import React, { useMemo, useState } from 'react';
import {
  FanAttributeStatus,
  FanAttributesEntity,
  InterestStatus,
} from '@seaters-app/constants';
import {
  useFetchFanAttributes,
  useFetchInterestCategories,
  useFetchProfileInterests,
  useLikeInterest,
  useLinkFanAttribute,
  useDislikeInterest,
  useUnlinkFanAttribute,
  useSendNeutralInterest,
  useLinkFanAttributeByName,
  getSingleTranslation,
} from '@seaters-app/data-access';
import styles from './tabs.module.css';
import FanAttributeCard from './components/FanAttributeCard/FanAttributeCard';
import {
  Alert,
  Avatar,
  Col,
  Collapse,
  CollapseProps,
  Form,
  Grid,
  Select,
  Skeleton,
  Typography,
} from 'antd';
import { useFanAttributes } from './hooks/useSearchFanAttributes';
import InterestsTable from './components/InterestsTable/InterestsTable';
import InterestsTableMobile from './components/InterestsTable/InterestsTableMobile';
import FanAttributeCardSkeleton from './components/FanAttributeCard/FanAttributeCardSkeleton';
import { useMessage } from '../ProfileLayout';
import { useTranslation } from 'react-i18next';
const { Title } = Typography;
const { Option } = Select;

const { useBreakpoint } = Grid;

function Interests() {
  const { lg } = useBreakpoint();
  const { message, setMessage } = useMessage();

  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const lang = language === 'nl_NL' || language === 'nl_BE' ? 'nl' : language;

  const handleCloseMessage = () => {
    setMessage(false);
  };

  const [searchedValue, setSearchedValue] = useState('');
  const categoriesParams = {
    size: 9999,
    page: 0,
    sort: 'category_order%3Aasc',
  };
  const { data: categories, isLoading: categoriesIsLoading } =
    useFetchInterestCategories(categoriesParams);
  const interestsParams = {
    size: 9999,
    page: 0,
  };
  const { data: interests, isLoading: interestsIsLoading } =
    useFetchProfileInterests(interestsParams);

  const fanAttributesParams = {
    size: 9999,
    page: 0,
    user_fan_attribute_status: FanAttributeStatus.LINKED,
  };
  const { data: fanAttributes, isLoading: fanAttributesIsLoading } =
    useFetchFanAttributes(fanAttributesParams);
  const { searchedFanAttributes, handleSearch, setParams } =
    useFanAttributes(setSearchedValue);
  const { mutateAsync: linkFanAttributeByName } = useLinkFanAttributeByName();
  const { mutateAsync: linkFanAttribute } = useLinkFanAttribute();

  const { mutateAsync: unlinkFanAttribute } = useUnlinkFanAttribute();
  const { mutate: likeInterest } = useLikeInterest();
  const { mutate: dislikeInterest } = useDislikeInterest();
  const { mutate: setNeutral } = useSendNeutralInterest();

  const handleChange = async (key: string, option: InterestStatus) => {
    if (option === InterestStatus.LIKE) {
      likeInterest({ id: key });
    } else if (option === InterestStatus.DISLIKE) {
      dislikeInterest({ id: key });
    } else {
      setNeutral({ id: key });
    }
  };

  const items: CollapseProps['items'] = useMemo(
    () =>
      categories?.content.map((category) => {
        const currentCategoryInterestsList = interests?.content.filter(
          (interest) => interest.interest.categoryId === category.id
        );
        return {
          key: category.id,
          label: getSingleTranslation(category.name, lang),
          children: (
            <div style={{ display: 'flex', gap: 16 }}>
              <InterestsTable
                dataSource={currentCategoryInterestsList?.slice(
                  0,
                  Math.ceil(currentCategoryInterestsList?.length / 2)
                )}
                handleChange={handleChange}
              />

              {currentCategoryInterestsList?.length &&
                currentCategoryInterestsList?.length > 1 && (
                  <InterestsTable
                    dataSource={currentCategoryInterestsList?.slice(
                      Math.ceil(currentCategoryInterestsList.length / 2)
                    )}
                    handleChange={handleChange}
                  />
                )}
            </div>
          ),
        };
      }),
    [categories?.content, interests?.content, lang]
  );

  const itemsMobile: CollapseProps['items'] = useMemo(
    () =>
      categories?.content.map((category) => {
        return {
          key: category.id,
          label: getSingleTranslation(category.name),
          className: `${styles.interestsMobileCollapseContent}`,
          children: (
            <InterestsTableMobile
              dataSource={interests?.content.filter(
                (interest) => interest.interest.categoryId === category.id
              )}
              handleChange={handleChange}
            />
          ),
        };
      }),
    [categories?.content, interests?.content, lang]
  );

  return (
    <>
      {message && (
        <Alert
          message={t('fan_profile_interests_confirm_message')}
          type="success"
          closable
          afterClose={handleCloseMessage}
          style={{ marginBottom: 16 }}
        />
      )}
      <div className={styles.fanAttributes}>
        <Title level={5}>{t('interests_attributes_title')}</Title>
        <Form layout="vertical">
          <Col xs={24} sm={12} md={12} lg={8}>
            <Form.Item label={t('interests_fan_text')}>
              <Select
                showSearch
                filterOption={false}
                onSelect={(item, option) => {
                  if (searchedFanAttributes?.length) {
                    linkFanAttribute({ id: option.key });
                  } else if (option.value) {
                    linkFanAttributeByName({ name: option?.value.toString() });
                    setSearchedValue('');
                  }
                  setParams({
                    query: '',
                    validated: true,
                  });
                }}
                notFoundContent={null}
                onSearch={handleSearch}
                placeholder={t('interests_search_placeholder_text')}
                options={
                  searchedFanAttributes?.length
                    ? searchedFanAttributes?.map((option) => ({
                        label: (
                          <div>
                            {/* TO DO : return back */}
                            {/* <Avatar
                              src={`https://graph.facebook.com/${
                                option.externalIdType === 'FACEBOOK'
                                  ? option.externalId
                                  : undefined
                              }/picture`}
                              size="small"
                            />{' '} */}
                            {option?.name}
                          </div>
                        ),
                        value: option?.name,
                        key: option?.id,
                      }))
                    : [
                        {
                          key: `key-for-${searchedValue}`,
                          label: <strong>{searchedValue}</strong>,
                          value: searchedValue,
                        },
                      ]
                }
              />
            </Form.Item>
          </Col>
        </Form>
        <Col xs={24} sm={24} md={20} lg={24}>
          {fanAttributesIsLoading ? (
            <div className={styles.attributesContainer}>
              {[...Array(3)].map(() => (
                <FanAttributeCardSkeleton />
              ))}
            </div>
          ) : (
            !!fanAttributes?.content.length && (
              <div className={styles.attributesContainer}>
                {fanAttributes.content.map((attr: FanAttributesEntity) => (
                  <FanAttributeCard
                    id={attr.fanAttribute.id}
                    title={attr.fanAttribute.name}
                    unlinkFanAttribute={unlinkFanAttribute}
                    facebookId={
                      attr.fanAttribute.externalIdType === 'FACEBOOK'
                        ? attr.fanAttribute.externalId
                        : undefined
                    }
                  />
                ))}
              </div>
            )
          )}
        </Col>
      </div>
      <div className={styles.interests}>
        <Title level={5}>{t('profiling_fan_attribute_interest_label')}</Title>
        <Col xs={24} sm={24} md={20} lg={24}>
          {categoriesIsLoading || interestsIsLoading ? (
            <Skeleton />
          ) : (
            <Collapse
              items={lg ? items : itemsMobile}
              defaultActiveKey={['1']}
            />
          )}
        </Col>
      </div>
    </>
  );
}

export default Interests;
