import {
  FormTranslationType,
  LookEntity,
  usersKeys,
} from '@seaters-app/constants';
import { getSlugFromUrl, queryClient } from '@seaters-app/data-access';
import { initialTranslations } from '../../../utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useFanGroupData = () => {
  const detectedSlug = getSlugFromUrl();
  const fanGroup = queryClient.getQueryData<LookEntity>(usersKeys.look());

  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const lang = language === 'nl_NL' || language === 'nl_BE' ? 'nl' : language;

  const [fanGroupData, setFanGroupData] = useState<{
    profileImage: string;
    backgroundImage: string;
    coverImage: string;
    fanGroupName: FormTranslationType;
    helpUrls: string | null;
    faqLinks: string | null;
  }>({
    profileImage: '',
    backgroundImage: '',
    coverImage: '',
    fanGroupName: initialTranslations,
    helpUrls: '',
    faqLinks: '',
  });

  useEffect(() => {
    if (fanGroup) {
      setFanGroupData({
        profileImage: fanGroup?.profileImageUrl,
        coverImage: fanGroup?.coverImageUrl,
        backgroundImage: fanGroup?.backgroundImageUrl,
        fanGroupName: fanGroup?.name,
        helpUrls:
          fanGroup?.helpLinks && !!fanGroup?.helpLinks[lang]
            ? fanGroup?.helpLinks[lang]
            : null,
        faqLinks:
          fanGroup?.faqLinks && !!fanGroup?.faqLinks[lang]
            ? fanGroup?.faqLinks[lang]
            : null,
      });
    } else {
      setFanGroupData({
        coverImage: '',
        profileImage: '',
        backgroundImage: '',
        fanGroupName: initialTranslations,
        helpUrls: null,
        faqLinks: null,
      });
    }
  }, [detectedSlug, fanGroup]);

  const {
    profileImage,
    backgroundImage,
    fanGroupName,
    coverImage,
    helpUrls,
    faqLinks,
  } = fanGroupData;

  return {
    profileImage,
    backgroundImage,
    fanGroupName,
    coverImage,
    helpUrls,
    faqLinks,
  };
};
