import { Tag } from 'antd';

import { MailcampaignState } from '@seaters-app/constants';
import {
  alertColor,
  baseColor600,
  errorColor,
  successColor,
  tertiaryColor700,
} from '@seaters-app/ui';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export interface MailcampaignStatusTagProps {
  status: MailcampaignState;
  icon?: ReactNode;
}

const mailcampaignStatusToColor = {
  [MailcampaignState.DRAFT]: baseColor600,
  [MailcampaignState.FAILED]: errorColor,
  [MailcampaignState.QUEUED]: alertColor,
  [MailcampaignState.SCHEDULED]: tertiaryColor700,
  [MailcampaignState.SENDING]: alertColor,
  [MailcampaignState.SENT]: successColor,
};

const mailcampaignStatusTranslations: {
  [key in MailcampaignState]: string;
} = {
  draft: 'mailcampaign_draft_label',
  failed: 'mailcampaign_failed_label',
  queued: 'mailcampaign_queued_label',
  scheduled: 'mailcampaign_scheduled_label',
  sending: 'mailcampaign_sending_label',
  sent: 'mailcampaign_sent_label',
};
export function MailcampaignStatusTag({
  status,
  icon,
}: MailcampaignStatusTagProps) {
  const { t } = useTranslation();
  return (
    <Tag icon={icon} color={mailcampaignStatusToColor[status]}>
      {t(mailcampaignStatusTranslations[status])}
    </Tag>
  );
}

export default MailcampaignStatusTag;
