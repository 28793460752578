import { useState } from 'react';
import PhoneInput, { CountryData, PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './styles.css';
import { theme } from 'antd';
import { errorColor } from '../theme/colors';
import { useTranslation } from 'react-i18next';

interface PhoneInputWithCountryCodeProps extends PhoneInputProps {
  phoneNumber: string;
  setPhoneNumber: (phoneNumber: string) => void;
  countryCode: string;
  setCountryCode: (countryCode: string) => void;
  setDisabledSave?: (v: boolean) => void;
  isError?: boolean;
  setFormValue?: (value: string) => void;
}

export const PhoneInputWithCountryCode = ({
  phoneNumber,
  setPhoneNumber,
  setCountryCode,
  setDisabledSave,
  isError,
  setFormValue,
  ...props
}: PhoneInputWithCountryCodeProps) => {
  const { t } = useTranslation();

  const [hasNumberError, setHasNumberError] = useState(false);

  const handlePhoneNumber = (value: string, country: CountryData) => {
    if (!!value.startsWith(`${country.dialCode}0`)) {
      setHasNumberError(true);
    } else {
      setHasNumberError(false);
    }

    setPhoneNumber(value);
    setFormValue && setFormValue(value);
    setCountryCode(country.dialCode);
    if (setDisabledSave) {
      setDisabledSave(false);
    }
  };

  const [isActive, setIsActive] = useState(false);
  const { token } = theme.useToken();
  const inputStyle = {
    backgroundColor: props.disabled
      ? token.colorBgContainerDisabled
      : token.colorBgBase,
    background: 'inherit',
    color: props.disabled ? token.colorTextDisabled : token.colorTextBase,
    border: `1px solid ${
      isError ? errorColor : isActive ? token.colorPrimary : token.colorBorder
    }`,
    opacity: 'inherit',
  };

  const buttonStyle = {
    ...inputStyle,
    backgroundColor: 'inherit',
  };

  return (
    <>
      <PhoneInput
        inputStyle={inputStyle}
        buttonStyle={buttonStyle}
        value={phoneNumber}
        onChange={handlePhoneNumber}
        onFocus={() => setIsActive(true)}
        onBlur={() => setIsActive(false)}
        preferredCountries={['fr', 'be', 'nl']}
        country="nl"
      />
      {hasNumberError && (
        <span style={{ color: errorColor }}>{t('phone_input_error_text')}</span>
      )}
    </>
  );
};
