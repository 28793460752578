import { InterestEntity, InterestStatus } from '@seaters-app/constants';
import { getSingleTranslation } from '@seaters-app/data-access';
import { Radio, Table } from 'antd';
import Column from 'antd/es/table/Column';
import { useTranslation } from 'react-i18next';

export interface InterestsTableProps {
  dataSource?: InterestEntity[];
  handleChange: (id: string, status: InterestStatus) => void;
  isFailed?: boolean;
  isSuccess?: boolean;
}

function InterestsTable({ dataSource, handleChange }: InterestsTableProps) {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const lang = language === 'nl_NL' || language === 'nl_BE' ? 'nl' : language;
  return (
    <Table
      bordered
      style={{ width: 'calc(50% - 8px)' }}
      pagination={false}
      dataSource={dataSource}
    >
      <Column
        title={t('settings_personal-section_form_username-label')}
        dataIndex="interest"
        key="name"
        render={(interest) => {
          return <span>{getSingleTranslation(interest.name, lang)}</span>;
        }}
      />
      <Column
        title={t('interests_yes_label')}
        key="yes"
        align="center"
        width="17%"
        render={(_, record: InterestEntity) => (
          <Radio
            checked={record.status === InterestStatus.LIKE}
            onChange={() =>
              handleChange(record.interest.id, InterestStatus.LIKE)
            }
          />
        )}
      />
      <Column
        title={t('interests_undecided_label')}
        key="maybe"
        align="center"
        width="17%"
        render={(_, record: InterestEntity) => (
          <Radio
            checked={record.status === InterestStatus.NEUTRAL}
            onChange={() =>
              handleChange(record.interest.id, InterestStatus.NEUTRAL)
            }
          />
        )}
      />
      <Column
        title={t('interests_no_label')}
        key="no"
        align="center"
        width="17%"
        render={(_, record: InterestEntity) => (
          <Radio
            checked={record.status === InterestStatus.DISLIKE}
            onChange={() =>
              handleChange(record.interest.id, InterestStatus.DISLIKE)
            }
          />
        )}
      />
    </Table>
  );
}

export default InterestsTable;
