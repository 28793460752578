import { Form, Modal, Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { surveyExtensionPoints } from '../../surveys/[id]/SurveyInstanceDetails/constants';
import { useParams } from 'react-router-dom';
import {
  getSingleTranslation,
  queryClient,
  useAppUserStore,
  useFanGroupStore,
} from '@seaters-app/data-access';
import {
  FanGroupOwnerWaitingListEntity,
  fanGroupOwnerWaitingListsKeys,
  languages,
  WLPositionStatus,
} from '@seaters-app/constants';
import { Button } from '@seaters-app/ui';
import { DownloadOutlined } from '@ant-design/icons';
import { useDownloadSurveyAnswers } from './hooks/useDownloadSurveyAnswers';
import Alerts from '../../supportActions/components/Alerts';

interface DownloadSurveyAnswersModalProps {
  open: boolean;
  close: () => void;
}

function DownloadSurveyAnswersModal({
  open,
  close,
}: DownloadSurveyAnswersModalProps) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { fanGroup } = useFanGroupStore();
  const { wishListId = '' } = useParams();
  const { user } = useAppUserStore();

  const languagesOptions = languages.map((language) => ({
    label: language.name.en,
    value: language.locale,
  }));

  const wishList = queryClient.getQueryData<FanGroupOwnerWaitingListEntity>(
    fanGroupOwnerWaitingListsKeys.detail(wishListId)
  );

  const {
    isGenerating,
    answersLoading,
    handleDownload,
    handleExportAnswers,
    xlsxUrl,
    job,
  } = useDownloadSurveyAnswers(fanGroup?.slug ?? '', wishListId);

  return (
    <Modal
      title={t('export_survey_answers')}
      open={open}
      onOk={() => form.submit()}
      onCancel={close}
    >
      <Spin
        spinning={isGenerating}
        fullscreen
        tip={t('report_generation_notification')}
      />
      <Form
        initialValues={{
          extensionPoint: '',
          positionStatus: WLPositionStatus.HAS_SEAT,
          userLanguage: user?.language ?? 'en',
        }}
        form={form}
        disabled={answersLoading || isGenerating}
        onFinish={async (values) => {
          handleExportAnswers(values);
        }}
      >
        <Form.Item
          label={t('position_status_label')}
          name="positionStatus"
          rules={[{ required: true, message: t('field_validation_required') }]}
        >
          <Select
            allowClear
            placeholder={t('please_select_placeholder_text')}
            options={[
              {
                label: WLPositionStatus.HAS_SEAT,
                value: WLPositionStatus.HAS_SEAT,
              },
            ]}
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          label={t('survey_extension_point_label')}
          name="extensionPoint"
          rules={[{ required: true, message: t('field_validation_required') }]}
        >
          <Select
            allowClear
            placeholder={t('please_select_placeholder_text')}
            options={surveyExtensionPoints.map(({ label, value }) => {
              return { value, label: t(label) };
            })}
            style={{ width: '100%' }}
          />
        </Form.Item>

        <Form.Item
          name="userLanguage"
          label={t('settings_personal-section_form_lang-label')}
        >
          <Select
            options={languagesOptions}
            defaultValue={user?.language ?? 'en'}
          />
        </Form.Item>
      </Form>
      {!!xlsxUrl && job?.status === 'succeeded' && (
        <Button
          onClick={() =>
            handleDownload(
              `${
                wishList?.experienceName
                  ? getSingleTranslation(wishList.experienceName)
                  : wishList?.name
              } - ${form.getFieldValue(
                'extensionPoint'
              )} - ${form.getFieldValue('userLanguage')}.xlsx`
            )
          }
          style={{ padding: '0' }}
          type="link"
          icon={<DownloadOutlined rev={undefined} />}
        >
          {t('download_report_btn_text')}
        </Button>
      )}
      <Alerts job={job} />
    </Modal>
  );
}

export default DownloadSurveyAnswersModal;
