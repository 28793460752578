import {
  AxiosAPIError,
  ListEntity,
  EventEntity,
  eventsKeys,
  EventsQuery,
  EventValidator,
  SearchEventRequestBody,
  FanGroupOwnerEventEntity,
  fanGroupOwnerEventsKeys,
  FGOEventValidator,
  AdminEventEntity,
  PaginationQueryV2,
  UserRole,
  PaginationQuery,
  WaitingListEntity,
} from '@seaters-app/constants';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import {
  createEvent,
  createFGOEvent,
  deleteEvent,
  fetchAdminEventWLs,
  fetchEvent,
  fetchEventAsFGO,
  fetchEvents,
  searchAdminEventsList,
  searchFanGroupOwnerEventsList,
  updateEvent,
} from '../api/events';
import { useAppUserStore } from '../storage';

export const useFetchEvents = (params: EventsQuery) =>
  useQuery<ListEntity<EventEntity>, Error>(
    eventsKeys.list(params),
    () => fetchEvents(params),
    {
      keepPreviousData: true,
    }
  );

export const useFetchEvent = (eventId: string) => {
  const { user } = useAppUserStore();

  const isAdmin = user?.roles.includes(UserRole.ADMIN);
  const isFGO = user?.roles.includes(UserRole.FAN_GROUP_OWNER);

  return useQuery<EventEntity, Error>(
    eventsKeys.detail(eventId),
    () => isAdmin ? fetchEvent(eventId) : fetchEventAsFGO(eventId),
    {
      enabled: !!eventId && (isAdmin || isFGO),
    }
  );
};

export const useCreateEvent = (): UseMutationResult<
  EventEntity,
  AxiosAPIError<EventValidator>,
  EventValidator,
  unknown
> =>
  useMutation(eventsKeys.mutation(), (body: EventValidator) =>
    createEvent(body)
  );

export const useCreateFGOEvent = (): UseMutationResult<
  FanGroupOwnerEventEntity,
  AxiosAPIError<FGOEventValidator>,
  FGOEventValidator,
  unknown
> =>
  useMutation(eventsKeys.mutation(), (body: FGOEventValidator) =>
    createFGOEvent(body)
  );

export const useDeleteEvent = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ eventId }: { eventId: string }) => deleteEvent(eventId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(eventsKeys.all());
      },
    }
  );
};

export const useUpdateEvent = (
  id: string
): UseMutationResult<
  EventEntity,
  AxiosAPIError<EventValidator>,
  EventValidator,
  unknown
> =>
  useMutation(eventsKeys.mutation(), (body: EventValidator) =>
    updateEvent(id, body)
  );

export const useSearchFanGroupOwnerEventsList = (
  body: SearchEventRequestBody,
  params: EventsQuery
) =>
  useQuery<ListEntity<FanGroupOwnerEventEntity>, Error>(
    fanGroupOwnerEventsKeys.filteredList(params, body),
    () => searchFanGroupOwnerEventsList(body, params),
    {
      refetchOnWindowFocus: false,
    }
  );

export const useSearchAdminEventsList = (
  body: SearchEventRequestBody,
  params: PaginationQueryV2
) =>
  useQuery<ListEntity<AdminEventEntity>, Error>(
    eventsKeys.adminList(params, body),
    () => searchAdminEventsList(body, params),
    {
      refetchOnWindowFocus: false,
    }
  );

export const useFetchEventWls = (eventId: string, params: PaginationQuery) => {
  return useQuery<ListEntity<WaitingListEntity>, Error>(
    eventsKeys.waitingListsList(eventId, params),
    () => fetchAdminEventWLs(eventId, params),
    {
      enabled: !!eventId,
    }
  );
};
