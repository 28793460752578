import {
  AssignationMode,
  BillingMode,
  DisplayMode,
  InvitationApprovalMode,
  InvitationMode,
  Language,
  PositionsDistributionMode,
  SeatDistributionMode,
  SeatDistributionModeLabels, WaitingListAcceptFormat,
  WLAccessModeEnum
} from '../../enums';
import { string, z } from 'zod';
import dayjs, { Dayjs } from 'dayjs';

export const CreateWishListSettingsFormValidatorSchema = z
  .object({
    experienceName: z.record(z.string().min(1), z.string()),
    name: z.string().min(1),
    highlighted: z.boolean().optional(),

    billingVariables: z.object({
      distributionRate: z.string(),
      maxDistributionFee: z.string(),
      minDistributionFee: z.string(),
      vatRate: z.string(),
    }),

    description: z.record(z.string().min(1), z.string()),
    keywords: z.record(z.string().min(1), z.string().array()),
    billingMode: z.nativeEnum(BillingMode),
    accessMode: z.nativeEnum(WLAccessModeEnum),
    price: z.string(),
    originalPrice: z.union([z.string(), z.number()]),
    showOriginalValue: z.boolean().optional(),
    directSales: z.boolean().optional(),
    prepaidFee: z.boolean().optional(),
    termsAndConditionFileURL: string().optional(),
    positionsDistributionMode: z.string(),
    displayMode: z.string().min(1),
    minNumberOfSeatsPerPosition: z.number().optional(),
    maxNumberOfSeatsPerPosition: z.number().optional(),
    voucherExpirationTimeOutInMs: z.number().optional(),
    fixedNumberOfSeatsPerPosition: z.number().optional(),
    participationEndDate: z
      .instanceof(dayjs as unknown as typeof Dayjs)
      .nullable()
      .optional(),
    invitationParameters: z
      .object({
        approvalMode: z.nativeEnum(InvitationApprovalMode),
        minNumberOfInvitations: z.number(),
        maxNumberOfInvitations: z.number(),
        assignationMode: z.nativeEnum(AssignationMode),
        invitationMode: z.nativeEnum(InvitationMode).optional(),
        specialGuestEnabled: z.boolean(),
      })
      .refine(
        (data) => {
          return data.minNumberOfInvitations <= data.maxNumberOfInvitations;
        },
        {
          message: 'wl_settings_max_tickets_per_host_validation', // wl_settings_max_tickets_per_host_validation
          path: ['maxNumberOfInvitations'], // path of error
        }
      )
      .nullable(),
  })
  .superRefine((schema, ctx) => {
    const {
      positionsDistributionMode,
      participationEndDate,
      minNumberOfSeatsPerPosition,
      maxNumberOfSeatsPerPosition,
      fixedNumberOfSeatsPerPosition,
    } = schema;

    if (positionsDistributionMode === PositionsDistributionMode.RANDOM) {
      if (!participationEndDate) {
        ctx.addIssue({
          message: 'ADD DATE',
          code: z.ZodIssueCode.custom,
          path: ['participationEndDate'],
        });
      }
      if (!fixedNumberOfSeatsPerPosition) {
        ctx.addIssue({
          message: 'Add seats per position',
          code: z.ZodIssueCode.custom,
          path: ['fixedNumberOfSeatsPerPosition'],
        });
      }
    } else {
      if (!minNumberOfSeatsPerPosition) {
        ctx.addIssue({
          message: 'Invalid input',
          code: z.ZodIssueCode.invalid_type,
          expected: 'number',
          received: 'null',
          path: ['minNumberOfSeatsPerPosition'],
        });
      }
      if (!maxNumberOfSeatsPerPosition) {
        ctx.addIssue({
          message: 'Invalid input',
          code: z.ZodIssueCode.invalid_type,
          expected: 'number',
          received: 'null',
          path: ['maxNumberOfSeatsPerPosition'],
        });
      }
    }

    if (
      minNumberOfSeatsPerPosition &&
      maxNumberOfSeatsPerPosition &&
      minNumberOfSeatsPerPosition > maxNumberOfSeatsPerPosition
    ) {
      ctx.addIssue({
        message: 'Invalid input',
        code: z.ZodIssueCode.custom,
        path: ['maxNumberOfSeatsPerPosition'],
      });
    }
  })
  .superRefine((schema, ctx) => {
    const { positionsDistributionMode, displayMode } = schema;
    const randomDistributionIncompatibleDisplayModes = [
      DisplayMode.DETAILED,
      DisplayMode.INFORMATIVE,
    ];
    const parkingDistributionIncompatibleDisplayModes = [
      DisplayMode.CONTEST,
      DisplayMode.MERCHANDISE,
      DisplayMode.INFORMATIVE,
    ];
    if (
      (positionsDistributionMode === PositionsDistributionMode.RANDOM &&
        randomDistributionIncompatibleDisplayModes.includes(
          displayMode as DisplayMode
        )) ||
      (positionsDistributionMode === PositionsDistributionMode.WITH_PARKING &&
        parkingDistributionIncompatibleDisplayModes.includes(
          displayMode as DisplayMode
        ))
    ) {
      ctx.addIssue({
        message:
          positionsDistributionMode === PositionsDistributionMode.RANDOM
            ? 'Display mode DETAILED, INFORMATIVE not allowed for selected distribution mode'
            : 'Display mode  CONTEST, MERCHANDISE, INFORMATIVE not allowed for selected distribution mode',
        code: z.ZodIssueCode.custom,
        path: ['displayMode'],
      });
    }
  });
const ticketPoolIdsIssue: z.IssueData = {
  code: z.ZodIssueCode.too_small,
  minimum: 1,
  type: 'array',
  path: ['ticketPoolIds'],
  inclusive: true,
  message: 'general_label_required',
};

const voucherTextIssue = {
  code: z.ZodIssueCode.custom,
  path: ['voucherText'],
  message: 'event_error_no-default-translation',
};

export const CreateWishListTicketsFormValidatorSchema = z
  .object({
    voucherText: z.record(z.string().min(1), z.string()).optional(),
    ticketsToAdd: z.number(),
    downloadFormat: z.nativeEnum(WaitingListAcceptFormat),
    estimatedTickets: z.number(),
    seatDistributionMode: z.nativeEnum(SeatDistributionModeLabels),
    ticketPoolIds: z.string().array().optional(),
    secondaryTicketPoolIds: z.string().array().optional(),
  })
  .superRefine((schema, ctx) => {
    const { seatDistributionMode, ticketPoolIds } = schema;
    if (seatDistributionMode === SeatDistributionModeLabels.TICKET) {
      if (ticketPoolIds?.length === 0) {
        ctx.addIssue(ticketPoolIdsIssue);
      }
    } else {
      if (
        seatDistributionMode === SeatDistributionModeLabels.VOUCHER &&
        (!schema.voucherText || !schema?.voucherText?.en) &&
        schema.ticketsToAdd > 0
      )
        ctx.addIssue(voucherTextIssue);
    }
  });

export const EditWishListTicketsFormValidatorSchema = z
  .object({
    voucherText: z.record(z.nativeEnum(Language), z.string()).optional(),
    ticketsToAdd: z.number(),
    downloadFormat: z.nativeEnum(WaitingListAcceptFormat),
    estimatedTickets: z.number(),
    seatDistributionMode: z.nativeEnum(SeatDistributionMode),
    ticketPoolIds: z.string().array().optional(),
    secondaryTicketPoolIds: z.string().array(),
  })
  .superRefine((schema, ctx) => {
    const { seatDistributionMode, ticketPoolIds } = schema;
    if (seatDistributionMode === SeatDistributionMode.TICKET) {
      if (ticketPoolIds?.length === 0) {
        ctx.addIssue(ticketPoolIdsIssue);
      }
    } else {
      if (
        seatDistributionMode === SeatDistributionMode.VOUCHER &&
        (!schema.voucherText || !schema?.voucherText?.en) &&
        schema.ticketsToAdd > 0
      ) {
        ctx.addIssue(voucherTextIssue);
      }
    }
  });

export const FGOWishListsValidatorSchema = z.object({
  eventStartDate: z.string().optional(),
  mustHaveSeatsWaiting: z.boolean(),
  includeClosed: z.boolean(),
  includeOpen: z.boolean(),
  includeSoon: z.boolean(),
  includeDraft: z.boolean(),
  categoryPart: z.string(),
  exprerienceNamePart: z.string().optional(),
});

export type FGOWishListsValidator = z.infer<typeof FGOWishListsValidatorSchema>;
export type CreateWishListsValidator = z.infer<
  typeof CreateWishListSettingsFormValidatorSchema
>;
