export const languagesWithoutNL = [
  {
    locale: 'en',
    name: {
      de: 'English',
      ru: 'English',
      pt: 'English',
      ja: 'English',
      en: 'English',
      cn: 'English',
      it: 'English',
      fr: 'English',
      es: 'English',
      nl: 'English',
    },
  },
  {
    locale: 'fr',
    name: {
      de: 'Français',
      ru: 'Français',
      pt: 'French',
      ja: 'Français',
      en: 'Français',
      cn: 'Français',
      it: 'Français',
      fr: 'Français',
      es: 'Français',
      nl: 'Français',
    },
  },
  {
    locale: 'de',
    name: {
      de: 'Deutsch',
      ru: 'Deutsch',
      ja: 'Deutsch',
      en: 'Deutsch',
      cn: 'Deutsch',
      it: 'Deutsch',
      fr: 'Deutsch',
      es: 'Deutsch',
      nl: 'Deutsch',
    },
  },
  {
    locale: 'it',
    name: {
      de: 'Italiano',
      ru: 'Italiano',
      ja: 'Italiano',
      en: 'Italiano',
      cn: 'Italiano',
      it: 'Italiano',
      fr: 'Italiano',
      es: 'Italiano',
      nl: 'Italiano',
    },
  },
  {
    locale: 'es',
    name: {
      de: 'Español',
      ru: 'Español',
      pt: 'Spanish',
      ja: 'Español',
      en: 'Español',
      cn: 'Español',
      it: 'Español',
      fr: 'Español',
      es: 'Español',
      nl: 'Español',
    },
  },
  {
    locale: 'ja',
    name: {
      de: '日本語',
      ru: '日本語',
      ja: '日本語',
      en: '日本語',
      cn: '日本語',
      it: '日本語',
      fr: '日本語',
      es: '日本語',
      nl: '日本語',
    },
  },
  {
    locale: 'cn',
    name: {
      de: '中文',
      ru: '中文',
      ja: '中文',
      en: '中文',
      cn: '中文',
      it: '中文',
      fr: '中文',
      es: '中文',
      nl: '中文',
    },
  },
  {
    locale: 'ru',
    name: {
      de: 'Russian',
      ru: 'Russian',
      ja: 'Russian',
      en: 'Russian',
      cn: 'Russian',
      it: 'Russian',
      fr: 'Russian',
      es: 'Russian',
      nl: 'Russian',
    },
  },
];

export const languages = [
  ...languagesWithoutNL.slice(0, 2),
  {
    locale: 'nl',
    name: {
      de: 'Nederlands',
      ru: 'Nederlands',
      pt: 'Dutch',
      ja: 'Nederlands',
      en: 'Nederlands',
      cn: 'Nederlands',
      it: 'Nederlands',
      fr: 'Nederlands',
      nl: 'Nederlands',
      es: 'Nederlands',
    },
  },
  ...languagesWithoutNL.slice(3),
];

export const userLanguages = [
  ...languagesWithoutNL.slice(0, 2),
  {
    locale: 'nl_BE',
    name: {
      de: 'Nederlands (BE)',
      ru: 'Nederlands (BE)',
      pt: 'Dutch (BE)',
      ja: 'Nederlands (BE)',
      en: 'Nederlands (BE)',
      cn: 'Nederlands (BE)',
      it: 'Nederlands (BE)',
      fr: 'Nederlands (BE)',
      nl: 'Nederlands (BE)',
      es: 'Nederlands (BE)',
    },
  },
  {
    locale: 'nl_NL',
    name: {
      de: 'Nederlands (NL)',
      ru: 'Nederlands (NL)',
      pt: 'Dutch (NL)',
      ja: 'Nederlands (NL)',
      en: 'Nederlands (NL)',
      cn: 'Nederlands (NL)',
      it: 'Nederlands (NL)',
      fr: 'Nederlands (NL)',
      nl: 'Nederlands (NL)',
      es: 'Nederlands (NL)',
    },
  },
  ...languagesWithoutNL.slice(3),
];

export const languagesOptions = languages.map((language) => ({
  label: language.locale.toUpperCase(),
  value: language.locale,
}));

export const userLanguagesOptions = userLanguages.map((language) => ({
  label: language.locale.toUpperCase(),
  value: language.locale,
}));
