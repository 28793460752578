import {
  getSingleTranslation,
  queryClient,
  useFanGroupStore,
  useFetchFGOBadges,
  useFetchUserBadgesEvents,
  useGrantUserBadge,
  useRevokeUserBadge,
} from '@seaters-app/data-access';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button } from '@seaters-app/ui';
import { Avatar, Select, Space, Spin, Typography, theme } from 'antd';
import { useNavigate } from 'react-router-dom';
import styles from './../fanLayout.module.css';
import { useMemo, useState } from 'react';
import { useFGBadges } from '../../../fan-groups/[id]/badges/useFGBadges';
import { FanBadgeStatusTag } from '@seaters-app/ui-shared';
import { useTranslation } from 'react-i18next';
import {
  BadgeEventEntity,
  FanBadgeStatusEnum,
  FanEntity,
  fansKeys,
} from '@seaters-app/constants';
const { Text } = Typography;
const { Option } = Select;

export function AddFanBadge({ fanId }: { fanId: string }) {
  const { token } = theme.useToken();
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const lang = language === 'nl_NL' || language === 'nl_BE' ? 'nl' : language;
  const { fanGroup } = useFanGroupStore();
  const params = { page: 1, size: 20 };
  const [selectedBadge, setSelectedBadge] = useState('');

  const fanPersonalInfo = queryClient.getQueryData<FanEntity>(
    fansKeys.detail(fanId)
  );
  const { data: badges } = useFetchFGOBadges(fanGroup?.id ?? '', params);

  const { badges: fanGroupBadges } = useFGBadges();
  const { data: userBadgesEvents } = useFetchUserBadgesEvents(
    fanGroup?.id ?? '',
    fanId,
    params
  );

  const badgesOptions = fanGroupBadges?.map((badge) => ({
    label: getSingleTranslation(badge.name),
    value: badge.id,
    avatar: badge.displayedLogoUrl,
  }));

  const { mutate: grantBadge, isLoading: isBadgeGranting } = useGrantUserBadge(
    fanGroup?.id ?? ''
  );

  const { mutate: revokeBadge, isLoading: isBadgeRevoking } =
    useRevokeUserBadge(fanGroup?.id ?? '');

  const navigate = useNavigate();

  const userBadgesNames = useMemo(() => {
    const res = userBadgesEvents?.content.map((i) =>
      getSingleTranslation(i.badge.name)
    );
    return res;
  }, [userBadgesEvents?.content.length]);

  const filteredOptions = badgesOptions?.filter(
    (option) => !userBadgesNames?.includes(option.label)
  );

  const handleGrantBadge = () => {
    grantBadge({
      userId: fanId,
      badgeId: selectedBadge,
      expirationDate: null,
    });
    setSelectedBadge('');
  };

  const handleBadgeStatusChange = (item: BadgeEventEntity) => {
    if (item.event.status === FanBadgeStatusEnum.REVOKED) {
      grantBadge({
        userId: fanId,
        badgeId: item.badge.id,
        expirationDate: null,
      });
    } else {
      revokeBadge({
        userId: fanId,
        badgeId: item.badge.id,
        expirationDate: null,
      });
    }
  };

  return (
    <div style={{ height: '100vh' }}>
      {fanPersonalInfo ? (
        <>
          <Space direction="vertical" style={{ width: '100%' }} size={16}>
            <Text strong>{t('select_badge_label_text')}</Text>
            <Select
              showSearch
              optionFilterProp="filter"
              allowClear
              onSelect={(item, option) =>
                setSelectedBadge(option.value?.toString() ?? '')
              }
              style={{ width: '30%' }}
              size="large"
              placeholder={t('search_badge_placeholder')}
              options={filteredOptions?.map((option) => ({
                label: (
                  <div>
                    <Avatar src={option.avatar} size="small" /> {option.label}
                  </div>
                ),
                value: option.value,
                key: option.value,
                filter: option.label,
              }))}
            />
            <Button
              disabled={!selectedBadge}
              onClick={handleGrantBadge}
              type="primary"
            >
              {t('add_badge_button_text')}
            </Button>
          </Space>

          {badges && (
            <div className={styles.badgesContainer}>
              {userBadgesEvents?.content?.map((item) => (
                <div
                  style={{
                    border: `1px solid ${token.colorBorder}`,
                  }}
                  key={item?.fanGroupId + item.userId}
                  className={styles.badgeCard}
                >
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      alignItems: 'center',
                    }}
                  >
                    <Space size={16}>
                      <Space size={10}>
                        <Avatar
                          className={styles.avatar}
                          size="large"
                          src={item.badge.displayedLogoUrl}
                        />
                        <Text strong className={styles.title}>
                          {getSingleTranslation(item.badge.name)}
                        </Text>
                      </Space>
                      <FanBadgeStatusTag status={item.event.status} />
                    </Space>
                    <Button
                      style={{
                        marginLeft: 'auto',
                        marginRight: 0,
                        color:
                          item.event.status === FanBadgeStatusEnum.REVOKED
                            ? token.colorSuccess
                            : token.colorError,
                      }}
                      onClick={() => handleBadgeStatusChange(item)}
                      type="text"
                      icon={
                        item.event.status === FanBadgeStatusEnum.REVOKED ? (
                          <PlusOutlined rev={undefined} />
                        ) : (
                          <CloseOutlined rev={undefined} />
                        )
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      ) : (
        <div style={{ textAlign: 'center', width: '100%' }}>
          <Spin />
        </div>
      )}
    </div>
  );
}
