import {
  MailcampaignEntity,
  MailcampaignRecipient,
} from '@seaters-app/constants';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RecipientDropdownWrapper } from '../components/RecipientDropdown';
import { Control } from 'react-hook-form';

export const useRecipientOptions = (): {
  value: string;
  label: string;
  dropdown: (control: any) => JSX.Element;
}[] => {
  const { t } = useTranslation();
  return [
    {
      value: MailcampaignRecipient.FANGROUP,
      label: t('mailcampaign_recipient_fangroup'),
      dropdown: (control: Control) => (
        <RecipientDropdownWrapper control={control} recipientType={null} />
      ),
    },
    {
      value: MailcampaignRecipient.WAITING_LIST,
      label: t('mailcampaign_recipient_waitinglist'),
      dropdown: (control: Control) => (
        <RecipientDropdownWrapper control={control} recipientType="wishlist" />
      ),
    },
    {
      value: MailcampaignRecipient.WAITING_LIST_NOT_DISTRIBUTED,
      label: t('mailcampaign_recipient_waitinglist_not_distributed'),
      dropdown: (control: Control) => (
        <RecipientDropdownWrapper control={control} recipientType="wishlist" />
      ),
    },
    {
      value: MailcampaignRecipient.WAITING_LIST_DISTRIBUTED_NOT_ACCEPTED,
      label: t('mailcampaign_recipient_waitinglist_distributed_not_accepted'),
      dropdown: (control: Control) => (
        <RecipientDropdownWrapper control={control} recipientType="wishlist" />
      ),
    },
    {
      value: MailcampaignRecipient.WAITING_LIST_DISTRIBUTED_ACCEPTED,
      label: t('mailcampaign_recipient_waitinglist_distributed_accepted'),
      dropdown: (control: Control) => (
        <RecipientDropdownWrapper control={control} recipientType="wishlist" />
      ),
    },
    {
      value: MailcampaignRecipient.BADGE,
      label: t('mailcampaign_recipient_badge'),
      dropdown: (control: Control) => (
        <RecipientDropdownWrapper control={control} recipientType="badge" />
      ),
    },
  ];
};

export const getDetailStepInitialValues = (
  mailcampaignValues?: MailcampaignEntity
) => {
  if (!mailcampaignValues) {
    return {
      fangroup: '',
      name: '',
      wishlist: null,
      body: '',
      subject: '',
      replyTo: '',
      badge: null,
      recipient: null,
    };
  } else {
    return {
      ...mailcampaignValues,
      wishlist: mailcampaignValues.wishlist?.id,
      badge: mailcampaignValues.badge?.id,
      fangroup: mailcampaignValues.fanGroup.id,
    };
  }
};
