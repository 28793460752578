import React, { useEffect, useState } from 'react';
import {
  getSlugFromUrl,
  useFetchFGBySlug,
  useRequestFanAccessToProtectedFG,
  useUpdateRequestFanAccessToProtectedFG,
  useRequestFanAccessToProtectedWL,
  useUpdateRequestFanAccessToProtectedWL,
  useFetchWaitingList,
  queryClient,
} from '@seaters-app/data-access';
import { Button } from '@seaters-app/ui';
import { Alert, Card, Form, Input, Modal, Typography } from 'antd';
import Meta from 'antd/es/card/Meta';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  AccessMode,
  RequestAccessStatus,
  waitingListsKeys,
} from '@seaters-app/constants';
const { Title } = Typography;

export function ProtectedCodeModal({
  isProtectedCodeModalShown,
  setProtectedCodeModalShown,
}: {
  isProtectedCodeModalShown: boolean;
  setProtectedCodeModalShown: (v: boolean) => void;
}) {
  const slug = getSlugFromUrl();

  const { waitingListId = '' } = useParams<{ waitingListId: string }>();
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const lang = language === 'nl_NL' || language === 'nl_BE' ? 'nl' : language;
  const { data: fanGroupBySlugData, isRefetching } = useFetchFGBySlug(
    slug ?? '',
    true
  );
  const { data: waitingList, isRefetching: isRefetchingWL } =
    useFetchWaitingList(waitingListId);

  const isWLProtected = waitingList?.accessMode === AccessMode.CODE_PROTECTED;

  const [isLoading, setIsLoading] = useState(false);
  const [code, setCode] = useState<string>('');

  const { mutate: requestAccess, isLoading: isRequestLoading } =
    useRequestFanAccessToProtectedFG(fanGroupBySlugData?.id ?? '', slug ?? '');

  const { mutate: updateRequestAccess, isLoading: isRequestUpdateLoading } =
    useUpdateRequestFanAccessToProtectedFG(
      fanGroupBySlugData?.id ?? '',
      slug ?? ''
    );

  const { mutate: requestAccessWL, isLoading: isRequestLoadingWL } =
    useRequestFanAccessToProtectedWL(waitingListId);

  const { mutate: updateRequestAccessWL, isLoading: isRequestUpdateLoadingWL } =
    useUpdateRequestFanAccessToProtectedWL(waitingListId);

  const funcToRequest = isWLProtected ? requestAccessWL : requestAccess;

  const funcToUpdateRequest = isWLProtected
    ? updateRequestAccessWL
    : updateRequestAccess;

  // For testing:
  // const { mutate: deleteRequestAccess, isLoading: isRequestDeleteLoading } =
  //   useDeleteRequestToJoinFG(fanGroupBySlugData?.id ?? '', slug ?? '');

  const [isAlertShown, setAlertShown] = useState(false);

  const onSubmit = async () => {
    setIsLoading(true);
    if (waitingList?.request || fanGroupBySlugData?.membership.request) {
      funcToUpdateRequest(
        { code },
        {
          onSuccess: () => {
            setAlertShown(true);
            setIsLoading(false);
            queryClient.invalidateQueries(
              waitingListsKeys.detail(waitingListId)
            );
          },
        }
      );
    } else {
      funcToRequest(
        { code },
        {
          onSuccess: () => {
            setAlertShown(true);
            setIsLoading(false);
            queryClient.invalidateQueries(
              waitingListsKeys.detail(waitingListId)
            );
          },
        }
      );
    }
  };
  const description = fanGroupBySlugData?.protectionCodeExplanation
    ? fanGroupBySlugData?.protectionCodeExplanation
    : isWLProtected
    ? t('unlock_text_wishlist_reserved', {
        wishlist: waitingList?.experienceName[lang],
      })
    : t('unlock_text_fangroup_reserved', {
        fangroup: fanGroupBySlugData?.name[lang]
          ? fanGroupBySlugData?.name[lang]
          : fanGroupBySlugData?.name['en'],
      });

  useEffect(() => {
    setIsLoading(
      isRequestLoading ||
        isRequestUpdateLoading ||
        isRefetching ||
        isRequestLoadingWL ||
        isRequestUpdateLoadingWL ||
        isRefetchingWL
    );
  }, [isRefetching, isRefetchingWL]);

  useEffect(() => {
    if (waitingList?.request?.status === RequestAccessStatus.ACCEPTED) {
      setProtectedCodeModalShown(false);
    }
  }, [waitingList?.request?.status]);

  return (
    <Modal
      title={
        <Title level={3}>
          {isWLProtected
            ? t('waitinglist_unlock_modal_title')
            : t('general_protected_group')}
        </Title>
      }
      onCancel={() => {
        setAlertShown(false);
        setProtectedCodeModalShown(false);
      }}
      open={isProtectedCodeModalShown}
      footer={null}
      width={400}
      centered
      style={{ maxHeight: '800px' }}
      destroyOnClose={true}
    >
      <Form layout="vertical">
        <Form.Item label={t('enter_access_code_text')}>
          <Input
            onChange={(e) => setCode(e.target.value)}
            placeholder={
              t('type_here_placeholder')
                ? (t('type_here_placeholder') as string)
                : 'Type here'
            }
          />
        </Form.Item>

        <div
          style={{
            width: '100%',
            display: 'flex',
            gap: '20px',
            marginBottom: '24px',
          }}
        >
          <Button
            style={{ flexGrow: 1 }}
            onClick={() => {
              setProtectedCodeModalShown(false);
              setAlertShown(false);
            }}
          >
            {t('button_text_cancel')}
          </Button>
          <Button
            style={{ flexGrow: 1 }}
            type="primary"
            onClick={onSubmit}
            // loading={isRequestLoading || isRequestUpdateLoading || isRefetching}
            loading={isLoading}
          >
            {t('button_text_send')}
          </Button>
          {/* For testing purpose: */}
          {/* <Button        
            style={{ flexGrow: 1 }}
            type="primary"
            onClick={() => deleteRequestAccess()}
            loading={isRequestDeleteLoading || isRefetching}
          >
            Delete request
          </Button> */}
        </div>
        {fanGroupBySlugData?.membership.request?.rejectionReason &&
          isAlertShown && (
            <Alert
              style={{ marginBottom: 16 }}
              message={fanGroupBySlugData?.membership.request?.rejectionReason}
              type="error"
            />
          )}
        {waitingList?.request?.rejectionReason && isAlertShown && (
          <Alert
            style={{ marginBottom: 16 }}
            message={waitingList?.request?.rejectionReason}
            type="error"
          />
        )}
        <Card>
          <Meta description={description} />
        </Card>
      </Form>
    </Modal>
  );
}

export default ProtectedCodeModal;
