import {
  AnswerSemanticEntity,
  AxiosAPIError,
  ChoiceEntity,
  ChoiceListEntity,
  ChoicesQuery,
  FanAnswers,
  FansAnswersQuery,
  ListEntity,
  ListEntityV2,
  PaginationQuery,
  Question,
  QuestionChoicesDataValidator,
  QuestionFormDataType,
  SurveyAdvancedQuery,
  SurveyAnswersResponse,
  SurveyAnswersValidator,
  SurveyEntity,
  SurveyExtensionPoint,
  SurveyInstanceEntity,
  SurveyInstanceQuery,
  SurveyInstanceValidator,
  SurveyPaginationQuery,
  SurveyQuery,
  SurveyValidator,
  UserRole,
  questionChoicesKeys,
  questionsKeys,
  surveyFansAnswers,
  surveyInstancesKeys,
  surveysKeys,
} from '@seaters-app/constants';
import {
  UseMutationResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  createAdminSurvey,
  createAdminSurveyInstance,
  deleteAdminSurveyInstance,
  deleteAdminQuestion,
  fetchAdminSurveys,
  fetchAdminSurveysInstances,
  fetchQuestionChoices,
  fetchSurveyAnswers,
  fetchAdminSurveyById,
  fetchAdminSurveyInstanceById,
  fetchSurveyInstancesForFanGroup,
  fetchSurveyInstancesForWishList,
  fetchAdminSurveysQuestions,
  sendAnswers,
  updateAdminSurvey,
  updateAdminSurveyInstance,
  createAdminQuestion,
  updateAdminQuestion,
  createAdminQuestionChoices,
  updateAdminQuestionChoices,
  fetchAdminAnswerSemantics,
  fetchFGOWishListSurveysInstances,
  fetchSurveyFansAnswers,
  createFGOSurveyInstance,
  updateFGOnSurveyInstance,
  fetchAdvancedSurveys,
  sendFanAnswersAsFGO,
  sendGuestAnswersAsHost,
  fetchSurveyInstancesWithoutHeaders,
  fetchQuestionChoicesWithoutHeaders,
  sendAnswersWithoutHeaders,
  fetchSurveyAnswersWithoutHeaders,
} from '../api';

export const useFetchAdminSurveys = (
  isAdmin: boolean,
  params: SurveyPaginationQuery
) =>
  useQuery<ListEntity<SurveyEntity>, Error>(
    surveysKeys.list(params),
    () => fetchAdminSurveys(params),
    {
      enabled: isAdmin,
    }
  );

export const useFetchAdvancedSurveys = (
  userRole: UserRole.FAN_GROUP_OWNER | UserRole.ADMIN,
  params: SurveyAdvancedQuery
) =>
  useQuery<ListEntity<SurveyEntity>, Error>(surveysKeys.list(params), () =>
    fetchAdvancedSurveys(userRole, params)
  );

export const useFetchAdminAnswerSemantics = (params: SurveyPaginationQuery) =>
  useQuery<ListEntity<AnswerSemanticEntity>, Error>(
    questionsKeys.list(params),
    () => fetchAdminAnswerSemantics(params)
  );

export const useFetchAdminSurveysInstances = (
  isAdmin: boolean,
  params: SurveyInstanceQuery
) =>
  useQuery<ListEntity<SurveyInstanceEntity>, Error>(
    surveyInstancesKeys.list(params),
    () => fetchAdminSurveysInstances(params),
    {
      enabled: isAdmin,
    }
  );

export const useFetchAdminSurvey = (surveyId: string) =>
  useQuery<SurveyEntity, Error>(
    surveysKeys.detail(surveyId),
    () => fetchAdminSurveyById(surveyId),
    {
      enabled: !!surveyId,
    }
  );

export const useFetchAdminSurveyInstance = (surveyInstanceId: string) =>
  useQuery<SurveyInstanceEntity, Error>(
    surveyInstancesKeys.detail(surveyInstanceId),
    () => fetchAdminSurveyInstanceById(surveyInstanceId),
    {
      enabled: !!surveyInstanceId,
    }
  );

export const useFetchAdminSurveysQuestions = (params: PaginationQuery) =>
  useQuery<ListEntity<Question>, Error>(
    questionsKeys.list(params),
    () => fetchAdminSurveysQuestions(params),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

export const useFetchFGOWishListSurveysInstances = (
  waitinglistId: string,
  params: SurveyQuery
) =>
  useQuery<ListEntityV2<SurveyInstanceEntity>, Error>(
    surveyInstancesKeys.listPerWL(params),
    () => fetchFGOWishListSurveysInstances(waitinglistId, params)
  );

export const useCreateAdminSurvey = (): UseMutationResult<
  SurveyEntity,
  AxiosAPIError<SurveyValidator>,
  SurveyValidator,
  unknown
> =>
  useMutation(surveysKeys.mutation(), (body: SurveyValidator) =>
    createAdminSurvey(body)
  );

export const useUpdateAdminSurvey = (
  id: string
): UseMutationResult<
  SurveyEntity,
  AxiosAPIError<SurveyValidator>,
  SurveyValidator,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    surveysKeys.mutation(),
    (body: SurveyValidator) => updateAdminSurvey(id, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(surveysKeys.detail(id));
      },
    }
  );
};

export const useCreateAdminSurveyInstance = (): UseMutationResult<
  SurveyInstanceEntity,
  AxiosAPIError<SurveyInstanceValidator>,
  SurveyInstanceValidator,
  unknown
> =>
  useMutation(surveyInstancesKeys.mutation(), (body: SurveyInstanceValidator) =>
    createAdminSurveyInstance(body)
  );

export const useCreateFGOSurveyInstance = (): UseMutationResult<
  SurveyInstanceEntity,
  AxiosAPIError<SurveyInstanceValidator>,
  SurveyInstanceValidator,
  unknown
> =>
  useMutation(surveyInstancesKeys.mutation(), (body: SurveyInstanceValidator) =>
    createFGOSurveyInstance(body)
  );

export const useUpdateAdminSurveyInstance = (
  id: string
): UseMutationResult<
  SurveyInstanceEntity,
  AxiosAPIError<SurveyInstanceValidator>,
  SurveyInstanceValidator,
  unknown
> =>
  useMutation(surveyInstancesKeys.mutation(), (body: SurveyInstanceValidator) =>
    updateAdminSurveyInstance(id, body)
  );

export const useUpdateFGOSurveyInstance = (): UseMutationResult<
  SurveyInstanceEntity,
  AxiosAPIError<{ id: string; body: SurveyInstanceValidator }>,
  { id: string; body: SurveyInstanceValidator },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    surveyInstancesKeys.mutation(),
    ({ id, body }: { id: string; body: SurveyInstanceValidator }) =>
      updateFGOnSurveyInstance(id, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(surveyInstancesKeys.all());
      },
    }
  );
};

export const useDeleteAdminSurveyInstance = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ surveyInstanceId }: { surveyInstanceId: string }) =>
      deleteAdminSurveyInstance(surveyInstanceId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(surveyInstancesKeys.all());
      },
    }
  );
};

export const useFetchWaitingListSurveyInstances = (
  params: Omit<SurveyQuery, 'fangroup_id'>
) =>
  useQuery<ListEntityV2<SurveyInstanceEntity>, Error>(
    surveyInstancesKeys.listPerWL(params),
    () => fetchSurveyInstancesForWishList(params),
    {
      enabled: !!params.waitinglist_id,
      // keepPreviousData: true,
      // refetchOnMount: false,
      // refetchOnReconnect: false,
      // refetchOnWindowFocus: false,
    }
  );

export const useFetchWaitingListSurveyInstancesWithoutHeader = (
  params: Omit<SurveyQuery, 'fangroup_id'>
) =>
  useQuery<ListEntityV2<SurveyInstanceEntity>, Error>(
    surveyInstancesKeys.listPerWL(params),
    () => fetchSurveyInstancesWithoutHeaders(params),
    {
      enabled: !!params.waitinglist_id,
      retry: 3,
    }
  );

export const useFetchAfterRegistrationSurveyInstances = (
  params: Omit<SurveyQuery, 'waitinglist_id'>,
  slug: string,
  enabled: boolean
) =>
  useQuery<ListEntityV2<SurveyInstanceEntity>, Error>(
    surveyInstancesKeys.listPerFan(params?.fangroup_id ?? ''),
    () => fetchSurveyInstancesForFanGroup(params),
    {
      enabled: enabled && (!!params.fangroup_id || !!slug),
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

export const useFetchWaitingListSurveyAnswers = (
  waitingListId: string,
  instanceId: string
) =>
  useQuery<ListEntityV2<SurveyAnswersResponse>, Error>(
    surveyInstancesKeys.answersBeforeJoin(
      {
        waitinglist_id: waitingListId,
        extension_point: SurveyExtensionPoint.BEFORE_JOINING_WAITINGLIST,
      },
      instanceId
    ),
    () => fetchSurveyAnswers(instanceId),
    {
      enabled: !!instanceId,
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

export const useFetchWaitingListSurveyAnswersAtCheckout = (
  waitingListId: string,
  instanceId: string
) =>
  useQuery<ListEntityV2<SurveyAnswersResponse>, Error>(
    surveyInstancesKeys.answersAtCheckout(
      {
        waitinglist_id: waitingListId,
        extension_point: SurveyExtensionPoint.AT_CHECKOUT,
      },
      instanceId
    ),
    () => fetchSurveyAnswers(instanceId),
    {
      enabled: !!instanceId,
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

export const useFetchWaitingListSurveyAnswersWithoutHeaders = (
  waitingListId: string,
  instanceId: string,
  code: string
) =>
  useQuery<ListEntityV2<SurveyAnswersResponse>, Error>(
    surveyInstancesKeys.answersAfterEvent(
      {
        waitinglist_id: waitingListId,
        extension_point: SurveyExtensionPoint.AFTER_EVENT,
      },
      instanceId
    ),
    () => fetchSurveyAnswersWithoutHeaders(instanceId, code),
    {
      enabled: !!instanceId,
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: 3,
    }
  );

export const useFetchFanSurveyAnswers = (
  fanGroupId: string,
  instanceId: string
) =>
  useQuery<ListEntityV2<SurveyAnswersResponse>, Error>(
    surveyInstancesKeys.answersAfterSignIn(fanGroupId, instanceId),
    () => fetchSurveyAnswers(instanceId),
    {
      enabled: !!instanceId,
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

export const useFetchQuestionChoices = (
  questionId: string,
  params: ChoicesQuery
) => {
  return useQuery<ListEntityV2<ChoiceEntity>, Error>(
    questionChoicesKeys.listPerQuestion(questionId),
    () => fetchQuestionChoices(questionId, params),
    {
      enabled: !!questionId,
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const useFetchQuestionChoicesWithoutHeaders = (
  questionId: string,
  params: ChoicesQuery
) => {
  return useQuery<ListEntityV2<ChoiceEntity>, Error>(
    questionChoicesKeys.listPerQuestion(questionId),
    () => fetchQuestionChoicesWithoutHeaders(questionId, params),
    {
      enabled: !!questionId,
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSendAnswers = (
  instanceId: string,
  waitingListId?: string
): UseMutationResult<
  SurveyAnswersResponse,
  AxiosAPIError<SurveyAnswersValidator>,
  SurveyAnswersValidator,
  unknown
> => {
  const queryClient = useQueryClient();
  return waitingListId
    ? useMutation(
        (body: SurveyAnswersValidator) => sendAnswers(instanceId, body),
        {
          onSuccess: () => {
            queryClient.invalidateQueries(
              surveyInstancesKeys.answersBeforeJoin(
                {
                  waitinglist_id: waitingListId,
                  extension_point:
                    SurveyExtensionPoint.BEFORE_JOINING_WAITINGLIST,
                },
                instanceId
              )
            );
            queryClient.invalidateQueries(
              surveyInstancesKeys.answersAtCheckout(
                {
                  waitinglist_id: waitingListId,
                  extension_point: SurveyExtensionPoint.AT_CHECKOUT,
                },
                instanceId
              )
            );
          },
        }
      )
    : useMutation(
        surveyInstancesKeys.mutation(),
        (body: SurveyAnswersValidator) => sendAnswers(instanceId, body)
      );
};

export const useSendAnswersAfterEvent = (
  instanceId: string,
  code: string
): UseMutationResult<
  SurveyAnswersResponse,
  AxiosAPIError<SurveyAnswersValidator>,
  SurveyAnswersValidator,
  unknown
> => {
  // const queryClient = useQueryClient();
  return useMutation(
    (body: SurveyAnswersValidator) =>
      sendAnswersWithoutHeaders(instanceId, code, body),
    {
      onSuccess: () => {
        // queryClient.invalidateQueries(
        //   surveyInstancesKeys.answersBeforeJoin(
        //     {
        //       waitinglist_id: waitingListId,
        //       extension_point: SurveyExtensionPoint.BEFORE_JOINING_WAITINGLIST,
        //     },
        //     instanceId
        //   )
        // );
      },
    }
  );
};

export const useSendFanAnswersAsFGO = (
  instanceId: string,
  fanId: string,
  waitingListId?: string
): UseMutationResult<
  SurveyAnswersResponse,
  AxiosAPIError<SurveyAnswersValidator>,
  SurveyAnswersValidator,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    (body: SurveyAnswersValidator) =>
      sendFanAnswersAsFGO(instanceId, fanId, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          surveyInstancesKeys.answersAtCheckout(
            {
              waitinglist_id: waitingListId,
              extension_point: SurveyExtensionPoint.AT_CHECKOUT,
            },
            instanceId
          )
        );
      },
    }
  );
};

export const useSendGuestAnswersAsHost = (
  instanceId: string,
  fanId: string,
  waitingListId?: string
): UseMutationResult<
  SurveyAnswersResponse,
  AxiosAPIError<SurveyAnswersValidator>,
  SurveyAnswersValidator,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    (body: SurveyAnswersValidator) =>
      sendGuestAnswersAsHost(instanceId, fanId, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          surveyInstancesKeys.answersAtCheckout(
            {
              waitinglist_id: waitingListId,
              extension_point: SurveyExtensionPoint.AT_CHECKOUT,
            },
            instanceId
          )
        );
      },
    }
  );
};

export const useCreateAdminQuestion = (): UseMutationResult<
  Question,
  AxiosAPIError<Question>,
  QuestionFormDataType,
  unknown
> =>
  useMutation(questionsKeys.all(), (body: QuestionFormDataType) =>
    createAdminQuestion(body)
  );

export const useUpdateAdminQuestion = (
  id: string,
  surveyId: string
): UseMutationResult<
  Question,
  AxiosAPIError<Question>,
  QuestionFormDataType,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    questionsKeys.mutation(),
    (body: QuestionFormDataType) => updateAdminQuestion(id, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(surveysKeys.detail(surveyId));
      },
    }
  );
};

export const useDeleteAdminQuestion = () => {
  const queryClient = useQueryClient();
  return useMutation((questionId: string) => deleteAdminQuestion(questionId), {
    onSuccess: () => {
      queryClient.invalidateQueries(questionsKeys.all());
    },
  });
};

export const useCreateAdminQuestionChoices = (): UseMutationResult<
  ChoiceListEntity[],
  AxiosAPIError<QuestionChoicesDataValidator>,
  { questionId: string; body: QuestionChoicesDataValidator },
  unknown
> =>
  useMutation(
    questionChoicesKeys.listPerQuestion(''),
    ({
      questionId,
      body,
    }: {
      questionId: string;
      body: QuestionChoicesDataValidator;
    }) => createAdminQuestionChoices(questionId, body)
  );

export const useUpdateAdminQuestionChoices = (
  questionId: string
): UseMutationResult<
  ChoiceListEntity[],
  AxiosAPIError<QuestionChoicesDataValidator>,
  QuestionChoicesDataValidator,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    questionsKeys.mutation(),
    (body: QuestionChoicesDataValidator) =>
      updateAdminQuestionChoices(questionId, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          questionChoicesKeys.listPerQuestion(questionId)
        );
      },
    }
  );
};

// fetchSurveyFansAnswers

export const useFetchSurveysFansAnswers = (
  waitinglistId: string,
  surveyInstanceId: string,
  params: FansAnswersQuery
) => {
  return useQuery<ListEntityV2<FanAnswers>, Error>(
    surveyFansAnswers.listPerInstance(waitinglistId, surveyInstanceId, params),
    () => fetchSurveyFansAnswers(waitinglistId, surveyInstanceId, params),
    { enabled: !!surveyInstanceId && !!params?.user_id }
  );
};
