import { useUpdateScheduleMailcampaign } from '@seaters-app/data-access';
import { DatePicker, DatePickerProps, notification } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

export function EditScheduleDate({
  mailcampaignId,
  scheduledDate,
}: {
  mailcampaignId: string;
  scheduledDate: Dayjs;
}) {
  const { mutate: scheduleMailCampaign } = useUpdateScheduleMailcampaign();
  const onOk = (value: DatePickerProps['value']) => {
    scheduleMailCampaign(
      { id: mailcampaignId, scheduleDate: dayjs(value) },
      {
        onSuccess: () => {
          notification.success({
            message: 'Success',
            description: 'Mail campaign rescheduled successfully',
          });
        },
        onError: (error: any) => {
          notification.error({
            message: 'Error',
            description: error.response?.data?.message || error.message,
          });
        },
      }
    );
  };
  return (
    <DatePicker
      value={scheduledDate}
      showTime={{ format: 'HH:mm' }}
      disabledDate={(current) => current && current < dayjs().startOf('day')}
      disabledTime={(current) => {
        const now = new Date();
        if (current && current.isSame(now, 'day')) {
          return {
            disabledHours: () => Array.from(Array(now.getHours()).keys()),
            disabledMinutes: (hour) =>
              hour === now.getHours()
                ? Array.from(Array(now.getMinutes()).keys())
                : [],
          };
        }
        return {};
      }}
      onOk={onOk}
    />
  );
}
