import {
  CheckOutlined,
  CloseOutlined,
  DownOutlined,
  SolutionOutlined,
  TagsOutlined,
  UserAddOutlined,
} from '@ant-design/icons';

import { baseColor100, baseColor400, Button } from '@seaters-app/ui';
import {
  Space,
  Table,
  notification,
  ConfigProvider,
  theme,
  Tooltip,
  TableColumnsType,
  Typography,
  Dropdown,
} from 'antd';
import {
  FGOWishListInvitation,
  ImportanceEnum,
  SemanticNameEnum,
  waitingListInvitationsKeys,
} from '@seaters-app/constants';
import {
  queryClient,
  useFGOApproveInvitations,
  useFGOApproveSelectedInvitations,
  useFGORejectInvitations,
} from '@seaters-app/data-access';
import { useParams } from 'react-router-dom';
import { Key, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsMutating } from '@tanstack/react-query';
import styles from './styles.module.css';
import { useWishListInvitations } from './hooks/useWishListInvitations';
import { InvitationStatusTag } from './InvitationStatusTag';
import { NumberItem } from './NumberItem';
import { MenuProps } from 'antd/lib/menu';
import { string } from 'zod';

const { Text } = Typography;

interface Host {
  id: string;
  numberOfInvitations: number;
  host: FGOWishListInvitation['host'];
  key: number;
}

// WLid to test d199abfd-c705-4ccd-a795-894ffbcc58a1
export function Demands() {
  const { token } = theme.useToken();
  const { t } = useTranslation();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedGroupedRowKeys, setSelectedGroupedRowKeys] = useState<{
    [key: string]: Key[];
  }>({});

  const { wishListId = '' } = useParams();

  const { seats, handleTableChange, pagination, isLoading } =
    useWishListInvitations();

  const selectedRowsTotal = selectedRowKeys.filter((rowKey) => {
    const allGuests = seats?.content.map((seat) => seat.id);
    return allGuests?.includes(rowKey.toString());
  }).length;

  const groupedByHost = seats?.content.reduce(
    (
      acc: {
        [key: string]: {
          host: FGOWishListInvitation['host'];
          invitations: FGOWishListInvitation[];
        };
      },
      invitation
    ) => {
      const hostId = invitation?.host?.hostId;

      if (!acc[hostId]) {
        acc[hostId] = { host: invitation?.host, invitations: [] };
      } else if (!acc[hostId].host.firstName) {
        acc[hostId].host = invitation?.host;
      }

      acc[hostId].invitations.push(invitation);
      return acc;
    },
    {}
  );
  const hostsData = groupedByHost
    ? Object.keys(groupedByHost).map((hostId, i) => {
        return {
          id: hostId,
          host: groupedByHost[hostId].host,
          numberOfInvitations: groupedByHost[hostId].invitations.reduce(
            (acc: number, invitation) => {
              const toAdd =
                invitation?.nbrOfRequestedSeats ||
                invitation?.numberOfNonAllocatedSeats;
              return acc + toAdd;
            },
            0
          ),
          key: i,
        };
      })
    : [];

  const handleMenuOnClick = async ({
    key,
    invitation,
  }: {
    key: string;
    invitation: FGOWishListInvitation;
  }) => {
    switch (key) {
      case 'REMOVE':
        handleRejectInvitation(invitation.id);
        break;
      case 'REVIEW':
        handleApproveInvitation(invitation.id);
        break;
      default:
        break;
    }
  };

  const items = (invitation: FGOWishListInvitation): MenuProps['items'] => {
    const disabled = invitation.status !== 'PENDING' || mutating;
    return [
      {
        label: t('review_button_text'),
        key: 'REVIEW',
        icon: <CheckOutlined rev={undefined} />,
        style: {
          color: !disabled ? token.colorSuccess : token.colorTextDisabled,
        },
        disabled: disabled,
      },
      {
        label: t('general_reject'),
        key: 'REMOVE',
        icon: <CloseOutlined rev={undefined} />,
        danger: true,
        disabled: disabled,
      },
    ];
  };

  const { mutate: rejectInvitation } = useFGORejectInvitations(wishListId);
  const { mutate: approveInvitation } = useFGOApproveInvitations(wishListId);
  const { mutate: approveSelectedInvitations } = useFGOApproveSelectedInvitations(wishListId);
  const mutating = !!useIsMutating();

  const resetSelection = () => {
    setSelectedGroupedRowKeys({});
    setSelectedRowKeys([]);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[], key: Key) => {
    const updatedSelectedGroupedRowKeys = { ...selectedGroupedRowKeys };
    updatedSelectedGroupedRowKeys[key.toString()] = newSelectedRowKeys;
    setSelectedGroupedRowKeys(updatedSelectedGroupedRowKeys);
    const updatedSelectedRowsKeys = Object.keys(
      updatedSelectedGroupedRowKeys
    ).reduce((acc: Array<string | Key>, key) => {
      return [...acc, ...updatedSelectedGroupedRowKeys[key], key];
    }, []);
    setSelectedRowKeys(updatedSelectedRowsKeys);
  };

  const onHostSelectChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: Host[]
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);

    if (groupedByHost) {
      let newRows: Array<string | React.Key> = [...newSelectedRowKeys];
      let hostGuests: Array<string | React.Key> = [];
      selectedRows.forEach(({ id }, i) => {
        hostGuests = selectedRows.flatMap(({ id }) =>
          (groupedByHost[id]?.invitations || [])
            .filter((invitation) => invitation.status === 'PENDING')
            .map((invitation) => invitation.id)
        );
      });
      newRows = [...newRows, ...hostGuests];
      setSelectedRowKeys([...newRows]);
    }
  };

  const rowSelection = (key: Key) => ({
    selectedRowKeys,
    onChange: (newSelectedRowKeys: Key[]) =>
      onSelectChange(newSelectedRowKeys, key),
    getCheckboxProps: (record: FGOWishListInvitation) => {
      return {
        disabled: record?.status !== 'PENDING', // Column configuration not to be checked
      };
    },
  });

  const hostRowSelection = {
    selectedRowKeys,
    onChange: onHostSelectChange,
    getCheckboxProps: (record: Host) => {
      const isDisabled =
        groupedByHost &&
        groupedByHost[record.id]?.invitations.every(
          (invitation) => invitation.status !== 'PENDING'
        );
      return {
        disabled: isDisabled,
      };
    },
  };

  const handleOnSuccess = (message: string) => {
    notification.success({
      message: t(message),
    });
    resetSelection();
    queryClient.invalidateQueries(
      waitingListInvitationsKeys.allPerWaitingList(wishListId)
    );
  };

  const handleOnError = (message: string, description: string) => {
    notification.error({
      message: t(message),
      description: t(description),
    });
  };

  const rejectSelected = () => {
    const allSelectedGuests = selectedRowKeys.filter((rowKey) => {
      const allGuests = seats?.content.map((seat) => seat.id);

      return allGuests?.includes(rowKey.toString());
    });

    allSelectedGuests.forEach((invitationId) => {
      rejectInvitation(
        { invitationId: invitationId as string },
        {
          onSuccess: () =>
            handleOnSuccess('notification_success_invitations_rejected'),
        }
      );
    });
  };

  const approveSelected = () => {
    const allSelectedGuests = selectedRowKeys.filter((rowKey) => {
      const allGuests = seats?.content.map((seat) => seat.id);
      return allGuests?.includes(rowKey.toString());
    });

    if (allSelectedGuests.length === 0) return;

    approveSelectedInvitations(allSelectedGuests.map(String), {
      onSuccess: () => {
        handleOnSuccess('notification_success_invitations_reviewed');
      },
      onError: (error) =>
        handleOnError(
          'notification_error_invitations_reviewed',
          error.response?.data?.message as string
        ),
    });
  };


  const handleRejectInvitation = (invitationId: string) => {
    rejectInvitation(
      {
        invitationId,
      },
      {
        onSuccess: () => {
          notification.success({
            message: t('notification_success_invitation_rejected'),
          });
        },
      }
    );
  };

  const handleApproveInvitation = (invitationId: string) => {
    approveInvitation(
      {
        invitationId,
      },
      {
        onSuccess: () => {
          notification.success({
            message: t('notification_success_invitation_approved'),
          });
        },
        onError: (error) => {
          notification.error({
            message: t('notification_error_invitation_approved'),
            description: t(error.response?.data.message),
          });
        },
      }
    );
  };

  const expandedRowRender = (record: Host) => {
    const columns: TableColumnsType<FGOWishListInvitation> = [
      {
        title: t('wl_status-label'),
        dataIndex: 'name',
        width: '20%',
        render: (_, invitation: FGOWishListInvitation) => {
          return <InvitationStatusTag status={invitation.status} />;
        },
      },
      {
        title: t('guest_title'),
        dataIndex: 'guest',
        width: '30%',
        render: (guest: FGOWishListInvitation['guest']) => {
          return (
            <span>
              {guest.firstName} {guest.lastName}
            </span>
          );
        },
      },
      {
        width: '10%',
        dataIndex: 'host',
        render: (host: FGOWishListInvitation['host'], record) => {
          const isPublicOfficial =
            record.guest.customInfos.find(
              (customInfo) =>
                customInfo.semanticName === SemanticNameEnum.PUBLIC_OFFICIAL
            )?.informationValue === 'true';

          return (
            <Space>
              {record.importance === ImportanceEnum.OPTIONAL && (
                <Tooltip title={t('general_reserve')}>
                  <UserAddOutlined rev={undefined} />
                </Tooltip>
              )}
              {record.invitationReason && (
                <Tooltip title={record.invitationReason}>
                  <TagsOutlined rev={undefined} />
                </Tooltip>
              )}
              {isPublicOfficial && (
                <Tooltip title={t('public_official')}>
                  <SolutionOutlined rev={undefined} />
                </Tooltip>
              )}
            </Space>
          );
        },
      },
      {
        title: t('wl_set_seats'),
        dataIndex: 'nbrOfRequestedSeats',
        width: '20%',
        render: (
          nbrOfRequestedSeats: number,
          record: FGOWishListInvitation
        ) => {
          return nbrOfRequestedSeats || record.numberOfNonAllocatedSeats;
        },
      },
      {
        dataIndex: 'id',
        align: 'right',
        width: '20%',
        render: (_, invitation: FGOWishListInvitation) => {
          return (
            <Space size={0}>
              <Dropdown
                trigger={['click']}
                menu={{
                  items: items(invitation),
                  onClick: ({ key }) => handleMenuOnClick({ key, invitation }),
                }}
              >
                <Button type="link">
                  <Space>
                    {t('mwl_wl_table-actions')}
                    <DownOutlined rev={undefined} />
                  </Space>
                </Button>
              </Dropdown>
            </Space>
          );
        },
      },
    ];

    const data = groupedByHost ? groupedByHost[record.id].invitations : [];

    return (
      <ConfigProvider
        theme={{
          components: {
            Table: {
              colorBgContainer: baseColor100,
            },
          },
        }}
      >
        <Table
          rowHoverable={false}
          rowClassName={styles.table1}
          columns={columns}
          dataSource={data}
          pagination={false}
          rowKey={(record) => record?.id}
          rowSelection={rowSelection(record.key)}
          size="small"
          loading={isLoading}
        />
      </ConfigProvider>
    );
  };
  const hostsColumn: TableColumnsType<Host> = [
    {
      title: `${t('host_title')}`,
      dataIndex: 'host',
      width: '20%',
      render: (host: Host['host']) => {
        return (
          <Text strong>
            {host?.firstName} {host?.lastName}
          </Text>
        );
      },
    },
    {
      title: `${t('host_title')} ${t('admin_user_email')}`,
      dataIndex: 'host',
      width: '40%',
      render: (host: Host['host']) => {
        return <Text strong>{host?.hostEmail}</Text>;
      },
    },
    {
      width: '40%',
      title: t('number_of_invitations_label'),
      dataIndex: 'numberOfInvitations',
    },
  ];

  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          backgroundColor: token.colorPrimaryBg,
          borderBottom: `1px solid ${token.colorBorder}`,
        }}
      >
        {selectedRowKeys.length ? (
          <ConfigProvider
            theme={{
              token: {
                colorText: baseColor100,
              },
              components: {
                Input: {
                  addonBg: baseColor100,
                  hoverBg: baseColor100,
                },
              },
            }}
          >
            <div
              className={styles.distributeBlock}
              style={{ justifyContent: 'flex-end' }}
            >
              <Space size={24}>
                <NumberItem
                  label={t('selected_invitations_label')}
                  value={selectedRowsTotal}
                  isInverted
                />

                <Button
                  danger
                  type="text"
                  onClick={rejectSelected}
                  disabled={mutating}
                >
                  {t('general_reject')} {selectedRowsTotal}{' '}
                  {t('invitations_label_text')}
                </Button>
                <Button
                  type="primary"
                  onClick={approveSelected}
                  style={{
                    color: 'white',
                  }}
                  disabled={mutating}
                >
                  {t('review_selected_button_text')}
                </Button>
              </Space>
            </div>
          </ConfigProvider>
        ) : null}
      </div>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              colorBgContainer: baseColor100,
              borderColor: baseColor400,
            },
          },
        }}
      >
        <Table
          rowClassName={styles.table0}
          columns={hostsColumn}
          rowHoverable={false}
          expandable={{
            expandedRowRender: (record) => expandedRowRender(record),
            defaultExpandedRowKeys: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
          }}
          rowKey={(record) => record.key}
          loading={isLoading}
          rowSelection={hostRowSelection}
          onChange={handleTableChange}
          pagination={pagination}
          dataSource={hostsData}
        />
      </ConfigProvider>
    </div>
  );
}
