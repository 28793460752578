import {
  DatePicker as AntdDatePicker,
  DatePickerProps,
  ConfigProvider,
} from 'antd';
import frFR from 'antd/locale/fr_FR';
import enGB from 'antd/locale/en_GB';
import nlBE from 'antd/locale/nl_BE';
import nlNL from 'antd/locale/nl_NL';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Locale } from 'antd/lib/locale';

const matchLangAndLocale: { [key: string]: Locale } = {
  en: enGB,
  fr: frFR,
  nl_BE: nlBE,
  nl_NL: nlNL,
  nl: nlBE,
};

export const DatePicker: React.FC<DatePickerProps> = ({ ...rest }) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const locale = matchLangAndLocale[language] ?? enGB;

  return (
    <ConfigProvider locale={locale}>
      <AntdDatePicker {...rest} />
    </ConfigProvider>
  );
};
