import {
  Alert,
  Card,
  Checkbox,
  Divider,
  Input,
  Space,
  Tooltip,
  Typography,
  notification,
  theme,
} from 'antd';
import styles from './tickets.module.css';
import { useState } from 'react';
import {
  PlusOutlined,
  MinusOutlined,
  HeartOutlined,
  StarOutlined,
  LockOutlined,
} from '@ant-design/icons';
import { Button, errorColor } from '@seaters-app/ui';
import Meta from 'antd/es/card/Meta';
import {
  PositionsDistributionMode,
  SurveyAnswersResponse,
  SurveyInstanceEntity,
  WaitingListEntity,
  DisplayMode,
  AccessMode,
  SESSION_STORAGE_TOTAL_TICKETS,
  UserRole,
  InvitationMode,
  ImportanceEnum,
  routes,
  WaitingListStatusEnum,
  LookEntity,
  usersKeys,
  WLBadgesStateEnum,
  WLPositionStatus,
  RequestAccessStatus,
} from '@seaters-app/constants';
import dayjs from 'dayjs';
import {
  getObjectTranslation,
  getSlugFromUrl,
  queryClient,
  useCreateWaitingListInvitation,
  useFetchFGBySlug,
  useFetchFanFGSeats,
  useFetchHost,
  useFetchHostWLAdditionalInfo,
  useFetchUserRolesForCurrentFG,
  useFetchWLAdditionalInfo,
  useIsCustomFlow,
} from '@seaters-app/data-access';
import { useNavigate } from 'react-router-dom';
import { getCounterValue } from '../../helpers';
import { useTranslation } from 'react-i18next';
import { NumberItemVertical } from './../../../../wish-lists/[id]/NumberItemVertical';
import { SSOLoginTypes } from 'apps/seaters/src/pages/fan-groups/fgo-configuration/FGRegistrationConfig';

const { Text } = Typography;

interface RequestTicketsComponentProps {
  waitingList: WaitingListEntity;
  setSurveyShown: (v: boolean) => void;
  survey?: SurveyInstanceEntity;
  surveyAnswers?: SurveyAnswersResponse;
  joinWL: () => void;
  loading: boolean;
  counter: number;
  setCounter: (counter: number) => void;
  numberOfParkingTickets: number;
  setNumberOfParkingTickets: (v: number) => void;
  isMobile?: boolean;
  setProtectedCodeModalShown: (v: boolean) => void;
  setSSOWarningModalShown: (v: boolean) => void;
  setRestrictedWLModal: (v: boolean) => void;
  badgesState?: WLBadgesStateEnum;
}

function RequestTicketsComponent({
  waitingList,
  setSurveyShown,
  survey,
  surveyAnswers,
  joinWL,
  setSSOWarningModalShown,
  loading,
  counter,
  setCounter,
  numberOfParkingTickets,
  setNumberOfParkingTickets,
  isMobile,
  setProtectedCodeModalShown,
  setRestrictedWLModal,
  badgesState,
}: RequestTicketsComponentProps) {
  const slug = getSlugFromUrl();

  const isComingSoonWL =
    waitingList?.waitingListStatus === WaitingListStatusEnum.PUBLISHED;

  const lookEntity = queryClient.getQueryData<LookEntity>(usersKeys.look());
  const { data: fanGroupBySlugData } = useFetchFGBySlug(slug ?? '');

  const { data: rolesData } = useFetchUserRolesForCurrentFG(
    fanGroupBySlugData?.id ?? ''
  );
  const isHost = !!rolesData?.roles.find((role) => role === UserRole.HOST);

  const isDetailedWL = waitingList.displayMode === DisplayMode.DETAILED;
  const { data: wlAdditionalInfo } = useFetchWLAdditionalInfo(
    waitingList.waitingListId,
    isDetailedWL && !isHost
  );

  const { data: wlAdditionalInfoForHost } = useFetchHostWLAdditionalInfo(
    waitingList.waitingListId,
    isDetailedWL && !!isHost
  );

  const additionalDetailedInfo = isHost
    ? wlAdditionalInfoForHost
    : wlAdditionalInfo;

  const {
    positionsDistributionMode,
    participationEndDate,
    maxNumberOfSeatsPerHost,
    price,
    currency: { symbol },
    displayMode,
    freeWaitingList,
    termsAndConditionFileURL,
    waitingListId,
    directSalesEnabled,
  } = waitingList;

  const { token } = theme.useToken();

  const navigate = useNavigate();
  const { mutateAsync: createWaitingListInvitation } =
    useCreateWaitingListInvitation(waitingListId);
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const lang = language === 'nl_NL' || language === 'nl_BE' ? 'nl' : language;
  const { data: host } = useFetchHost();

  const { data: seats } = useFetchFanFGSeats(fanGroupBySlugData?.id ?? '');
  const userTicketsLimit = seats?.directSaleModeTicketsLimit;
  const userTicketsLeft = userTicketsLimit
    ? Number(userTicketsLimit - (seats?.numberOfSeats ?? 0))
    : undefined;
  const increaseCounter = () => {
    setCounter(counter + 1);
  };
  const decreaseCounter = () => {
    setCounter(counter - 1);
  };

  const { minValue, maxValue } = getCounterValue(
    waitingList,
    isHost,
    userTicketsLeft
  );

  const increaseParkingCounter = () => {
    setNumberOfParkingTickets(numberOfParkingTickets + 1);
  };
  const decreaseParkingCounter = () => {
    setNumberOfParkingTickets(numberOfParkingTickets - 1);
  };
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const onChange = () => {
    setTermsAndConditions(!termsAndConditions);
  };
  const isWithParking =
    positionsDistributionMode === PositionsDistributionMode.WITH_PARKING;
  const isRandomDistribution =
    positionsDistributionMode === PositionsDistributionMode.RANDOM;
  const isRankBasedDistribution =
    positionsDistributionMode === PositionsDistributionMode.FIFS;
  const isInformativeDisplayMode = displayMode === DisplayMode.INFORMATIVE;
  const isContestDisplayMode = displayMode === DisplayMode.CONTEST;

  const isInvitationMode =
    waitingList.invitationMode === InvitationMode.ENABLED;
  const isCustomFlow = useIsCustomFlow();

  // Hide "Please specify your guestlist by clicking the button below to get the approval." for custom flow
  const specifyGuestListText = isCustomFlow
    ? null
    : t('event_specifyGuestList_label');

  const fanGroupModeCondition =
    lookEntity?.properties?.fanGroupMode === 'NO_JOINED_WAITING_LIST';

  const isWLProtected = waitingList?.accessMode === AccessMode.CODE_PROTECTED;

  const isRequestAccepted =
    waitingList?.request?.status === RequestAccessStatus.ACCEPTED;

  // TODO extract to function with extra info
  const additionalInfo =
    isHost && isInvitationMode
      ? specifyGuestListText
      : isWithParking
      ? isContestDisplayMode
        ? `${t('waitinglist_standard_distribution_explanation_contest')} ${t(
            'waitinglist_standard_distribution_with_parking_explanation'
          )}`
        : t('waitinglist_standard_distribution_with_parking_explanation')
      : isRandomDistribution
      ? t('waitinglist_random_distribution_explanation')
      : directSalesEnabled && fanGroupModeCondition
      ? t('waitinglist_direct_sales_standard_explanation')
      : t('waitinglist_standard_distribution_explanation_contest');

  const onClickRequest = () => {
    if (badgesState === WLBadgesStateEnum.RESTRICTED) {
      setRestrictedWLModal(true);
      return;
    }
    if (counter > maxValue) {
      setCounter(maxValue);
      notification.warning({
        message: `${t('waitinglist_max_tickets_per_host')}: ${maxValue}`,
      });
      return;
    } else if (counter < minValue) {
      setCounter(minValue);
      notification.warning({
        message: `${t('waitinglist_min_tickets_per_host')} ${minValue}`,
      });
      return;
    }
    if (isCustomFlow && isHost && host) {
      createWaitingListInvitation(
        {
          guestId: host.userId,
          body: {
            numberOfSeats: 0,
            importance: ImportanceEnum.REQUIRED,
            numberOfNonAllocatedSeats: counter,
          },
        },
        {
          onError: (err) => {
            console.error(err);
            notification.error({
              message: t('invitation_create_notification_error'),
              description: err.response?.data.message,
            });
          },
          onSuccess: () => {
            notification.success({
              message: t('invitation_create_notification_success'),
            });
            navigate(routes.guestlist);
          },
        }
      );
    } else if (isInvitationMode && isHost) {
      sessionStorage.setItem(SESSION_STORAGE_TOTAL_TICKETS, counter.toString());
      navigate(routes.guestlist);
    } else if (survey) {
      setSurveyShown(true);
    } else {
      joinWL();
    }
  };

  // const maxTicketsValue = isHost
  //   ? maxNumberOfSeatsPerHost
  //   : maxNumberOfSeatsPerPosition;

  const totalTickets = seats?.numberOfSeats ?? 0;
  const ticketsLimit = userTicketsLimit ?? 0;

  const limitReached =
    directSalesEnabled &&
    fanGroupModeCondition &&
    !!userTicketsLimit &&
    ((seats?.numberOfSeats ?? 0) >= (userTicketsLimit ?? 0) ||
      additionalDetailedInfo?.availableSeats === 0 ||
      userTicketsLimit < counter ||
      (additionalDetailedInfo &&
        additionalDetailedInfo?.availableSeats < counter));

  const maxLimit = additionalDetailedInfo
    ? Math.min(
        ticketsLimit - totalTickets,
        additionalDetailedInfo?.availableSeats,
        maxValue
      )
    : lookEntity?.properties?.directSaleModeTicketsLimit
    ? Math.min(ticketsLimit - totalTickets, maxValue)
    : maxValue;

  const max = fanGroupModeCondition && directSalesEnabled ? maxLimit : maxValue;

  const isNotAllowedToSeeWLs =
    !fanGroupBySlugData?.fanMember &&
    lookEntity?.login.length === 1 &&
    SSOLoginTypes.includes(lookEntity?.login[0].type);

  return (
    <Card
      className={!isMobile ? styles.card : styles.cardMobile}
      bordered={!isMobile}
      title={
        isCustomFlow ? (
          <div className={styles.actions}>
            <Text style={{ color: token.colorPrimary }} strong>
              {t('general_number_of_seats', { seats: maxNumberOfSeatsPerHost })}
            </Text>
          </div>
        ) : fanGroupBySlugData?.fanMember &&
          (!isWLProtected || isRequestAccepted) ? (
          freeWaitingList ? (
            <div className={styles.cardTitleRequest}>
              {!!Number(price?.discountAmount) && waitingList.showOriginalValue && (
                <Text style={{ color: errorColor }}>
                  {t('invite_wl_value')}{' '}
                  {Number(price?.discountAmount) * counter} {symbol}
                </Text>
              )}
              <Text strong>{t('component_waitinglist_price-free')}</Text>
            </div>
          ) : (
            <div className={styles.cardTitleRequest}>
              <Text strong>
                {(counter *
                  (Number(price?.facialPrice) + Number(price?.fee)) *
                  100) /
                  100}{' '}
                {symbol}
              </Text>
              {!!Number(price?.discountAmount) && waitingList.showOriginalValue && (
                <Text type="danger">
                  {t('invite_wl_value')} {Number(price?.originalPrice)}
                  {symbol} {t('waitinglist_per_ticket')}
                </Text>
              )}
            </div>
          )
        ) : fanGroupBySlugData?.accessMode !== AccessMode.PRIVATE ? (
          (fanGroupBySlugData?.accessMode === AccessMode.CODE_PROTECTED ||
            isWLProtected) && (
            <Button
              disabled={isComingSoonWL}
              size="large"
              type="primary"
              style={{ width: '100%' }}
              onClick={() => {
                isNotAllowedToSeeWLs
                  ? setSSOWarningModalShown(true)
                  : setProtectedCodeModalShown(true);
              }}
              icon={<HeartOutlined rev={undefined} />}
            >
              {isWLProtected
                ? t('code_protected_wl')
                : t('entry_group_about_access-howto_public_step2', {
                    'group-name': fanGroupBySlugData?.translatedName,
                  })}
            </Button>
          )
        ) : (
          <Alert
            className={styles.alert}
            type="error"
            message={
              <div className={styles.alertContent}>
                {t('private_fg_warning_text')}
              </div>
            }
          />
        )
      }
    >
      <div
        className={
          !fanGroupBySlugData?.fanMember ||
          (isWLProtected && !isRequestAccepted)
            ? styles.blurred
            : styles.content
        }
      >
        {isDetailedWL && additionalDetailedInfo && (
          <Space size={15} style={{ alignItems: 'flex-start' }}>
            {directSalesEnabled &&
              lookEntity?.properties.directSaleModeTicketsLimit && (
                <>
                  <NumberItemVertical
                    maxWidth={120}
                    label={t('total_tickets_limit_label_text')}
                    value={`${seats?.numberOfSeats ?? 0} / ${
                      userTicketsLimit ?? 0
                    }`}
                  />
                  <Divider style={{ height: 60 }} type="vertical" />{' '}
                </>
              )}

            <NumberItemVertical
              maxWidth={120}
              label={t('waitinglist_remaining_tickets')}
              value={additionalDetailedInfo.availableSeats}
              direction={directSalesEnabled ? 'vertical' : 'horizontal'}
            />
          </Space>
        )}
        <div className={styles.countersContainer}>
          {isWithParking && (
            <Meta
              style={{
                width: '100%',
              }}
              description={t('general_tickets_quantity')}
            />
          )}

          <div className={styles.counter}>
            {!isRandomDistribution && (
              <Tooltip
                title={`${t('waitinglist_min_tickets_per_host')}  ${minValue}`}
              >
                <Button
                  size="large"
                  type="text"
                  disabled={isComingSoonWL || counter < minValue + 1}
                  color={token.colorPrimary}
                  icon={
                    <MinusOutlined style={{ fontSize: 28 }} rev={undefined} />
                  }
                  onClick={decreaseCounter}
                />
              </Tooltip>
            )}
            <Input
              style={{
                fontSize: '38px',
                border: 'none',
                width: '100px',
                textAlign: 'center',
              }}
              disabled={isComingSoonWL}
              min={minValue}
              max={max}
              defaultValue={max}
              onChange={(e) => {
                setCounter(Number(e.target.value));
              }}
              value={counter}
            />

            {!isRandomDistribution && (
              <Tooltip
                title={`${t('waitinglist_max_tickets_per_host')} ${max}`}
              >
                <Button
                  disabled={isComingSoonWL || counter > max - 1}
                  size="large"
                  type="text"
                  color={token.colorPrimary}
                  icon={
                    <PlusOutlined style={{ fontSize: 28 }} rev={undefined} />
                  }
                  onClick={increaseCounter}
                />
              </Tooltip>
            )}
          </div>
        </div>
        {isWithParking && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Meta
              style={{
                width: '100%',
              }}
              description={t('queue_price-table_nbrofparkings')}
            />

            <div className={styles.counter}>
              <Button
                disabled={isComingSoonWL}
                size="large"
                type="text"
                color={token.colorPrimary}
                icon={
                  <MinusOutlined style={{ fontSize: 28 }} rev={undefined} />
                }
                onClick={decreaseParkingCounter}
              />
              {numberOfParkingTickets}
              <Tooltip title={`${t('max_amount_parking_label_text')}  ${max}`}>
                <Button
                  disabled={isComingSoonWL || numberOfParkingTickets > max - 1}
                  size="large"
                  type="text"
                  color={token.colorPrimary}
                  icon={
                    <PlusOutlined style={{ fontSize: 28 }} rev={undefined} />
                  }
                  onClick={increaseParkingCounter}
                />
              </Tooltip>
            </div>
          </div>
        )}
        {termsAndConditionFileURL && (
          <Checkbox
            defaultChecked={false}
            value={termsAndConditions}
            onChange={onChange}
          >
            {t('waitinglist_i_accept_the')}{' '}
            <a
              href={termsAndConditionFileURL}
              target="_blank"
              rel="noreferrer"
              style={{ color: token.colorPrimary }}
            >
              {t('waitinglist_terms_and_conditions')}
            </a>
          </Checkbox>
        )}

        <Button
          size="large"
          type="primary"
          disabled={
            isComingSoonWL ||
            (!termsAndConditions && !!termsAndConditionFileURL) ||
            !counter ||
            (!fanGroupBySlugData?.fanMember &&
              fanGroupBySlugData?.accessMode !== AccessMode.PUBLIC) ||
            (isWLProtected && !isRequestAccepted) ||
            limitReached
          }
          style={{ width: '100%' }}
          onClick={onClickRequest}
          loading={
            loading ||
            waitingList?.position?.status === WLPositionStatus.BEING_PROCESSED
          }
          icon={
            isComingSoonWL ? (
              <LockOutlined rev={undefined} />
            ) : (
              <StarOutlined rev={undefined} />
            )
          }
        >
          {isComingSoonWL
            ? t('group_waitinglists_comingsoon-label')
            : t('queue_header_join-btn').toUpperCase()}
        </Button>

        {!freeWaitingList && (
          <div className={styles.prices}>
            <div className={styles.price}>
              <Text>{`${price?.facialPrice} ${symbol} x ${
                lookEntity?.properties?.waitinglists
                  ? getObjectTranslation(
                      lookEntity?.properties?.waitinglists
                        ?.waitinglist_ticket_price,
                      lang
                    )
                  : `${counter} ${t('guests')}`
              }`}</Text>
              <Text>
                {counter * Number(price?.facialPrice)} {symbol}
              </Text>
            </div>
            <div className={styles.price}>
              <Text>{t('waitinglist_fees')}</Text>
              <Text>
                {counter * Number(price?.fee)} {symbol}
              </Text>
            </div>
            <div className={styles.price}>
              <Text strong>{t('waitinglist_total_price')}</Text>
              <Text strong>
                {(counter *
                  (Number(price?.facialPrice) + Number(price?.fee)) *
                  100) /
                  100}{' '}
                {symbol}
              </Text>
            </div>
          </div>
        )}
        {isRandomDistribution && (
          <Text strong className={styles.distributionDate}>
            {t('wishlist_closes_at_label_text')}:
            <br />
            {`${
              participationEndDate
                ? dayjs(participationEndDate).format('ddd MMM D YYYY h:mm A')
                : ''
            }`}
            <br />
            {t('wishlist_random_distribution_closed_description')}
          </Text>
        )}

        <Meta
          description={
            <>
              {!(isInformativeDisplayMode && isRankBasedDistribution) &&
                additionalInfo}{' '}
            </>
          }
          className={styles.additionalInfo}
        />
      </div>
    </Card>
  );
}

export default RequestTicketsComponent;
