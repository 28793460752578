import { useState } from 'react';
import {
  Input,
  Col,
  Row,
  notification,
  Select,
  Switch,
  Spin,
  Modal,
} from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import Form from 'antd/es/form';
import { FanProfileFooter, Footer } from '@seaters-app/ui-shared';

import { Button, DatePicker, PhoneInputWithCountryCode } from '@seaters-app/ui';
import {
  FanProfileFormEntity,
  SESSION_STORAGE_VERIFICATION,
  languages,
  routes,
  userLanguages,
} from '@seaters-app/constants';
import styles from '../../profile.module.css';
import {
  getFanFieldsToUpdate,
  getSlugFromUrl,
  useAppUserStore,
  useFetchCountries,
  useFetchLook,
  useRefreshToken,
  useUpdateFanEmail,
  useUpdateFanPhone,
  useUpdateFanProfile,
  useUserProfile,
} from '@seaters-app/data-access';
import { useJsApiLoader } from '@react-google-maps/api';

import { getInitialValues } from '../../helpers/getInitialValues';
import { MobilePhoneVerificationModal } from '../../components/MobilePhoneVerificationModal';
import { PasswordForm } from './PasswordForm';
import { useIsFetching } from '@tanstack/react-query';
import { GooglePlacesSearch } from '../../../../shared/GooglePlacesSearch';
import { googleMapsApiKey } from '@seaters-app/constants';
import { useNavigate } from 'react-router-dom';
import { useMessage } from '../../ProfileLayout';
import { useTranslation } from 'react-i18next';
import { getDetails, SetValue } from 'use-places-autocomplete';
import { simplifySearchResult } from '../../../../shared/simplifySearchResults';
const { Title } = Typography;

export function FanProfile() {
  const { t, i18n } = useTranslation();
  const detectedSlug = getSlugFromUrl();
  const { data: fanGroupData } = useFetchLook(detectedSlug ?? '');

  const optInPlatformHidden = fanGroupData?.optInPlatformHidden;
  const optInPartnersHidden = fanGroupData?.optInPartnersHidden;
  const navigate = useNavigate();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey,
    libraries: ['places'],
  });

  const isAdminApp = window.location.pathname.includes('admin');

  const slug = getSlugFromUrl();
  const [form] = Form.useForm();
  const isFetching = !!useIsFetching();

  const [disabledSave, setDisabledSave] = useState(true);

  const { data: countries } = useFetchCountries({
    itemOffset: 0,
    maxPageSize: 240,
  });

  const fanLanguagesOptions = userLanguages.map((language) => ({
    label: language.name.en,
    value: language.locale,
  }));

  const adminLanguagesOptions = languages
    .map((language) => ({
      label: language.name.en,
      value: language.locale === 'nl' ? 'nl_BE' : language.locale,
    }))
    .slice(0, 3);

  const countriesOptions = countries?.items.map((country) => ({
    label: country.name,
    value: country.alpha2Code,
  }));

  const email = Form.useWatch('email', form);

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setDisabledSave(hasErrors);
  };

  const { mutate: updateFan, isLoading: isFanProfileUpdating } =
    useUpdateFanProfile();

  const token = JSON.parse(
    sessionStorage.getItem(SESSION_STORAGE_VERIFICATION) ?? ''
  ).token.value;

  const { mutate: updateFanPhone } = useUpdateFanPhone();

  const { mutate: updateFanEmail } = useUpdateFanEmail();

  const { mutate: refresh } = useRefreshToken();

  const { data: fanProfile } = useUserProfile(true);
  const { user, setUser } = useAppUserStore();

  const initialValues = getInitialValues(fanProfile);

  const [verifyEmailButtonDisabled, setVerifyEmailButtonDisabled] = useState(
    (initialValues.validatedEmail && initialValues.email === email) ||
      isFetching
  );

  const [phoneNumber, setPhoneNumber] = useState(
    initialValues.countryCallingCode + initialValues.localNumber
  );
  const [countryCode, setCountryCode] = useState(
    initialValues?.countryCallingCode
  );
  const [isModalShown, setIsModalShown] = useState(false);
  const { setMessage } = useMessage();

  const onFinish = async (values: FanProfileFormEntity) => {
    if (fanProfile) {
      const userToUpdate = getFanFieldsToUpdate(
        {
          ...values,
          localNumber: phoneNumber.slice(countryCode.length),
          countryCallingCode: countryCode,
        },
        fanProfile
      );
      updateFan(userToUpdate, {
        onSuccess: () => {
          notification.success({
            message: t('general_profile_update_notification_success'),
          });
          window.scrollTo(0, 0);
          navigate(`../${routes.interests}`);
          setMessage(true);
          if (userToUpdate.language !== initialValues.language) {
            refresh(token, {
              onSuccess: (res) => {
                if (user) {
                  setUser({ ...user, language: res.userData?.locale });
                }
              },
            });
          }
        },
        onError: (err) => {
          console.error(err);
          notification.error({
            message: t('fan_profile_updated_error'),
          });
        },
      });
    }
  };

  const showInfo = () => {
    Modal.warning({
      title: t('info_message_modal_title'),
      width: 500,
      content: <div>{t('email_confirmation_email_sent_message')}</div>,
    });
  };

  const verifyEmail = (email: string) => {
    updateFanEmail(
      { email },
      {
        onSuccess: (data) => {
          if (data.email !== initialValues.email) {
            notification.success({
              message: t('fan_email_updated_success'),
            });
          }
          showInfo();
        },
        onError: (err) => {
          console.error(err);
          notification.error({
            message: t('fan_email_updated_error'),
          });
        },
      }
    );
  };

  const verifyPhone = (countryCallingCode: string, localNumber: string) => {
    updateFanPhone(
      { countryCallingCode, localNumber },
      {
        onSuccess: (data) => {
          const { mobilePhoneNumber } = data;
          if (
            mobilePhoneNumber?.countryCallingCode !==
              initialValues.countryCallingCode ||
            mobilePhoneNumber?.localNumber !== initialValues.localNumber
          ) {
            notification.success({
              message: t('fan_phone_updated_success'),
            });
          }

          setIsModalShown(true);
        },
        onError: (err) => {
          console.error(err);
          notification.error({
            message: t('fan_phone_updated_error'),
            description: t(err.response?.data.message),
          });
        },
      }
    );
  };

  const handleSelectPlace = async (
    name: string,
    id: string,
    setValueForPlace: SetValue
  ) => {
    setValueForPlace(name, false);

    const details = await getDetails({
      placeId: id,
    });

    const simplifiedPlace = simplifySearchResult(details ?? '');

    if (simplifiedPlace) {
      form.setFieldValue('line1', simplifiedPlace?.formatted_address ?? '');
      form.setFieldValue('country', simplifiedPlace?.countryShortName ?? '');
      form.setFieldValue('city', simplifiedPlace?.city ?? '');
      form.setFieldValue('state', simplifiedPlace?.state ?? '');
      form.setFieldValue('postalCode', simplifiedPlace?.postalCode ?? '');
    }
  };

  return (
    <Spin tip={t('general_please_wait')} spinning={isFanProfileUpdating}>
      <Form
        id="profileForm"
        form={form}
        name="basic"
        layout="vertical"
        initialValues={initialValues}
        onFinish={onFinish}
        autoComplete="off"
        onFieldsChange={handleFormChange}
        disabled={isFanProfileUpdating}
      >
        <Title className={styles.subtitle} level={4}>
          {t('settings_preferences')}
        </Title>
        <Row gutter={24}>
          <Col xs={24} sm={18} md={12} lg={8} xl={8} xxl={10}>
            <Form.Item
              name="language"
              label={t('settings_personal-section_form_lang-label')}
              rules={[{ required: true }]}
            >
              <Select
                options={
                  isAdminApp ? adminLanguagesOptions : fanLanguagesOptions // show only EN, FR, NL (BE) languages for admin app
                }
                onChange={(lng) => i18n.changeLanguage(lng)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[{ xs: 10, sm: 24 }, 24]}>
          <Col xs={18} md={12} lg={8} xl={8} xxl={10}>
            <Form.Item
              label={t('settings_personal-section_form_email-label')}
              name="email"
              rules={[
                { required: true, message: t('profile_email_validation') },
                { whitespace: true },
                { type: 'email' },
              ]}
            >
              <Input onChange={() => setVerifyEmailButtonDisabled(false)} />
            </Form.Item>
          </Col>
          <Col xs={4} md={4}>
            <Form.Item label="hidden">
              <Button
                onClick={() => {
                  setVerifyEmailButtonDisabled(true);
                  verifyEmail(email);
                }}
                disabled={verifyEmailButtonDisabled}
              >
                {t('general_verify_action')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[{ xs: 10, sm: 24 }, 24]}>
          <Col xs={18} md={12} lg={8} xl={8} xxl={10}>
            <Form.Item
              label={t('settings_phone_form_phone-label')}
              name="localNumber"
            >
              <PhoneInputWithCountryCode
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                setDisabledSave={setDisabledSave}
                countryCode={countryCode}
                setCountryCode={setCountryCode}
                disabled={isFetching}
              />
            </Form.Item>
          </Col>
          <Col xs={4}>
            <Form.Item label="hidden">
              <Button
                onClick={() =>
                  verifyPhone(
                    countryCode,
                    phoneNumber.slice(countryCode.length)
                  )
                }
                disabled={
                  (initialValues.validatedMobilePhone &&
                    initialValues.countryCallingCode === countryCode &&
                    initialValues.localNumber ===
                      phoneNumber.slice(countryCode.length)) ||
                  isFetching
                }
              >
                {t('general_verify_action')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Title className={styles.subtitle} level={4}>
          {t('profile_menu_personal-infos')}
        </Title>
        <Row gutter={24}>
          <Col xs={24} md={12} lg={8} xl={8} xxl={10}>
            <Form.Item
              label={t('settings_personal-section_form_firstname-label')}
              name="firstName"
              rules={[
                {
                  required: true,
                  message: t('checkout_missingfield_firstname'),
                },
                { whitespace: true },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8} xl={8} xxl={10}>
            <Form.Item
              label={t('settings_personal-section_form_lastname-label')}
              name="lastName"
              rules={[
                {
                  required: true,
                  message: t('checkout_missingfield_lastname'),
                },
                { whitespace: true },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={12} lg={8} xl={8} xxl={10}>
            <Form.Item
              label={t('settings_personal-information_date-of-birth-label')}
              name="birthDate"
            >
              <DatePicker
                format="DD-MM-YYYY"
                picker="date"
                className={styles.datePicker}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={12} lg={8} xl={8} xxl={10}>
            <Form.Item
              label={t('settings_personal-information_citizenship-label')}
              name="citizenship"
              rules={[{ whitespace: true }]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option?.label.toLowerCase().includes(input.toLowerCase()) ??
                  false
                }
                options={countriesOptions}
              />
            </Form.Item>
          </Col>
        </Row>
        {/* <Row gutter={24}>
          <Col xs={24} md={12} lg={8} xl={8} xxl={10}>
            <Form.Item
              label={t('settings_personal-information_company-label')}
              name="company"
              rules={[{ whitespace: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row> */}
        <Row gutter={24}>
          {isLoaded && (
            <Col xs={24} md={12} lg={16} xl={8} xxl={20}>
              <Form.Item
                label={t('settings_personal-information_address-label-line1')}
                name="line1"
                rules={[{ whitespace: true }]}
              >
                <GooglePlacesSearch
                  defaultValue={initialValues?.line1 ?? ''}
                  handleSelect={handleSelectPlace}
                  placeholder={t('google_search_placeholder')}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={12} lg={16} xl={8} xxl={20}>
            <Form.Item
              label={`${t(
                'settings_personal-information_address-label-line2'
              )} (${t('general_label_optional')})`}
              name="line2"
              rules={[{ whitespace: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        {/* <Row gutter={24}>
          <Col xs={24} md={12} lg={16} xl={8} xxl={20}>
            <Form.Item
              label={`${t(
                'settings_personal-information_address-label-line3'
              )} (${t('general_label_optional')})`}
              name="line3"
              rules={[{ whitespace: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row> */}
        <Row gutter={24}>
          <Col xs={24} md={12} lg={8} xl={8} xxl={10}>
            <Form.Item
              label={t('settings_personal-information_country-label')}
              name="country"
              rules={[{ whitespace: true }]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option?.label.toLowerCase().includes(input.toLowerCase()) ??
                  false
                }
                options={countriesOptions}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8} xl={8} xxl={10}>
            <Form.Item
              label={t('settings_personal-information_postal-code-label')}
              name="postalCode"
              rules={[{ whitespace: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} md={12} lg={8} xl={8} xxl={10}>
            <Form.Item
              label={t('settings_personal-information_city-label')}
              name="city"
              rules={[{ whitespace: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8} xl={8} xxl={10}>
            <Form.Item
              label={t('settings_personal-information_state-label')}
              name="state"
              rules={[{ whitespace: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Title className={styles.subtitle} level={4}>
          {t('profile_menu_notifications')}
        </Title>
        <Row gutter={[{ xs: 8 }, 20]}>
          <Col xs={4} md={2}>
            <Form.Item name="byMail" label="none" valuePropName="checked">
              <Switch
                checkedChildren={<CheckOutlined rev={undefined} />}
                checked={true}
                defaultChecked={initialValues?.byMail}
              />
            </Form.Item>
          </Col>
          <Col
            xs={20}
            md={20}
            lg={8}
            xl={8}
            xxl={22}
            className={styles.centeredCol}
          >
            <Form.Item label="none">
              <div className={styles.tips}>
                {t('fan_profile_get_notifications_by_email')}
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[{ xs: 8 }, 20]}>
          <Col xs={4} md={2}>
            <Form.Item name="bySms" label="none" valuePropName="checked">
              <Switch
                checkedChildren={<CheckOutlined rev={undefined} />}
                checked={true}
                defaultChecked={initialValues?.bySms}
              />
            </Form.Item>
          </Col>
          <Col
            xs={20}
            md={20}
            lg={8}
            xl={8}
            xxl={22}
            className={styles.centeredCol}
          >
            <Form.Item label="none">
              <div className={styles.tips}>
                {t('fan_profile_get_notifications_by_text_message')}
              </div>
            </Form.Item>
          </Col>
        </Row>
        {!optInPlatformHidden && (
          <Row gutter={[{ xs: 8 }, 20]}>
            <Col xs={4} md={2}>
              <Form.Item
                name="allowDirectMarketingFromSeaters"
                label="none"
                valuePropName="checked"
              >
                <Switch
                  checkedChildren={<CheckOutlined rev={undefined} />}
                  checked={true}
                  defaultChecked={
                    initialValues?.allowDirectMarketingFromSeaters
                  }
                />
              </Form.Item>
            </Col>
            <Col
              xs={20}
              md={20}
              lg={8}
              xl={8}
              xxl={22}
              className={styles.centeredCol}
            >
              <Form.Item label="none">
                <div className={styles.tips}>
                  {t('fan_profile_get_notifications_from_company', {
                    company: fanGroupData?.translatedName,
                  })}
                </div>
              </Form.Item>
            </Col>
          </Row>
        )}
        {!optInPartnersHidden && (
          <Row gutter={[{ xs: 8 }, 20]}>
            <Col xs={4} md={2}>
              <Form.Item
                name="allowDirectMarketingFromPartners"
                label="none"
                valuePropName="checked"
              >
                <Switch
                  checkedChildren={<CheckOutlined rev={undefined} />}
                  checked={true}
                  defaultChecked={
                    initialValues?.allowDirectMarketingFromPartners
                  }
                />
              </Form.Item>
            </Col>

            <Col
              xs={20}
              md={20}
              lg={8}
              xl={8}
              xxl={20}
              className={styles.centeredCol}
            >
              <Form.Item label="none">
                <div className={styles.tips}>
                  {t('fan_profile_get_notifications_from_company_partners', {
                    company: fanGroupData?.translatedName,
                  })}
                </div>
              </Form.Item>
            </Col>
          </Row>
        )}
        {slug ? (
          <FanProfileFooter
            formName="profileForm"
            onCancel={() => form.resetFields()}
            isDisabled={disabledSave}
          />
        ) : (
          <Footer
            onCancel={() => form.resetFields()}
            isDisabled={disabledSave}
          />
        )}
      </Form>
      <PasswordForm />
      <MobilePhoneVerificationModal
        isModalShown={isModalShown}
        setIsModalShown={setIsModalShown}
        countryCode={countryCode}
        phoneNumber={phoneNumber}
      />
    </Spin>
  );
}
