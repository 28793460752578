import { FormTranslationType } from '@seaters-app/constants';
import { useTranslation } from 'react-i18next';

export const useTranslatedProperty = (links?: FormTranslationType) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const lang = language === 'nl_NL' || language === 'nl_BE' ? 'nl' : language;
  if (!links) return null;
  return links[lang] ? links[lang] : null;
};
