import React, { useState, useRef } from 'react';
import { Upload, Modal, notification } from 'antd';
import Cropper, { ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { useTranslation } from 'react-i18next';
import {
  queryClient,
  requestOneTimeUpload,
  uploadFile,
  useUpdateWaitingListVoucherImage,
} from '@seaters-app/data-access';
import { waitingListsKeys } from '@seaters-app/constants';
import { useParams } from 'react-router-dom';
import { UploadButton } from '@seaters-app/ui';

const VoucherUploader = ({
  setImageUploading,
}: {
  setImageUploading: (v: boolean) => void;
}) => {
  const { wishListId = '' } = useParams<{ wishListId: string }>();
  const [image, setImage] = useState<string | undefined>(undefined);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const cropperRef = useRef<ReactCropperElement>(null);
  const [cropper, setCropper] = useState<Cropper>();
  const { t } = useTranslation();
  const { mutate: updateVoucherImage } = useUpdateWaitingListVoucherImage();

  const handleUpload = (file: File) => {
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as string);
      setIsModalVisible(true);
    };
    reader.readAsDataURL(file);
    return false;
  };

  const handleCrop = async () => {
    if (cropper) {
      const canvas = cropper?.getCroppedCanvas({
        width: 2480,
        height: 1170,
      });

      const blob = await new Promise<Blob | null>((resolve) =>
        canvas.toBlob(resolve, 'image/jpeg')
      );
      if (blob) {
        handleUpdateVoucherImage(blob);
      }
    }
  };

  const handleUpdateVoucherImage = async (croppedImage: Blob | null) => {
    setIsModalVisible(false);
    setImageUploading(true);
    if (croppedImage) {
      const formData = new FormData();
      formData.append('image', croppedImage, 'cropped-image.png');
      await requestOneTimeUpload({
        fileName: 'file.jpeg',
      }, false)
        .then(async (res) => {
          if (res.fileId) {
            const formFile = new FormData();

            formFile.append('file', croppedImage);
            await uploadFile(formFile, res.path.slice(20, 52), false).then(() => {
              updateVoucherImage(
                {
                  waitingListId: wishListId || '',
                  body: {
                    fileId: res.fileId,
                  },
                },
                {
                  onSuccess: () => {
                    notification.success({
                      message: t('notification_success_image_upload'),
                    });
                    queryClient.invalidateQueries(
                      waitingListsKeys.detail(wishListId)
                    );
                  },
                  onError: ({ response }) => {
                    response?.data?.errors?.errorsTypes?.technical_error?.forEach(
                      ({ error }) => {
                        notification.error({
                          message: t('notification_error_image_upload'),
                          description: t(error.errorCode),
                        });
                      }
                    );
                    response?.data?.errors?.errorsTypes?.validation_errors?.forEach(
                      ({ error }) => {
                        notification.error({
                          message: t('notification_error_image_upload'),
                          description: t(error.errorCode),
                        });
                      }
                    );
                  },
                }
              );
            });
          }
        })
        .finally(() => {
          setImageUploading(false);
        });
    }
  };

  return (
    <>
      <Upload
        beforeUpload={handleUpload}
        showUploadList={false}
        accept="image/*"
      >
        <UploadButton
          style={{
            width: 400,
            height: 200,
            borderRadius: 12,
          }}
          className={'eventImage'}
          size={'2480 x 1170'}
        />
      </Upload>
      <Modal
        open={isModalVisible}
        width={800}
        style={{ padding: 20 }}
        onOk={handleCrop}
        onCancel={() => setIsModalVisible(false)}
        okText={t('uploadimg_confirm-crop-btn')}
        cancelText={t('general_cancel')}
        maskClosable={false}
      >
        <Cropper
          src={image}
          style={{ height: 'auto', width: '100%' }}
          aspectRatio={2480 / 1170}
          guides={false}
          onInitialized={(instance) => {
            setCropper(instance);
          }}
          ref={cropperRef}
        />
      </Modal>
    </>
  );
};

export default VoucherUploader;
