import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import {
  DateFilter,
  FanGroupEntity,
  FilterName,
  Language,
  languagesOptions,
} from '@seaters-app/constants';
import { useUpdateFanGroupProperties } from '@seaters-app/data-access';
import { Button, Editor } from '@seaters-app/ui';
import { Footer, StyledText } from '@seaters-app/ui-shared';
import {
  Card,
  DatePicker,
  Form,
  Input,
  Segmented,
  Select,
  Space,
  Typography,
  notification,
} from 'antd';
import React, { useState } from 'react';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { FormType } from './types';
import NestedFieldArray from './NestedFieldArray';
import {
  defaultOptions,
  filterTypeToName,
  multipleValuesFilters,
} from './data';

const { Text } = Typography;

interface FilterConfigProps {
  fanGroupId: string;
  properties: FanGroupEntity['properties'];
}

const FilterConfig: React.FC<FilterConfigProps> = ({
  fanGroupId,
  properties,
}) => {
  const [currentLanguage, setCurrentLanguage] = useState<Language>(Language.EN);

  const { t } = useTranslation();

  const [editorShown, setEditorShown] = useState(false);

  const { mutate: updateProperties } = useUpdateFanGroupProperties(fanGroupId);

  const { control, handleSubmit } = useForm<FormType>({
    defaultValues: {
      filters: properties.filters || [], // Update the default value to undefined
    },
  });

  const { fields, append, remove, move } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'filters', // unique name for your Field Array
  });

  const watchedFilters = useWatch({
    control,
    name: 'filters',
  });

  const prepareValues = (filters: FormType['filters']) => {
    return filters.map((filter) => {
      let defaultFilter = { ...filter, type: filterTypeToName[filter.name] };

      if (filter.name === 'date') {
        return {
          ...defaultFilter,
          type:
            filter.type === 'date-picker'
              ? 'date-picker'
              : filterTypeToName[filter.name],
          values: filter.values.map((value) =>
            dayjs(value).format('YYYY-MM-DD')
          ),
        };
      } else {
        return defaultFilter;
      }
    });
  };
  const onSubmit = async (values: FormType) => {
    const preparedValues = prepareValues(values.filters);

    const valuesToSubmit = {
      properties: {
        ...properties,
        filters: preparedValues, // Set filters to undefined if preparedValues is null
      },
    };

    updateProperties(valuesToSubmit, {
      onSuccess: () => {
        notification.success({
          message: t('fanGroup:filterConfig.success'),
        });
      },
    });
  };

  // private String searchableText;
  // private String sessionType;
  // private String ticketCategory;
  // private String date;
  // private Boolean hasAvailableTicketsOnly;
  const filterNameOptions: { label: string; value: FilterName }[] = [
    {
      label: t('filters_label_searchable_text'), // 'Searchable Text',
      value: 'searchableText',
    },
    {
      label: t('filters_label_session_type'), // 'Session Type',
      value: 'sessionType',
    },
    {
      label: t('filters_label_ticket_category'), //'Ticket Category',
      value: 'ticketCategory',
    },
    {
      label: t('filters_label_date'), // 'Date',
      value: 'date',
    },
    {
      label: t('filters_label_has-available-ticket-only'), // 'Has Available Tickets Only',
      value: 'hasAvailableTicketsOnly',
    },
  ];

  const selectedFilterNames = watchedFilters.map((field) => field.name);

  const dateFilterComponentsOptions: {
    label: string;
    value: DateFilter['type'];
  }[] = [
    {
      label: t('filters_date_picker'), // 'Day picker',
      value: 'date-picker',
    },
    {
      label: t('filters_date_label-line'), // 'Date line',
      value: 'date',
    },
    {
      label: t('filters_date_label'), // 'Date line with labels',
      value: 'date-line',
    },
  ];

  const filterToAppendNext = filterNameOptions.find(
    (option) => !selectedFilterNames.includes(option.value)
  )?.value;

  const defaultValueToAppendNext = filterToAppendNext
    ? defaultOptions[filterToAppendNext]
    : null;

  return (
    <div>
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        name="filter_config"
        style={{ maxWidth: 600 }}
        autoComplete="off"
        onFinish={handleSubmit(onSubmit)}
      >
        {/* {(fields, { add, remove, move }) => ( */}
        <div style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}>
          {fields.map((field, fieldIndex) => {
            return (
              <Card
                size="small"
                title={`${'general_filter'} ${fieldIndex + 1}`}
                key={field.id}
                extra={
                  <Space>
                    <Button
                      size="small"
                      type="text"
                      onClick={() => {
                        move(fieldIndex, fieldIndex - 1);
                      }}
                      disabled={fieldIndex === 0}
                      icon={<ArrowUpOutlined rev={undefined} />}
                    />
                    <Button
                      size="small"
                      type="text"
                      disabled={fieldIndex === fields.length - 1}
                      onClick={() => {
                        move(fieldIndex, fieldIndex + 1);
                      }}
                      icon={<ArrowDownOutlined rev={undefined} />}
                    />

                    <Button
                      type="text"
                      size="small"
                      onClick={() => {
                        remove(fieldIndex);
                      }}
                      icon={<CloseOutlined rev={undefined} />}
                    />
                  </Space>
                }
              >
                <Form.Item
                  label={t('general_label')}
                  name={`filters.${fieldIndex}.label.${currentLanguage}`}
                >
                  <Controller
                    control={control}
                    name={`filters.${fieldIndex}.label.${currentLanguage}`}
                    render={({ field }) => {
                      return <Input {...field} />;
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label={t('general_name')}
                  name={`filters.${fieldIndex}.name`}
                >
                  <Controller
                    control={control}
                    name={`filters.${fieldIndex}.name`}
                    render={({ field }) => {
                      return (
                        <Select {...field}>
                          {filterNameOptions.map((option) => (
                            <Select.Option
                              value={option.value}
                              disabled={selectedFilterNames.includes(
                                option.value
                              )}
                            >
                              {option.label}
                            </Select.Option>
                          ))}
                        </Select>
                      );
                    }}
                  />
                </Form.Item>

                {watchedFilters[fieldIndex]?.name === 'date' ? (
                  <>
                    <Form.Item
                      label={t('filters_component_type')}
                      name={`filters.${fieldIndex}.type`}
                    >
                      <Controller
                        control={control}
                        name={`filters.${fieldIndex}.type`}
                        render={({ field }) => {
                          return (
                            <Select
                              {...field}
                              options={dateFilterComponentsOptions}
                            />
                          );
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label={t('general_values')}
                      name={`filters.${fieldIndex}.values`}
                    >
                      <Controller
                        control={control}
                        name={`filters.${fieldIndex}.values`}
                        render={({ field }) => {
                          const fieldValue = field.value || [];
                          return (
                            <DatePicker
                              multiple
                              {...field}
                              value={fieldValue.map((value) => dayjs(value))}
                            />
                          );
                        }}
                      />
                    </Form.Item>
                  </>
                ) : null}

                <Form.Item
                  label={t('description_label')}
                  name={`filters.${fieldIndex}.description.${currentLanguage}`}
                >
                  <Controller
                    control={control}
                    name={`filters.${fieldIndex}.description.${currentLanguage}`}
                    render={({ field: { onChange, ...field } }) => {
                      return !editorShown ? (
                        <StyledText
                          text={field.value ?? ''}
                          onClick={() => setEditorShown(true)}
                        />
                      ) : (
                        <Editor {...field} onEditorChange={onChange} />
                      );
                    }}
                  />
                </Form.Item>
                {multipleValuesFilters.includes(
                  watchedFilters[fieldIndex]?.name
                ) ? (
                  <NestedFieldArray
                    control={control}
                    nestIndex={fieldIndex}
                    currentLanguage={currentLanguage}
                  />
                ) : null}
              </Card>
            );
          })}

          <Button
            type="dashed"
            disabled={!defaultValueToAppendNext}
            onClick={() => {
              if (defaultValueToAppendNext) append(defaultValueToAppendNext);
            }}
            block
          >
            + {t('filter_config_add_filter')}
          </Button>
        </div>
        <Footer>
          <Segmented
            size="large"
            options={languagesOptions}
            value={currentLanguage}
            onChange={setCurrentLanguage}
          />
        </Footer>
      </Form>
    </div>
  );
};

export default FilterConfig;
