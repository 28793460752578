import {
  FGOWishListInvitation,
  waitingListInvitationsKeys,
  ListEntityV2,
  FGOWishListInvitationsQuery,
  AxiosAPIError,
  FGOInvitationRejectBody,
} from '@seaters-app/constants';
import {
  UseMutationResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  approveFGOInvitations,
  approveSelectedInvitations,
  fetchFGOInvitations,
  rejectFGOInvitations,
} from '../api/invitations';

export const useFetchFGOWishListInvitations = (
  wishListId: string,
  params: FGOWishListInvitationsQuery
) =>
  useQuery<ListEntityV2<FGOWishListInvitation>, Error>(
    waitingListInvitationsKeys.list<FGOWishListInvitationsQuery>(
      wishListId,
      params
    ),
    () => fetchFGOInvitations(wishListId, params)
  );

// rejectFGOInvitations
type UseFGORejectInvitationsType = {
  invitationId: string;
  body?: FGOInvitationRejectBody;
};

export const useFGORejectInvitations = (
  wishListId: string
): UseMutationResult<
  FGOWishListInvitation,
  AxiosAPIError<UseFGORejectInvitationsType>,
  UseFGORejectInvitationsType,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ invitationId, body }: UseFGORejectInvitationsType) =>
      rejectFGOInvitations(invitationId, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(waitingListInvitationsKeys.mutation());
        queryClient.invalidateQueries(
          waitingListInvitationsKeys.allPerWaitingList(wishListId)
        );
      },
    }
  );
};

// approveFGOInvitations
type UseFGOApproveInvitationsType = {
  invitationId: string;
};

export const useFGOApproveInvitations = (
  wishListId: string
): UseMutationResult<
  FGOWishListInvitation,
  AxiosAPIError<UseFGOApproveInvitationsType>,
  UseFGOApproveInvitationsType,
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ invitationId }: UseFGOApproveInvitationsType) =>
      approveFGOInvitations(invitationId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(waitingListInvitationsKeys.mutation());
        queryClient.invalidateQueries(
          waitingListInvitationsKeys.allPerWaitingList(wishListId)
        );
      },
    }
  );
};

export const useFGOApproveSelectedInvitations = (
  wishListId: string
): UseMutationResult<
  FGOWishListInvitation[],
  AxiosAPIError<string[]>,
  string[],
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    (invitationIds: string[]) => approveSelectedInvitations(invitationIds),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(waitingListInvitationsKeys.mutation());
        queryClient.invalidateQueries(
          waitingListInvitationsKeys.allPerWaitingList(wishListId)
        );
      },
    }
  );
};

