import { Button, Form, Grid, Input, Space, Typography } from 'antd';
import {
  getSlugFromUrl,
  queryClient,
  useFetchCountries,
  useUserProfile,
} from '@seaters-app/data-access';
import { Controller, useForm } from 'react-hook-form';
import {
  Address,
  AttendeeRequiredFieldsType,
  AttendeesInfoFormValidator,
  FanEntity,
  FanGroupOwnerWaitingListEntity,
  FanProfileFormEntity,
  GuestEntity,
  Option,
  SeatEntity,
  attendeeFields,
  fanGroupOwnerWaitingListsKeys,
  generateConditionalAttendeesInfoFormValidatorSchema,
  languages,
} from '@seaters-app/constants';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Card from 'antd/es/card/Card';
import { useAttendeeInfoStore } from './store/useAttendeeInfoStore';
import { PurchaseSummary } from '../PurchaseSummary';
import dayjs from 'dayjs';
import { StepItemContentProps } from './types';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  AddressFields,
  AttendeeInfoFormProps,
  AttendeeInfoFormType,
  BirthDateField,
  CitizenshipField,
  CityField,
  CountryField,
  LanguageField,
  MobileField,
  StateField,
  TitleField,
  ZipCodeField,
  attendeeInfoLabel,
} from './FieldsComponents/fields-components';
import { useEffect } from 'react';

const { useBreakpoint } = Grid;

const { Text } = Typography;

function AttendeeInfo({ children }: { children: React.ReactNode }) {
  if (!children) return null;
  return <Text>{children}</Text>;
}

function AttendeeInfoCard({
  filledData,
  onEdit,
  countriesOptions,
}: {
  filledData: AttendeesInfoFormValidator;
  onEdit: () => void;
  countriesOptions?: Option[];
}) {
  const { t } = useTranslation();
  const fullName =
    (filledData?.title ?? '') +
    ' ' +
    (filledData?.firstName ?? '') +
    ' ' +
    (filledData?.lastName ?? '');

  const nameFields = ['firstName', 'lastName', 'title'];

  const infoItems = Object.keys(filledData).filter(
    (item) => !nameFields.includes(item)
  );

  const hasFullName = Object.keys(filledData).find((el) =>
    nameFields.includes(el)
  );

  return (
    <Space direction="vertical" style={{ textAlign: 'left', width: '100%' }}>
      {hasFullName ? (
        <AttendeeInfo>
          <Text strong>{t('checkout_att_field_fullname')}: </Text>
          {fullName}
        </AttendeeInfo>
      ) : null}
      {infoItems?.map((item) => {
        if (!filledData[item as keyof AttendeesInfoFormValidator]) return null;

        if (item === 'address') {
          const addressObj: Address = filledData[item];
          const addressItems = Object.keys(addressObj);

          const addressValues = Object.values(addressObj).filter(
            (el) => el !== null
          );

          if (!addressValues.length) {
            return null;
          }
          return (
            <Space>
              <Text strong>{t('checkout_att_field_address')}: </Text>
              <Space>
                {addressItems?.map((addressItem) => {
                  if (!addressObj[addressItem as keyof Address]) return null;
                  return (
                    <AttendeeInfo>
                      {addressObj[addressItem as keyof Address]};
                    </AttendeeInfo>
                  );
                })}
              </Space>
            </Space>
          );
        }

        if (item === 'birthDate') {
          return (
            <AttendeeInfo>
              <Text strong>{t(attendeeInfoLabel.birthDate)}: </Text>
              {dayjs(filledData[item]).format('DD-MM-YYYY')}
            </AttendeeInfo>
          );
        }

        if (item === 'phoneNumber') {
          return (
            <AttendeeInfo>
              <Text strong>{t(attendeeInfoLabel.phoneNumber)}: </Text>
              {filledData.phoneNumber.countryCallingCode +
                filledData.phoneNumber.localNumber}
            </AttendeeInfo>
          );
        }
        if (item === 'citizenshipCountryCode') {
          return (
            <AttendeeInfo>
              <Text strong>
                {t(attendeeInfoLabel.citizenshipCountryCode)}:{' '}
              </Text>
              {
                countriesOptions?.find(
                  (country) =>
                    country.value === filledData.citizenshipCountryCode
                )?.label
              }
            </AttendeeInfo>
          );
        }
        if (item === 'country') {
          return (
            <AttendeeInfo>
              <Text strong>{t(attendeeInfoLabel.country)}: </Text>
              {
                countriesOptions?.find(
                  (country) => country.value === filledData?.country
                )?.label
              }
            </AttendeeInfo>
          );
        }

        return (
          <AttendeeInfo>
            <Text strong>
              {t(attendeeInfoLabel[item as AttendeeRequiredFieldsType])}:{' '}
            </Text>
            <>{filledData[item as keyof AttendeesInfoFormValidator]}</>
          </AttendeeInfo>
        );
      })}

      <Button onClick={onEdit}>{t('basket_edit_label')}</Button>
    </Space>
  );
}

function AttendeeInfoForm({
  fields,
  languagesOptions,
  countriesOptions,
  onFormSubmit,
  defaultValues,
}: AttendeeInfoFormProps) {
  const AttendeesInfoFormValidatorSchema =
    generateConditionalAttendeesInfoFormValidatorSchema(fields);

  const screens = useBreakpoint();
  const formItemStyle = { width: screens.xs ? '100%' : '30%' };
  const { t } = useTranslation();
  const methods = useForm<AttendeeInfoFormType>({
    mode: 'onBlur',
    resolver: zodResolver(AttendeesInfoFormValidatorSchema),
    defaultValues: defaultValues?.birthDate
      ? { ...defaultValues, birthDate: dayjs(defaultValues.birthDate) }
      : { ...defaultValues },
  });

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = methods;
  const onSubmit = (values: AttendeeInfoFormType) => {
    const parsed = AttendeesInfoFormValidatorSchema.safeParse(values);

    if (!parsed.success) {
      console.log(parsed.error);

      return;
    }

    onFormSubmit(values);
  };

  const getFieldComponent = (fieldName: AttendeeRequiredFieldsType) => {
    switch (fieldName) {
      case 'phoneNumber':
        return (
          <MobileField
            errors={errors}
            formItemStyle={formItemStyle}
            control={control}
            defaultValues={defaultValues}
            setValue={setValue}
          />
        );
      case 'language':
        return (
          <LanguageField
            formItemStyle={formItemStyle}
            errors={errors}
            control={control}
            languagesOptions={languagesOptions}
          />
        );
      case 'address':
        return (
          <AddressFields
            formItemStyle={formItemStyle}
            control={control}
            errors={errors}
          />
        );
      case 'zipCode':
        return (
          <ZipCodeField
            formItemStyle={formItemStyle}
            errors={errors}
            control={control}
          />
        );
      case 'city':
        return (
          <CityField
            formItemStyle={formItemStyle}
            errors={errors}
            control={control}
          />
        );
      case 'country':
        return (
          <CountryField
            formItemStyle={formItemStyle}
            errors={errors}
            control={control}
            countriesOptions={countriesOptions}
          />
        );
      case 'citizenshipCountryCode':
        return (
          <CitizenshipField
            formItemStyle={formItemStyle}
            errors={errors}
            control={control}
            countriesOptions={countriesOptions}
          />
        );
      case 'state':
        return (
          <StateField
            formItemStyle={formItemStyle}
            errors={errors}
            control={control}
          />
        );
      case 'title':
        return (
          <TitleField
            formItemStyle={formItemStyle}
            errors={errors}
            control={control}
          />
        );
      case 'birthDate':
        return (
          <BirthDateField
            formItemStyle={formItemStyle}
            errors={errors}
            control={control}
          />
        );
    }
  };
  return (
    <Form
      style={{
        width: '100%',
      }}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: 16 }}>
        {fields?.map((field) => {
          const label =
            attendeeInfoLabel[field as AttendeeRequiredFieldsType] ?? '';

          const Test = getFieldComponent(field as AttendeeRequiredFieldsType); // TO DO: rename + refactor

          return (
            <>
              {Test ? (
                getFieldComponent(field as AttendeeRequiredFieldsType)
              ) : field !== 'phoneNumber.localNumber' ? (
                <Form.Item
                  style={formItemStyle}
                  name={field}
                  label={t(label)}
                  validateStatus={errors && errors?.[field] && 'error'}
                  help={<>{errors?.[field]?.message}</>}
                  required={
                    attendeeFields.find(
                      (attendeeField) => attendeeField.name === field
                    )?.required
                  }
                >
                  <Controller
                    control={control}
                    rules={{
                      required: t('general_label_required')
                        ? (t('general_label_required') as string)
                        : 'Required',
                    }}
                    name={field}
                    render={({ field: { value, ...rest } }) => (
                      <Input value={value as string} {...rest} size="middle" />
                    )}
                  />
                </Form.Item>
              ) : null}
            </>
          );
        })}
      </div>
      <Space>
        <Button htmlType="submit" type="primary">
          {t('checkout_save')}
        </Button>
      </Space>
    </Form>
  );
}

export function AttendeeInfoStep({
  prev,
  currentStep,
  waitingList,
  handleAttendeeForm,
  selectedFan,
  requestedSeats,
  closeCheckout,
  submitForm,
}: StepItemContentProps) {
  const { wishListId = '' } = useParams();
  const slug = getSlugFromUrl();
  const waitingListFGO =
    queryClient.getQueryData<FanGroupOwnerWaitingListEntity>(
      fanGroupOwnerWaitingListsKeys.detail(wishListId)
    );

  const {
    attendees,
    setAttendeeInfo,
    setAttendees,
    reset,
    currentAttendee,
    setCurrentAttendee,
  } = useAttendeeInfoStore();

  const { t } = useTranslation();
  const { data: fanProfile } = useUserProfile(true);

  // if slug not exists - it's a FGO side (cockpit)
  const numberOfAttendees = requestedSeats
    ? requestedSeats
    : waitingList?.position?.numberOfSeats
    ? Number(waitingList?.position?.numberOfSeats)
    : null;

  const languagesOptions = languages.map((language) => ({
    label: language.name.en,
    value: language.locale,
  }));

  const { data: countries } = useFetchCountries({
    itemOffset: 0,
    maxPageSize: 240,
  });

  const countriesOptions = countries?.items.map((country) => ({
    label: country.name,
    value: country.alpha2Code,
  }));

  const onChange = (value: number) => {
    setCurrentAttendee(value);
  };

  const onSubmitStep = () => {
    const attendeesValues = attendees.map((el) => {
      const { birthDate, ...rest } = el;
      let values;

      if (el.birthDate) {
        values = {
          ...rest,
          birthDate: dayjs(el.birthDate).format('YYYY-MM-DD'),
        };
      } else {
        values = { ...rest };
      }

      //  *** countryCode is required on BE
      if (!rest.countryCode && rest.country) {
        values = {
          ...values,
          address: {
            ...values.address,
            countryCode: rest.country,
            country: rest.country,
          },
        };
      }
      // ***
      return values;
    });

    if (handleAttendeeForm) {
      handleAttendeeForm(attendeesValues);
    }
  };

  const onFormSubmit = (formData: any) => {
    setAttendeeInfo(formData, currentAttendee);
    onChange(currentAttendee ? currentAttendee + 1 : 1);
  };

  type ProfileDefaultDataType =
    | GuestEntity
    | SeatEntity
    | (Partial<FanEntity> & {
        phoneNumber:
          | {
              localNumber: string;
              countryCallingCode: string;
            }
          | null
          | undefined;
      });
  const profileDefaultData: ProfileDefaultDataType = selectedFan
    ? {
        ...selectedFan,
      }
    : {
        ...fanProfile,
        ...fanProfile?.personalInfo,
        phoneNumber: fanProfile?.mobilePhoneNumber,
      };

  const fields = waitingList?.eventRequiredAttendeeInfo
    ? waitingList?.eventRequiredAttendeeInfo
    : waitingListFGO?.event?.attendeeRequiredFields;

  const cleanedDefaultValues: Partial<
    Record<AttendeeRequiredFieldsType, string>
  > = {};

  fields?.forEach((el) => {
    cleanedDefaultValues[el as keyof ProfileDefaultDataType] =
      profileDefaultData[el as keyof ProfileDefaultDataType];
  });

  useEffect(() => {
    return () => {
      reset();
      setCurrentAttendee(0);
    };
  }, []);

  return (
    <div
      style={{
        alignItems: 'flex-start',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <Text type="secondary">{t('checkout_requiredinfo_header')}</Text>
          {Array(numberOfAttendees)
            .fill(0)
            .map((el, i) => {
              let filledData = attendees[i];
              if (
                filledData?.address?.countryCode &&
                !filledData?.address?.country
              ) {
                filledData = {
                  ...filledData,
                  country: filledData.address.countryCode,
                };
              }
              const defaultValues: FanProfileFormEntity | object | null =
                filledData ? filledData : i === 0 ? cleanedDefaultValues : {};

              return (
                <Card
                  title={t('checkout_requiredinfo_subheader', {
                    attendee_number: i + 1,
                  })}
                >
                  {currentAttendee === i ? (
                    <AttendeeInfoForm
                      languagesOptions={languagesOptions}
                      countriesOptions={countriesOptions}
                      fields={fields}
                      onFormSubmit={onFormSubmit}
                      defaultValues={defaultValues}
                    />
                  ) : attendees[i] ? (
                    <AttendeeInfoCard
                      filledData={filledData}
                      onEdit={() => onChange(i)}
                      countriesOptions={countriesOptions}
                    />
                  ) : (
                    <Button
                      onClick={() => onChange(i)}
                      disabled={i !== 0 && !attendees[i - 1]}
                    >
                      {t('checkout_fillin_details')}
                    </Button>
                  )}
                </Card>
              );
            })}
        </Space>
        <Space>
          {prev && (
            <Button
              onClick={
                currentStep
                  ? prev
                  : () => {
                      closeCheckout && closeCheckout();
                      setAttendees([]);
                    }
              }
            >
              {t('group_menu_back')}
            </Button>
          )}
          {/* <Button type="primary">{t('button_text_next')}</Button> */}
          <Button
            type="primary"
            disabled={attendees.length !== numberOfAttendees}
            onClick={onSubmitStep}
          >
            {submitForm || t('guest_app_confirm')}
          </Button>
        </Space>
      </Space>

      {/* Uncomment  */}
      <PurchaseSummary />
    </div>
  );
}
