import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Form, notification, Modal, InputNumber } from 'antd';
import { waitingListsKeys } from '@seaters-app/constants';
import { queryClient, useGiveParking } from '@seaters-app/data-access';

export function DistributeParkingModal({
  open,
  onClose,
  fanId,
}: {
  open: boolean;
  onClose: () => void;
  fanId?: string | null;
}) {
  const { wishListId = '' } = useParams();

  const { t } = useTranslation();

  const { mutate: giveParking, isLoading } = useGiveParking();

  const [form] = Form.useForm();

  const handleSubmit = (values: { ticketsToDistribute: number }) => {
    if (fanId)
      giveParking({ waitingListId: wishListId, fanId: fanId, nrOfParkingTickets: values.ticketsToDistribute },
    {
        onSuccess: () => {
            notification.success({
                message: t('notification_success_distributed_only_parking'),
            });
            onClose();
            queryClient.invalidateQueries(waitingListsKeys.seats(wishListId));
        },
        onError: () => {
            notification.error({
                message: t('notification_error_distributed_only_parking'),
                description: '',
            });
            return {};
        }
    });
  };

  return (
    <Modal
      open={open}
      title={t('mwl_distri_parking_title')}
      onOk={() => form.submit()}
      onCancel={onClose}
      okButtonProps={{ loading: false }}
      cancelButtonProps={{ disabled: false }}
    >
      <Form
        disabled={false}
        initialValues={{
          voucherExpirationTimeOutInMs: 0,
        }}
        form={form}
        onFinish={handleSubmit}
      >
        <Form.Item
          label={t('distribute_more_parking_tickets')}
          name="ticketsToDistribute"
          rules={[{ required: true, message: 'Required field' }]}
        >
          <InputNumber autoFocus min={0} type="number" defaultValue={0} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
