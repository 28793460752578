import {
  CloseOutlined,
  ClockCircleOutlined,
  DownloadOutlined,
  PlusCircleOutlined,
  DownOutlined,
  CheckOutlined,
  ShareAltOutlined,
  UpOutlined,
  FileDoneOutlined,
  CarOutlined,
} from '@ant-design/icons';

import { baseColor100, Button, successColor } from '@seaters-app/ui';
import {
  Space,
  Table,
  Tooltip,
  notification,
  Modal,
  Form,
  Select,
  InputNumber,
  ConfigProvider,
  Typography,
  theme,
  Dropdown,
  message,
  Popconfirm,
  Progress,
  Grid,
} from 'antd';
import {
  AxiosAPIError,
  BillingMode,
  fanGroupOwnerWaitingListsKeys,
  InvitationMode,
  PositionTimeoutsValidator,
  SeatDistributionMode,
  SeatEntity,
  SeatStatus,
  SurveyQuery,
  waitingListsKeys,
} from '@seaters-app/constants';
import dayjs from 'dayjs';
import {
  fetchSurveyFansAnswers,
  giveParking,
  queryClient,
  useAcceptFanSeatsAsFGO,
  useDeleteFGOWishListSeat,
  useFetchFanGroupOwnerWaitingList,
  useFetchFGOWaitingListAvailableSeats,
  useFetchFGOWaitingListAvailableSecondarySeats,
  useFetchWaitingListSurveyInstances,
  useUnmarkParkingPosition,
  useUpdateParkingPosition,
  useUpdatePositionTimeOuts,
  useUpdateSeatsPosition,
} from '@seaters-app/data-access';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useWishListSeats } from './hooks/useWishListSeats';
import { useTranslation } from 'react-i18next';
import { useExportList } from './hooks/useExportList';
import { useIsMutating } from '@tanstack/react-query';
import Search from 'antd/es/input/Search';
import { SeatStatusTag } from './SeatStatusTag';
import styles from './styles.module.css';
import { SortOrder } from 'antd/es/table/interface';
import { DistributeMoreModal } from './ActionModals';
import RemoveSeatModal from './ActionModals/RemoveSeatModal';
import debounce from 'lodash/debounce';
import ExpandedRow from './ExpandedRow/ExpandedRow';
import DownloadSurveyAnswersModal from './DownloadSurveyAnswersModal';
import useDistribution from './hooks/useDistribution';
import { checkVoucherText } from './helpers';
import { CheckoutModalFGO } from '@seaters-app/ui-shared';
import RemoveMultipleSeatModal from './ActionModals/RemoveMultipleSeatModal';
import { NumberItem } from './NumberItem';
import { ItemType } from 'antd/es/menu/interface';
import { DistributeParkingModal } from './ActionModals/DistributeParkingModal';
const { useBreakpoint } = Grid;

const { Text } = Typography;

const milliseconds = 1000;
const minuteMultiplier = milliseconds * 60;
const hrsMultiplier = minuteMultiplier * 60;
const dayMultiplier = hrsMultiplier * 24;

const statusesToDisable = [
  SeatStatus.ASSIGNED,
  SeatStatus.ARCHIVED,
  SeatStatus.ACCEPTED,
  SeatStatus.REJECTED,
];

// WLid to test 73e4fc18-1862-49ec-9f7a-f50ad43d15da
export function RSVP() {
  const [messageApi, contextHolder] = message.useMessage();
  const { xs, sm, md, lg, xxl } = useBreakpoint();

  const { token } = theme.useToken();
  const { wishListId = '' } = useParams();

  const { t } = useTranslation();

  const [isSeatsAssignation, setIsSeatsAssignation] = useState<boolean>(false);
  const [isOnlyParkingDistribution, setIsOnlyParkingDistribution] = useState<boolean>(false);

  const [removeMultipleSeatsModalOpen, setRemoveMultipleSeatsModalOpen] =
    useState(false);

  const openRemoveMultipleSeatsModal = () => {
    setRemoveMultipleSeatsModalOpen(true);
  };
  const [distributeWithParking, setDistributeWithParking] =
    useState<boolean>(false);

  const [fanIdToDistributeMore, setFanIdToDistributeMore] = useState<
    string | null
  >();

  const [fanIdToDistributeParking, setFanIdToDistributeParking] = useState<string | null>();

  const params: Omit<SurveyQuery, 'fangroup_id'> = {
    size: 9999,
    page: 0,
    waitinglist_id: wishListId,
  };

  const [selectedFan, setSelectedFan] = useState<SeatEntity | null>(null);

  const { data: wishList } = useFetchFanGroupOwnerWaitingList(wishListId);

  const {
    data: availableSeatsData,
    refetch: refetchAvailableSeats,
    isRefetching: isAvailableSeatsRefetching,
  } = useFetchFGOWaitingListAvailableSeats(wishListId);

  const { data: availableSecondarySeatsData } =
    useFetchFGOWaitingListAvailableSecondarySeats(wishListId);

  const { data: surveys } = useFetchWaitingListSurveyInstances(params);

  const atCheckoutSurvey = surveys?.content.find(
    (survey) => survey?.extensionPoint === 'AT_CHECKOUT'
  );

  const [seatToDelete, setSeatToDelete] = useState<SeatEntity | null>();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [rowsToDistribute, setRowsToDistribute] = useState<SeatEntity[]>([]);

  const [selectedRowItems, setSelectedRowItems] = useState<SeatEntity[]>([]);

  const [selectedAssetsRowId, setSelectedAssetsRowId] = useState<string | null>(
    null
  );

  const [selectedParkingRowId, setSelectedParkingRowId] = useState<
    string | null
  >(null);

  const [positionToChangeTimeouts, setPositionToChangeTimeouts] = useState<
    string | React.Key[]
  >([]);

  const [multiplier, setMultiplier] = useState<number>(minuteMultiplier);
  const [surveyDownloadOpen, setSurveyDownloadOpen] = useState<boolean>(false);

  const [checkoutShown, setCheckoutShown] = useState(false);

  const openSurveyDownload = () => {
    setSurveyDownloadOpen(true);
  };

  const closeSurveyDownload = () => {
    setSurveyDownloadOpen(false);
  };

  const {
    seats,
    handleTableChange,
    pagination,
    isLoading,
    setPaginationParams,
    queryParams,
    sort,
    handleSort,
  } = useWishListSeats();

  const [expandedRows, setExpandedRows] = useState<string[]>([]);

  const expandAllRows = () => {
    const allFans = seats?.content.map((seat) => seat?.fanId);
    setExpandedRows(allFans ?? []);
  };

  const hideAllRows = () => {
    setExpandedRows([]);
  };

  const { mutate: removeSeat, isLoading: isSeatRemoving } =
    useDeleteFGOWishListSeat(wishListId || '');

  const { handleDistribution } = useDistribution(wishListId);

  const { mutateAsync: updatePositionTimeouts } = useUpdatePositionTimeOuts(
    wishListId || ''
  );

  const { mutateAsync: unmarkParkingPosition } =
    useUnmarkParkingPosition(wishListId);

  const { mutate: updateSeatsPosition } = useUpdateSeatsPosition(wishListId);
  const { mutateAsync: updateParkingPosition } =
    useUpdateParkingPosition(wishListId);

  const { mutate: acceptFanSeatsAsFGO } = useAcceptFanSeatsAsFGO(wishListId);

  const { isExporting, handleExport } = useExportList();

  const [form] = Form.useForm();

  const [currentRow, setCurrentRow] = useState<SeatEntity | null>(null);

  const [assignationProgress, setAssignationProgress] = useState<number>(0);

  const progressPercent = currentRow
    ? Number(
        ((assignationProgress / currentRow.requestedSeats) * 100 || 0).toFixed(
          2
        )
      )
    : Number(
        ((assignationProgress / rowsToDistribute.length) * 100 || 0).toFixed(2)
      );

  const seatsToDistribute: number = currentRow
    ? currentRow.requestedSeats
    : rowsToDistribute.reduce((acc, row) => {
        return acc + row?.requestedSeats;
      }, 0);

  const handleCloseDistributionModal = () => {
    setAssignationProgress(0);
    setIsSeatsAssignation(false);
    resetSelection();
    setSelectedRowItems([]);
    setCurrentRow(null);
  };
  const handleCloseParkingDistributionModal = () => {
    setAssignationProgress(0);
    setIsOnlyParkingDistribution(false);
    resetSelection();
    setSelectedRowItems([]);
    setCurrentRow(null);
  }
  const hidePositionToChangeTimeoutsModal = () => {
    setPositionToChangeTimeouts([]);
  };

  const mutating = !!useIsMutating();

  const resetSelection = () => {
    setSelectedRowKeys([]);
    setRowsToDistribute([]);
  };

  const checkAbilityToDistribute = (seat: SeatEntity) => {
    const isAlreadyInTheList = rowsToDistribute.find(
      (item) => item.fanId === seat.fanId
    );
    return seat.status === SeatStatus.RSVP_ACCEPTED && !isAlreadyInTheList;
  };

  const getDistributedSeatsNames = (rows: SeatEntity[]) => {
    const names = rows
      .map((item) => `${item.firstName} ${item.lastName}`)
      .join(', ');

    return names;
  };

  const acceptFanRSVP = async (fanId: string) => {
    acceptFanSeatsAsFGO(fanId, {
      onSuccess: async () => {
        notification.success({
          message: t('notification_success_tickets_accepted'),
        });
      },
      onError: (err) => {
        notification.error({
          message: t('notification_error_tickets_accepted'),
        });
      },
    });
  };

  const onSelect = (record: SeatEntity, selected: boolean) => {
    const newRowItems = selected
      ? selectedRowItems.concat(record)
      : selectedRowItems.filter((el) => el.fanId !== record.fanId);
    const newRowsKeys = newRowItems.map((el) => el.fanId);
    if (record.status === SeatStatus.RSVP_ACCEPTED) {
      const newRowsToDistribute = selected
        ? [...rowsToDistribute, record]
        : rowsToDistribute.filter((el) => el.fanId !== record.fanId);
      setRowsToDistribute(newRowsToDistribute);
    }
    setSelectedRowKeys(newRowsKeys);
    setSelectedRowItems(newRowItems);
  };

  const onSelectAll = (selected: boolean, selectedRows: SeatEntity[]) => {
    let distributeArray: SeatEntity[] = selected ? rowsToDistribute : [];
    setSelectedRowItems(selectedRows);
    const selectedKeys = selectedRows.map((row: SeatEntity) => row.fanId);
    setSelectedRowKeys(selected ? selectedKeys : []);
    if (selected) {
      selectedRows.forEach((row) => {
        if (checkAbilityToDistribute(row)) {
          distributeArray.push(row);
        }
      });
    }
    setRowsToDistribute(distributeArray);
  };

  const rowSelection = {
    selectedRowKeys,
    onSelect,
    onSelectAll,
  };

  const SearchInput = () => (
    <Search
      defaultValue={queryParams.search}
      id="searchInput"
      placeholder={t('cwl_event_search-btn')}
      allowClear
      onSearch={(search) =>
        setPaginationParams({
          ...queryParams,
          search,
          current: 0,
        })
      }
      style={{ maxWidth: 300 }}
      size="middle"
    />
  );

  const getSortOrder = (columnName: string): SortOrder | undefined =>
    sort?.name === columnName ? sort.order : undefined;

  const handleSetNewTimeoutSelected = () => {
    const positionsToSetTimeouts = selectedRowItems.reduce(
      (acc: string[], curr: SeatEntity) => {
        if (
          curr.status === SeatStatus.ASSIGNED ||
          curr.status === SeatStatus.ASSIGNED_WITHOUT_SEATS
        ) {
          acc.push(curr.fanId);
        }
        return acc;
      },
      []
    );
    setPositionToChangeTimeouts(positionsToSetTimeouts);
  };

  const rejectSelected = () => {
    selectedRowKeys.forEach((position) => {
      removeSeat(
        { fanId: position },
        {
          onSuccess: () => {
            notification.success({
              message: t('notification_success_removed'),
            });
            resetSelection();
            queryClient.invalidateQueries(
              waitingListsKeys.seats(wishListId || '')
            );
            setRemoveMultipleSeatsModalOpen(false);
          },
        }
      );
    });
  };

  const handleSetTimeouts = async (value: PositionTimeoutsValidator) => {
    for (const seat of positionToChangeTimeouts) {
      await updatePositionTimeouts({
        fanId: seat,
        body: {
          paidSeatExpirationTimeOutInMs: 0,
          positionExpirationTimeOutMs: 0,
          voucherExpirationTimeOutInMs:
            value.voucherExpirationTimeOutInMs * multiplier,
        },
      });
    }

    hidePositionToChangeTimeoutsModal();
    resetSelection();
    notification.success({
      message: t('notification_success_new_exp_time_setted'),
    });
  };

  const distribute = async (seat: SeatEntity) => {
    if (!checkVoucherText(wishList)) return;

    /*---------------IN CASE USER DISTRIBUTES A POSITION WITH 0 TICKETS-----------------*/
    if (!seat.requestedSeats || seat.requestedSeats === 0) {
      notification.error({
        message: t('position_distribution_no_tickets-message'),
        description: t('position_distribution_no_tickets-description'),
      });
      return;
    }

    messageApi.loading('Checking available seats...');

    await refetchAvailableSeats().then(async () => {
      messageApi.destroy();
      if (availableSeatsData && !availableSeatsData.numberOfAvailableSeats) {
        notification.error({
          message: t('mwl_wl_distribute-toofewseats-title'),
          description: t('mwl_wl_distribute-toofewseats-info'),
        });

        return;
      } else {
        await handleDistribution(seat, distributeWithParking);
      }
    });
  };

  const acceptFanSeats = async (fanId: string) => {
    let fanSurveyAnswers = null;
    if (atCheckoutSurvey?.id) {
      fanSurveyAnswers = await fetchSurveyFansAnswers(
        wishListId,
        atCheckoutSurvey?.id,
        {
          page: 0,
          size: 10,
          user_id: fanId,
        }
      );
    }

    if (
      wishList?.billingMode === BillingMode.FAIR_PRICE ||
      (atCheckoutSurvey && fanSurveyAnswers?.content.length === 0) ||
      !!wishList?.event?.attendeeRequiredFields
    ) {
      setCheckoutShown(true);
    } else {
      acceptFanRSVP(fanId);
    }
  };
  const handleQueriesInvalidation = () => {
    queryClient.invalidateQueries(waitingListsKeys.seats(wishListId));
    queryClient.invalidateQueries(
      fanGroupOwnerWaitingListsKeys.availableSeats(wishListId)
    );
    queryClient.invalidateQueries(
      fanGroupOwnerWaitingListsKeys.availableSecondarySeats(wishListId)
    );
  };

  const handleMenuOnClick = async ({
    key,
    position,
  }: {
    key: string;
    position: SeatEntity;
  }) => {
    switch (key) {
      case 'REMOVE':
        setSeatToDelete(position);
        break;
      case 'SET_EXP_TIME':
        setPositionToChangeTimeouts([position.fanId]);
        break;
      case 'DISTRIBUTE_MORE':
        setFanIdToDistributeMore(position.fanId);
        break;
      case 'DISTRIBUTE':
        setCurrentRow(position);
        setIsSeatsAssignation(true);
        break;
      case 'DISTRIBUTE_WITH_PARKING':
        setCurrentRow(position);
        setDistributeWithParking(true);
        setIsSeatsAssignation(true);
        break;
      case 'DISTRIBUTE_ONLY_PARKING':
        setFanIdToDistributeParking(position.fanId);
        break;
      case 'SURVEY_ANSWERS':
        handleRowExpand(position);
        break;
      case 'ACCEPT_RSVP':
        setSelectedFan(position);
        acceptFanSeats(position.fanId);
        break;
      default:
        break;
    }
  };

  const items = (
    position: SeatEntity
  ): Array<ItemType & { hidden?: boolean }> => {
    return [
      {
        label: t('distribute-general'),
        key: 'DISTRIBUTE',
        icon: <ShareAltOutlined rev={undefined} />,
        disabled: position.status !== SeatStatus.RSVP_ACCEPTED || (availableSeatsData?.numberOfAvailableSeats ?? 0) < position.requestedSeats,
      },
      {
        label: t('wl_positions_distribution_mode_with_parking'),
        key: 'DISTRIBUTE_WITH_PARKING',
        icon: <CarOutlined rev={undefined} />,
        disabled: position.status !== SeatStatus.RSVP_ACCEPTED || (availableSecondarySeatsData?.numberOfAvailableSeats ?? 0) < position.numberOfParkingTickets || position.numberOfParkingTickets === 0 || (availableSeatsData?.numberOfAvailableSeats ?? 0) < position.requestedSeats,
      },
      {
        // button only available when at least 1 ticket distributed & parking tickets within available amount
        label: t('wl_positions_distribution_mode_only_parking'),
        key: 'DISTRIBUTE_ONLY_PARKING',
        icon: <CarOutlined rev={undefined} />,
        disabled: (availableSecondarySeatsData?.numberOfAvailableSeats ?? 0) === 0 || wishList?.seatDistributionMode !== SeatDistributionMode.TICKET || position.status !== SeatStatus.ACCEPTED

      },
      {
        label: t('distribute_more'),
        key: 'DISTRIBUTE_MORE',
        icon: <PlusCircleOutlined rev={undefined} />,
        disabled: position.status !== SeatStatus.ACCEPTED || availableSeatsData?.numberOfAvailableSeats === 0,
      },
      {
        label: t('set_new_exp_time_label'),
        key: 'SET_EXP_TIME',
        icon: <ClockCircleOutlined rev={undefined} />,
        disabled: position.status === SeatStatus.ACCEPTED,
        hidden: !!(
          wishList?.invitationParameters &&
          wishList?.invitationParameters.invitationMode ===
            InvitationMode.ENABLED
        ),
      },
      {
        label: t('survey_instance_answers_label'),
        key: 'SURVEY_ANSWERS',
        icon: <CheckOutlined rev={undefined} />,
        disabled: !surveys?.content.length,
      },
      {
        label: t('accept_rsvp_label'),
        key: 'ACCEPT_RSVP',
        icon: <FileDoneOutlined rev={undefined} />,
        // TO DO : add check if FG payment method is virtual
        disabled: position.status === SeatStatus.ACCEPTED,
      },
      {
        label: t('mwl_wl_fan-remove-btn'),
        key: 'REMOVE',
        icon: <CloseOutlined rev={undefined} />,
        danger: true,
      },
    ];
  };

  const handleRowExpand = (seat: SeatEntity) => {
    const isExpanded = expandedRows.includes(seat.fanId);
    const newExpandedRows = isExpanded
      ? expandedRows.filter((rowKey) => rowKey !== seat.fanId)
      : expandedRows.concat(seat.fanId);
    setExpandedRows(newExpandedRows);
  };

  const handlePositionUpdate = (
    positionId: string,
    nrOfSeats: number | null
  ) => {
    if (nrOfSeats !== null) {
      updateSeatsPosition(
        { positionId, nrOfSeats },
        {
          onSuccess: () => {
            notification.success({
              message: t('notification_seats_updated'),
            });
          },

          onError: (error) => {
            notification.error({
              message: t(error.response?.data.message || 'notification_error'),
            });
          },
        }
      );
    }
  };

  const handleParkingTicketsUpdate = (
    positionId: string,
    nbrOfParkingTickets: number | null
  ) => {
    if (nbrOfParkingTickets !== null) {
      const onSuccess = () => {
        notification.success({
          message: t('notification_parking_seats_updated'),
        });
        queryClient.invalidateQueries(
          waitingListsKeys.seatsList(wishListId, {
            size: queryParams.pageSize,
            page: queryParams.current,
          })
        );
      };

      if (nbrOfParkingTickets === 0) {
        unmarkParkingPosition(
          { positionId },
          {
            onSuccess,
          }
        );
      } else {
        updateParkingPosition(
          { positionId, nbrOfParkingTickets },
          {
            onSuccess,
          }
        );
      }
    }
  };

  const getSeatDisabled = (status: SeatStatus) => {
    return statusesToDisable.includes(status);
  };

  const handleDistributeSelectedSeats = async () => {
    if (!checkVoucherText(wishList)) return;

    const seatsToDistribute = selectedRowItems.filter(
      (seat) => seat.status === SeatStatus.RSVP_ACCEPTED
    );

    setRowsToDistribute(seatsToDistribute);

    for (const seat of seatsToDistribute) {
      await distribute(seat);
      setAssignationProgress((assignationProgress) => assignationProgress + 1);
    }
    setSelectedRowKeys([]);
    await handleQueriesInvalidation();
  };

  const handleConfirmDistributionModal = async () => {
    if (!currentRow) {
      handleDistributeSelectedSeats();
    } else {
      if (currentRow) {
        await distribute(currentRow);
        setAssignationProgress(
          (assignationProgress) => assignationProgress + 1
        );
        handleQueriesInvalidation();
      }
    }

    handleQueriesInvalidation();
  };

  const handleConfirmParkingDistributionModal = async () => {
    if (!currentRow) return;
    distributeOnlyParking(currentRow);
  }

  const parkingSeatsToDistribute = rowsToDistribute.reduce(
    (acc, curr) => acc + curr.numberOfParkingTickets,
    0
  );
  const seatsToDistributeTickets = rowsToDistribute.reduce(
    (acc, curr) => acc + curr.requestedSeats,
    0
  );

  return (
    <div style={{ width: '100%' }}>
      {contextHolder}

      <div
        style={{
          backgroundColor: token.colorPrimaryBg,
          borderBottom: `1px solid ${token.colorBorder}`,
        }}
      >
        {selectedRowKeys.length ? (
          <ConfigProvider
            theme={{
              token: {
                colorText: baseColor100,
              },
              components: {
                Input: {
                  addonBg: baseColor100,
                  hoverBg: baseColor100,
                },
              },
            }}
          >
            <div className={styles.distributeBlock}>
              <SearchInput />
              <Space size={12} className={styles.numbersAndButtons}>
                <div className={styles.numbersBlock}>
                  <NumberItem
                    label={t('selected_seats_label')}
                    value={selectedRowItems.reduce((acc, curr) => {
                      return acc + curr.requestedSeats;
                    }, 0)}
                    isInverted
                  />
                </div>
                <Space size={12}>
                  <Button
                    danger
                    type="primary"
                    onClick={openRemoveMultipleSeatsModal}
                  >
                    {t('remove_positions_button_text', {
                      count: selectedRowKeys.length,
                    })}
                  </Button>
                  {selectedRowItems.find(
                    (item) => item.status === SeatStatus.RSVP_ACCEPTED
                  ) && (
                    <ConfigProvider
                      theme={{
                        token: {
                          colorText: 'unset',
                        },
                      }}
                    >
                      <Space size={12} direction="horizontal">
                        {!!parkingSeatsToDistribute && (
                          <Popconfirm
                            title={t(
                              'wl_positions_distribution_mode_with_parking'
                            )}
                            description={t(
                              `bulk_tickets_distribution_confirmation_description`,
                              {
                                positionsToDistribute:
                                  getDistributedSeatsNames(rowsToDistribute),
                              }
                            )}
                            cancelText={t('general_cancel_text')}
                            onConfirm={() => {
                              setDistributeWithParking(true);
                              setIsSeatsAssignation(true);
                            }}
                            onOpenChange={() => console.log('open change')}
                          >
                            <Button
                              type="primary"
                              color={successColor}
                              disabled={!rowsToDistribute.length}
                            >
                              {t('distribute-general')}{' '}
                              {seatsToDistributeTickets}{' '}
                              {t('mwl_wl_post-distribute-box')} +{' '}
                              {parkingSeatsToDistribute}{' '}
                              {t('parking_tickets_label').toLowerCase()}
                            </Button>
                          </Popconfirm>
                        )}

                        <Popconfirm
                          title={t('distribute-general')}
                          description={t(
                            `bulk_tickets_distribution_confirmation_description`,
                            {
                              positionsToDistribute:
                                getDistributedSeatsNames(rowsToDistribute),
                            }
                          )}
                          cancelText={t('general_cancel_text')}
                          onConfirm={() => {
                            setIsSeatsAssignation(true);
                          }}
                          onOpenChange={() => console.log('open change')}
                        >
                          <Button
                            color={successColor}
                            type="primary"
                            disabled={!rowsToDistribute.length}
                          >
                            {t('distribute-general')} {seatsToDistributeTickets}{' '}
                            {t('mwl_wl_post-distribute-box')}
                          </Button>
                        </Popconfirm>
                      </Space>
                    </ConfigProvider>
                  )}

                  <Button
                    type="primary"
                    onClick={handleSetNewTimeoutSelected}
                    style={{
                      color: 'white',
                    }}
                    // disabled={!isGeneralDistributionAvailable}
                  >
                    {t('set_expiration_time_label')}{' '}
                    {
                      selectedRowItems.filter(
                        (item) =>
                          item.status === SeatStatus.ASSIGNED ||
                          item.status === SeatStatus.ASSIGNED_WITHOUT_SEATS
                      ).length
                    }
                  </Button>
                </Space>
              </Space>
            </div>
          </ConfigProvider>
        ) : (
          <div className={styles.distribution}>
            <SearchInput />
            <Space>
              <Button
                onClick={openSurveyDownload}
                icon={<DownloadOutlined rev={undefined} />}
              >
                {t('export_survey_answers')}
              </Button>
              <Button
                onClick={handleExport}
                loading={isExporting}
                icon={<DownloadOutlined rev={undefined} />}
              >
                {t('mwl_seats_export-btn')}
              </Button>
              {!!surveys?.content.length && (
                <>
                  {expandedRows?.length === seats?.content?.length &&
                  !!seats?.content?.length ? (
                    <Tooltip title={t('hide_all_answers_btn_text')}>
                      <Button
                        type="text"
                        onClick={hideAllRows}
                        icon={<UpOutlined rev={undefined} />}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title={t('show_all_answers_btn_text')}>
                      <Button
                        type="text"
                        disabled={!seats || !seats.content.length}
                        onClick={expandAllRows}
                        icon={<DownOutlined rev={undefined} />}
                      />
                    </Tooltip>
                  )}
                </>
              )}
            </Space>
          </div>
        )}
      </div>
      <ConfigProvider
        theme={{
          token: {
            fontSize: (xs || sm || md) && !xxl ? 12 : 14,
          },
        }}
      >
        <Table
          expandable={
            surveys?.content.length
              ? {
                  expandedRowRender: (record) => (
                    <ExpandedRow rowData={record} surveys={surveys} />
                  ),
                  expandedRowKeys: expandedRows,
                  onExpand: (_, event) => {
                    handleRowExpand(event);
                  },
                }
              : undefined
          }
          rowKey={(record) => record.fanId}
          rowSelection={rowSelection}
          size="small"
          loading={isLoading}
          onChange={handleTableChange}
          pagination={pagination}
          dataSource={seats?.content}
          style={{ overflowX: 'auto' }}
          columns={[
            {
              title: (
                <Text
                  style={{
                    padding: 0,
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    height: 'auto',
                    textAlign: 'left',
                  }}
                >
                  {t('fans_list_tbl-name')}
                </Text>
              ),
              dataIndex: 'name',
              render: (_, position: SeatEntity) => {
                return (
                  <span>
                    {position.firstName} {position.lastName}
                  </span>
                );
              },
              width: 150,
              fixed: 'left',
            },
            {
              title: t('mwl_seats_table-status'),
              dataIndex: 'status',
              width: 135,
              render: (status: SeatStatus) => (
                <Space>
                  <SeatStatusTag status={status} />
                </Space>
              ),
            },
            {
              title: t('mwl_seats_table-email'),
              dataIndex: 'fanEmail',
              width: 200,
              ellipsis: {
                showTitle: false,
              },
              render: (fanEmail) => (
                <Tooltip placement="topLeft" title={fanEmail}>
                  <Text
                    style={{
                      padding: 0,
                      whiteSpace: 'normal',
                      wordWrap: 'break-word',
                      height: 'auto',
                      textAlign: 'left',
                    }}
                  >
                    {fanEmail}
                  </Text>
                </Tooltip>
              ),
            },
            {
              title: t('mwl_seats_table-seats'),
              dataIndex: 'requestedSeats',
              width: 100,
              onCell: (seat, rowIndex) => ({
                style: {
                  cursor: getSeatDisabled(seat.status) ? 'auto' : 'pointer',
                },
                onClick: () => {
                  if (
                    seat.fanId !== selectedAssetsRowId &&
                    !getSeatDisabled(seat.status)
                  ) {
                    setSelectedAssetsRowId(seat.fanId);
                  }
                }, // click header
                onMouseLeave: () => setSelectedAssetsRowId(null),
              }),
              render: (requestedSeats, seat: SeatEntity) => {
                return (
                  <Space size={4}>
                    {selectedAssetsRowId === seat.fanId ? (
                      <InputNumber
                        min={1}
                        defaultValue={requestedSeats}
                        disabled={mutating}
                        onChange={debounce((v) => {
                          handlePositionUpdate(seat.fanId, v);
                        }, 1000)}
                      />
                    ) : (
                      requestedSeats
                    )}
                  </Space>
                );
              },
            },

            {
              title: t('parking_tickets_label'),
              dataIndex: 'numberOfParkingTickets',
              width: 100,
              onCell: (seat, rowIndex) => ({
                style: {
                  cursor: getSeatDisabled(seat.status) ? 'auto' : 'pointer',
                },
                onClick: () => {
                  if (seat.fanId !== selectedParkingRowId && !getSeatDisabled(seat.status)) {
                    setSelectedParkingRowId(seat.fanId);
                  }
                }, // click header
                onMouseLeave: () => setSelectedParkingRowId(null),
              }),
              render: (numberOfParkingTickets, seat: SeatEntity) => {
                return (
                  <Space size={4}>
                    {selectedParkingRowId === seat.fanId ? (
                      <InputNumber
                        min={0}
                        defaultValue={numberOfParkingTickets}
                        onChange={debounce((v) => {
                          handleParkingTicketsUpdate(seat.fanId, v);
                        }, 1000)}
                        disabled={mutating}
                      />
                    ) : (
                      numberOfParkingTickets
                    )}
                  </Space>
                );
              },
            },

            {
              title: (
                <Text
                  style={{
                    padding: 0,
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    height: 'auto',
                    textAlign: 'left',
                  }}
                >
                  {t('joined_wl_status_text')}
                </Text>
              ),
              dataIndex: ['userStats', 'nrOfWaitinglistsJoined'],
              sortDirections: ['ascend', 'descend', 'ascend'],
              sorter: true,
              width: 80,
              sortOrder: getSortOrder('userStats.nrOfWaitinglistsJoined'),
              onHeaderCell: () => ({
                onClick: () => {
                  handleSort('userStats.nrOfWaitinglistsJoined');
                },
              }),
            },
            {
              title: t('rsvp_sent_message_text'),
              dataIndex: 'rsvpSent',
              sortDirections: ['ascend', 'descend', 'ascend'],
              sorter: true,
              width: 80,
              sortOrder: getSortOrder('userStats.won'),
              onHeaderCell: () => ({
                onClick: () => {
                  handleSort('userStats.won');
                },
              }),
              render: (_, position: SeatEntity) => {
                return (
                  <span>
                    {position.userStats.won} /{' '}
                    {position.userStats.nrOfWaitinglistsJoined}
                  </span>
                );
              },
            },
            {
              title: t('abandon_label'),
              dataIndex: 'abandon',
              sortDirections: ['ascend', 'descend', 'ascend'],
              sorter: true,
              width: 80,
              sortOrder: getSortOrder('userStats.abandoned'),
              onHeaderCell: () => ({
                onClick: () => {
                  handleSort('userStats.abandoned');
                },
              }),
              render: (_, position: SeatEntity) => {
                return (
                  <span>
                    {position.userStats.abandoned} /{' '}
                    {position.userStats.nrOfWaitinglistsJoined}
                  </span>
                );
              },
            },
            {
              title: t('last_rsvp_title'),
              dataIndex: 'lastRSVP',
              sortDirections: ['ascend', 'descend', 'ascend'],
              sorter: true,
              width: 80,
              sortOrder: getSortOrder('lastRSVP'),
              onHeaderCell: () => ({
                onClick: () => {
                  handleSort('lastRSVP');
                },
              }),
              render: (lastRSVP) => {
                return <span>{lastRSVP ? dayjs(lastRSVP).fromNow() : ''}</span>;
              },
            },
            {
              title: t('current_rsvp_title'),
              dataIndex: 'seatsExpirationDate',
              width: 100,
              hidden:
                wishList?.invitationParameters?.invitationMode ===
                InvitationMode.ENABLED,
              render: (seatsExpirationDate) => {
                return (
                  <span>
                    {seatsExpirationDate
                      ? dayjs(seatsExpirationDate).format('DD/MM/YYYY HH:mm')
                      : ''}
                  </span>
                );
              },
            },
            {
              title: t('mwl_wl_table-actions'),
              align: 'right',
              fixed: 'right',
              width: 100,
              render: (_, position: SeatEntity) => {
                return (
                  <Space size={0}>
                    <Dropdown
                      menu={{
                        items: items(position)?.filter((item) => !item.hidden),
                        onClick: ({ key }) =>
                          handleMenuOnClick({ key, position }),
                      }}
                    >
                      {/* <a onClick={(e) => e.preventDefault()}> */}
                      <Button type="link">
                        <Space>
                          {t('mwl_wl_table-actions')}
                          <DownOutlined rev={undefined} />
                        </Space>
                      </Button>
                    </Dropdown>
                  </Space>
                );
              },
            },
          ]}
        />
      </ConfigProvider>
      <Modal
        title={t('mwl_distri_title')}
        open={isSeatsAssignation}
        onOk={
          !assignationProgress
            ? handleConfirmDistributionModal
            : handleCloseDistributionModal
        }
        okText={
          !assignationProgress ? t('general_ok-btn') : t('general_close-btn')
        }
        onCancel={() => {
          handleCloseDistributionModal();
          handleQueriesInvalidation();
          setCurrentRow(null);
        }}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <Text>
            {t('matrix_header_distributed')}{' '}
            {(progressPercent * seatsToDistribute) / 100} / {seatsToDistribute}{' '}
            {t('wl_seats-label')}
          </Text>

          <Text type="secondary">{t('mwl_distri_info')}</Text>
          <Progress percent={progressPercent} />
        </Space>
      </Modal>
      <DistributeParkingModal
        open={!!fanIdToDistributeParking}
        onClose={() => setFanIdToDistributeParking(null)}
        fanId={fanIdToDistributeParking}
      />
      {checkoutShown && selectedFan && (
        <CheckoutModalFGO
          open={checkoutShown}
          onCancel={() => setCheckoutShown(false)}
          selectedFan={selectedFan}
          closeModal={() => setCheckoutShown(false)}
        />
      )}
      <DistributeMoreModal
        open={!!fanIdToDistributeMore}
        onClose={() => setFanIdToDistributeMore(null)}
        fanId={fanIdToDistributeMore}
      />
      <RemoveSeatModal
        open={!!seatToDelete}
        seatToDelete={seatToDelete}
        setSeatToDelete={setSeatToDelete}
        seatDistributionMode={wishList?.seatDistributionMode}
        isPaid={wishList?.billingMode === BillingMode.FAIR_PRICE}
      />
      {removeMultipleSeatsModalOpen && (
        <RemoveMultipleSeatModal
          open={removeMultipleSeatsModalOpen}
          rejectSelected={rejectSelected}
          isLoading={isSeatRemoving}
          closeModal={() => setRemoveMultipleSeatsModalOpen(false)}
          selectedRowItems={selectedRowItems}
          seatDistributionMode={wishList?.seatDistributionMode}
          isPaid={wishList?.billingMode === BillingMode.FAIR_PRICE}
        />
      )}
      <Modal
        title={t('reset_rsvp_confirmation_time_title')}
        open={!!positionToChangeTimeouts.length}
        onOk={() => form.submit()}
        onCancel={hidePositionToChangeTimeoutsModal}
        okButtonProps={{ loading: mutating }}
      >
        <Form
          disabled={mutating}
          initialValues={{
            voucherExpirationTimeOutInMs: 0,
          }}
          form={form}
          onFinish={async (v) => {
            if (positionToChangeTimeouts) {
              handleSetTimeouts(v);
            }
          }}
        >
          <Form.Item
            label={t('curr_position_confirmation_time_label')}
            name="voucherExpirationTimeOutInMs"
            rules={[
              { required: true, message: t('field_validation_required') },
            ]}
          >
            <InputNumber
              autoFocus
              min={0}
              type="number"
              addonAfter={
                <Select
                  value={multiplier}
                  defaultValue={minuteMultiplier}
                  onChange={(value) => {
                    return setMultiplier(value);
                  }}
                  options={[
                    {
                      value: minuteMultiplier,
                      label: t('general_duration-min'),
                    },
                    { value: hrsMultiplier, label: t('general_duration-hrs') },
                    { value: dayMultiplier, label: t('general_duration-day') },
                  ]}
                />
              }
            />
          </Form.Item>
        </Form>
      </Modal>
      <DownloadSurveyAnswersModal
        open={surveyDownloadOpen}
        close={closeSurveyDownload}
      />
    </div>
  );
}
