import { useTranslation } from 'react-i18next';
import { Button, Space, Table, TablePaginationConfig, Tooltip } from 'antd';
import { useState } from 'react';
import {
  useFanGroupStore,
  useFetchMailcampaigns,
} from '@seaters-app/data-access';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';
import { usePagination } from '../../../utils';
import { MailcampaignEntity, MailcampaignState } from '@seaters-app/constants';
import { NavLink } from 'react-router-dom';
import dayjs from 'dayjs';
import { recipientToLabel } from '../utils/recipientToLabel';
import { MailcampaignStatusTag } from '@seaters-app/ui-shared';

function MailcampaignSent() {
  const { t } = useTranslation();

  const { fanGroup } = useFanGroupStore();

  const fanGroupId = fanGroup?.id ?? '';

  const [query, setQuery] = useQueryParams({
    pageSize: withDefault(NumberParam, 10),
    current: withDefault(NumberParam, 1),
  });

  const { data: mailcampaign } = useFetchMailcampaigns({
    fangroup: fanGroupId,
    state: [
      MailcampaignState.SENT,
      MailcampaignState.SENDING,
      MailcampaignState.QUEUED,
      MailcampaignState.FAILED,
    ],
    offset: (query.current - 1) * query.pageSize,
    limit: query.pageSize,
  });

  const pagination = usePagination({
    ...query,
    totalSize: mailcampaign?.count,
  });

  const handleTableChange = ({ pageSize, current }: TablePaginationConfig) => {
    setQuery({
      pageSize,
      current,
    });
  };

  return (
    <>
      <Table
        rowKey={(record) => record.id}
        size="small"
        pagination={pagination}
        onChange={handleTableChange}
        dataSource={mailcampaign?.items}
        style={{ overflowX: 'auto' }}
        columns={[
          {
            title: t('mailcampain_table-name'),
            render: (mailcampaign: MailcampaignEntity) => {
              return (
                <Space size={0} direction="vertical">
                  <NavLink to={`../detail/${mailcampaign.id}`}>
                    <Button
                      style={{
                        padding: 0,
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        height: 'auto',
                        textAlign: 'left',
                      }}
                      type="link"
                    >
                      {mailcampaign.name}
                    </Button>
                  </NavLink>
                </Space>
              );
            },
          },
          {
            title: t('mailcampain_table-send_date'),
            dataIndex: 'updatedAt',
            render: (updatedAt: Date) => {
              return (
                <Space size={0} direction="vertical">
                  <Button
                    style={{
                      padding: 0,
                      whiteSpace: 'normal',
                      wordWrap: 'break-word',
                      height: 'auto',
                      textAlign: 'left',
                    }}
                    type="link"
                  >
                    {dayjs(updatedAt).format('DD/MM/YY, HH:mm')}
                  </Button>
                </Space>
              );
            },
          },
          {
            title: t('mailcampaign_table-recipient'),
            render: (mailCampaign) => (
              <Space size={0} direction="vertical">
                <Button
                  style={{
                    padding: 0,
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    height: 'auto',
                    textAlign: 'left',
                  }}
                  type="link"
                >
                  {recipientToLabel(mailCampaign?.recipient) || ''}
                  {!!mailCampaign?.recipient &&
                  mailCampaign.recipient !== 'fangroup'
                    ? ` (${mailCampaign?.wishlist?.name})`
                    : ''}
                </Button>
              </Space>
            ),
          },
          {
            title: t('status_label'),
            dataIndex: 'state',
            render: (state: MailcampaignState) => {
              return <MailcampaignStatusTag status={state} />;
            },
          },
        ]}
      />
    </>
  );
}
export default MailcampaignSent;
