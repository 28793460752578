import { routes } from '@seaters-app/constants';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Typography, Tabs, TabsProps, Flex, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const { Title } = Typography;

function Mailcampaigns() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onChangeTab = (key: string) => {
    navigate(key);
  };
  const { pathname } = useLocation();

  const items: TabsProps['items'] = [
    {
      key: routes.draft,
      label: t('mailcampaign_draft_label'),
    },
    {
      key: routes.sent,
      label: t('mailcampaign_sent_label'),
    },
    {
      key: routes.scheduled,
      label: t('mailcampaign_scheduled_label'),
    },
  ];

  const activeTab = items?.find((item) => pathname.includes(item.key))?.key;

  return (
    <Flex
      vertical
      style={{
        padding: 24,
      }}
    >
      <div className={styles.subheader}>
        <Title level={2}>{t('mailcampaigns_tab')}</Title>
        <Link to={routes.create}>
          <Button size="large" type="primary" icon={<PlusOutlined />}>
            {t('general_add-btn')} {t('mailcampaign')}
          </Button>
        </Link>
      </div>

      <Tabs
        activeKey={activeTab}
        defaultActiveKey={items[0].key}
        items={items}
        onChange={onChangeTab}
      />
      <Outlet />
    </Flex>
  );
}

export default Mailcampaigns;
