import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useIsMutating } from '@tanstack/react-query';
import {
  Alert,
  Col,
  InputNumber,
  Modal,
  Radio,
  Row,
  Segmented,
  Space,
  Spin,
  Typography,
  notification, Tooltip
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import {
  DownloadOutlined,
  EyeOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import Form from 'antd/es/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Editor, baseColor600, baseColor900 } from '@seaters-app/ui';
import { Document, Page } from 'react-pdf';

import {
  useAppUserStore,
  useFanGroupStore,
  useFetchAdminFanGroup,
  useFetchAdminWaitingList,
  useFetchFGOWaitingListAvailableSeats,
  useFetchFanGroupOwnerWaitingList,
  usePreviewWaitingListVoucher,
  useRemoveWishListTickets,
  useUpdateAdminWaitingList,
  useUpdateWaitingList,
  useUpdateWaitingListTickets,
  useUpdateWaitingListToDraft,
} from '@seaters-app/data-access';
import {
  CreateWishListTicketsFormValidatorSchema,
  EditWishListTicketsFormValidatorSchema,
  FormTranslationType,
  Language,
  SEATERS_APP_BASE_URL,
  SeatDistributionMode,
  SeatDistributionModeLabels,
  UserRole,
  routes,
  languagesOptions,
  WaitingListAcceptFormat,
} from '@seaters-app/constants';
import { getTranslations, initialTranslations } from '../../../../utils';
import styles from './tickets.module.css';

import Footer from '../components/Footer';
import { convertTranslation } from '../helpers/convertTransaltions';
import { useTranslation } from 'react-i18next';
import { useCurrentStep } from '../hooks/useCurrentStep';
import Card from 'antd/es/card/Card';
import Paragraph from 'antd/es/typography/Paragraph';
import TicketPoolSelect from './TicketPoolSelect';
import { StyledText } from '@seaters-app/ui-shared';
import VoucherUploader from './Uploader';

const { Text } = Typography;

type TicketsStepFormValues = {
  seatDistributionMode: SeatDistributionModeLabels;
  voucherText?: FormTranslationType;
  ticketsToAdd: number;
  downloadFormat: WaitingListAcceptFormat;
  estimatedTickets: number;
  ticketPoolIds?: string[];
  secondaryTicketPoolIds?: string[];
  voucherImageUrl: string;
};

type UploadedImage = {
  height: number;
  size: number;
  type: string;
  width: number;
};

export function TicketsStep({ isEdit }: { isEdit?: boolean }) {
  const navigate = useNavigate();
  const isMutating = !!useIsMutating();
  const { next, prev } = useCurrentStep();
  const { fanGroup } = useFanGroupStore();
  const { user } = useAppUserStore();
  const [editorShown, setEditorShown] = useState(false);
  const [infoModalShown, setInfoModalShown] = useState(false);

  const isAdmin = user?.roles.includes(UserRole.ADMIN);

  const { data: adminFanGroup } = useFetchAdminFanGroup(
    (isAdmin && fanGroup?.id) || ''
  );
  const { wishListId = '' } = useParams<{ wishListId: string }>();
  const { t } = useTranslation();
  const { data: wishList, isLoading: wishListIsLoading } =
    useFetchFanGroupOwnerWaitingList(wishListId);
  const { data: availableSeatsData } =
    useFetchFGOWaitingListAvailableSeats(wishListId);

  const { mutateAsync: updateWishList, isLoading: updateWishListIsLoading } =
    useUpdateWaitingList();

  const { mutateAsync: removeWishListTickets } = useRemoveWishListTickets();

  const { data: adminWL } = useFetchAdminWaitingList(wishListId);

  const {
    mutateAsync: updateAdminWishList,
    isLoading: updateAdminWishListIsLoading,
  } = useUpdateAdminWaitingList();

  const {
    mutateAsync: updateWishListTickets,
    isLoading: updateWishListTicketsIsLoading,
  } = useUpdateWaitingListTickets();

  const { mutate: updateWLToDraft } = useUpdateWaitingListToDraft();

  const [saveAsDraft, setSaveAsDraft] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState<Language>(Language.EN);

  const {
    data: previewVoucherImage,
    isLoading: voucherPreviewIsLoading,
    refetch,
  } = usePreviewWaitingListVoucher({
    waitingListId: wishListId,
    language: currentLanguage,
  });

  const initialValues: TicketsStepFormValues = {
    voucherText: wishList?.voucherText.length
      ? getTranslations(['voucherText'], wishList)?.voucherText
      : initialTranslations,
    ticketsToAdd: availableSeatsData?.numberOfAvailableSeats || 0,
    downloadFormat: wishList?.assetsDownloadFileFormat || WaitingListAcceptFormat.MULTIPAGE_PDF_FILE,
    estimatedTickets: wishList?.estimatedTickets || 0,
    seatDistributionMode: wishList?.seatDistributionMode,
    ticketPoolIds: wishList?.ticketPoolIds || [],
    secondaryTicketPoolIds: wishList?.secondaryTicketPoolIds || [],
    voucherImageUrl: wishList?.voucherImageUrl || '',
  };

  const methods = useForm({
    mode: 'onBlur',
    resolver: zodResolver(
      isEdit
        ? EditWishListTicketsFormValidatorSchema
        : CreateWishListTicketsFormValidatorSchema
    ),
    defaultValues: initialValues,
  });

  const {
    handleSubmit,
    control,
    getValues,
    reset,
    formState: { errors, isDirty },
  } = methods;

  const seatDistributionMode = useWatch({
    control,
    name: 'seatDistributionMode',
  });

  const voucherImageUrl = useWatch({
    control,
    name: 'voucherImageUrl',
  });

  const handlePreviewVoucher = () => {
    if (previewVoucherImage?.url) {
      window.open(previewVoucherImage.url, '_blank');
    }
  };

  const onSubmit = async (values: TicketsStepFormValues) => {
    setInfoModalShown(false);
    const voucherText = convertTranslation(
      values.voucherText || initialTranslations
    );

    const updateTicketsBody =
      values.ticketsToAdd > initialValues.ticketsToAdd
        ? {
            originalNumberOfTickets: wishList?.totalTickets || 0, // to ensure that the request is sync when adding tickets
            ticketsToAdd: values.ticketsToAdd - initialValues.ticketsToAdd,
          }
        : {
            originalNumberOfTickets: wishList?.totalTickets || 0,
            ticketsToRemove: initialValues.ticketsToAdd - values.ticketsToAdd,
          };

    if (isAdmin && adminWL) {
      const { billingVariables, ...rest } = adminWL;
      const body = {
        ...rest,
        ...billingVariables,
        seatDistributionMode:
          values.seatDistributionMode === SeatDistributionModeLabels.TICKET
            ? SeatDistributionMode.TICKET
            : SeatDistributionMode.VOUCHER,
        ticketPoolIds: values.ticketPoolIds,
        secondaryTicketPoolIds: values.secondaryTicketPoolIds,
        voucherText,
        assetsDownloadFileFormat: values.downloadFormat,
        estimatedTickets: values.estimatedTickets,
      };

      await updateAdminWishList(
        {
          waitingListId: wishList?.waitingListId || '',
          body,
        },
        {
          onSuccess: () => {
            notification.success({
              message: t('notification_success_wl_updated'),
            });
          },
          onError: ({ response }) => {
            notification.error({
              message: t('notification_error_wl_updated'),
              description: response?.data.message,
            });
            if (values.ticketsToAdd < initialValues.ticketsToAdd) {
              notification.error({
                message: t('notification_error_vouchers_number_changed'),
              });
            }
          },
          // onSettled: () => {
          //   reset();
          // },
        }
      );
    } else if (wishList?.waitingListId) {
      await updateWishList(
        {
          waitingListId: wishList?.waitingListId || '',
          body: {
            ...wishList,
            seatDistributionMode:
              values.seatDistributionMode === SeatDistributionModeLabels.TICKET
                ? SeatDistributionMode.TICKET
                : SeatDistributionMode.VOUCHER,
            ticketPoolIds: values.ticketPoolIds,
            secondaryTicketPoolIds: values.secondaryTicketPoolIds,
            voucherText,
            assetsDownloadFileFormat: values.downloadFormat,
            estimatedTickets: values.estimatedTickets,
          },
        },
        {
          onSuccess: () => {
            notification.success({
              message: t('notification_success_wl_updated'),
            });
          },
        }
      );
    }
    if (updateTicketsBody.ticketsToAdd) {
      await updateWishListTickets(
        {
          waitingListId: wishList?.waitingListId || '',
          body: updateTicketsBody,
        },
        {
          onSuccess: () => {
            notification.success({
              message: t('notification_success_vouchers_number_changed', {
                to: initialValues.ticketsToAdd + updateTicketsBody.ticketsToAdd,
              }),
            });
          },
          onError: ({ response }) => {
            notification.error({
              message: t('notification_error_vouchers_number_changed'),
              description: response?.data.message,
            });
          },
        }
      );
    }

    if (updateTicketsBody.ticketsToRemove) {
      await removeWishListTickets(
        {
          waitingListId: wishList?.waitingListId || '',
          body: updateTicketsBody,
        },
        {
          onSuccess: () => {
            notification.success({
              message: t('notification_success_vouchers_number_changed', {
                to:
                  initialValues.ticketsToAdd -
                  updateTicketsBody.ticketsToRemove,
              }),
            });
          },
          onError: ({ response }) => {
            notification.error({
              message: t('notification_error_vouchers_number_changed'),
              description: response?.data.message,
            });
          },
        }
      );
    }

    if (saveAsDraft) {
      updateWLToDraft(
        { waitingListId: wishList?.waitingListId },
        { onSuccess: () => navigate(`/${routes.admin}/${routes.wishLists}`) }
      );
    } else {
      if (!isEdit) {
        next();
      } else {
        navigate(
          `/${routes.admin}/${routes.wishLists}/${wishList?.waitingListId}`
        );
      }
    }
  };

  const seatDistributionModeOptions = [
    {
      value: SeatDistributionModeLabels.VOUCHER,
      label: 'wl_set_distribution-mode-voucher',
      description: 'wl_set_distribution-mode-voucher-description',
    },
    {
      value: SeatDistributionModeLabels.TICKET,
      label: 'wl_set_distribution-mode-ticket',
      description: 'wl_set_distribution-mode-ticket-description',
    },
    {
      value: SeatDistributionModeLabels.LATER,
      label: 'wl_set_distribution-mode-later',
      description: 'wl_set_distribution-mode-later-description',
      hide: isEdit,
    },
  ];

  const downloadFormatOptions = [
    {
      value: WaitingListAcceptFormat.MULTIPAGE_PDF_FILE,
      label: 'wl_set_download-format-multipage-pdf',
    },
    {
      value: WaitingListAcceptFormat.ZIP_FILE,
      label: 'wl_set_download-format-zip',
    }

  ];

  useEffect(() => {
    refetch();
  }, [currentLanguage]);

  return (
    <>
      <Spin
        tip={t('please_wait_message_text')}
        fullscreen
        spinning={wishListIsLoading || imageUploading}
      />
      <Space
        size="large"
        direction="vertical"
        style={{ width: '100%', paddingBottom: 40 }}
      >
        <p style={{ color: baseColor600 }}>{t('tickets_tab_instruction')}</p>
        {adminFanGroup?.operationParameters?.ticketingSystemId && (
          <Link
            to={`${SEATERS_APP_BASE_URL}${routes.admin}/${routes.ticketingSystems}/${adminFanGroup?.operationParameters?.ticketingSystemId}`}
          >
            <Button type="link" icon={<EyeOutlined rev={undefined} />}>
              {t('admin_fan-groups_ticketing-system')} {t('admin_tabs_details')}
            </Button>
          </Link>
        )}
        <FormProvider {...methods}>
          <Form
            layout="vertical"
            id="ticketsStep"
            onFinish={handleSubmit(onSubmit)}
            autoComplete="off"
            disabled={
              updateWishListTicketsIsLoading ||
              updateAdminWishListIsLoading ||
              updateWishListIsLoading ||
              imageUploading
            }
          >
            <Form.Item name="seatDistributionMode">
              <Controller
                control={control}
                name="seatDistributionMode"
                render={({ field }) => {
                  return (
                    <Radio.Group
                      value={seatDistributionMode}
                      name="seatDistributionMode"
                      defaultValue={wishList?.seatDistributionMode}
                    >
                      <Space>
                        <Row gutter={20}>
                          {seatDistributionModeOptions.map((option) => {
                            return !option.hide ? (
                              <Col xs={24} md={8}>
                                <Card bordered>
                                  <Radio
                                    {...field}
                                    value={option.value}
                                  >
                                    {t(option.label)}
                                  </Radio>

                                  <Paragraph
                                    style={{ color: baseColor600, margin: 0 }}
                                  >
                                    {t(option.description)}
                                  </Paragraph>
                                </Card>
                              </Col>
                            ) : null;
                          })}
                        </Row>
                      </Space>
                    </Radio.Group>
                  );
                }}
              />
            </Form.Item>
            <Paragraph strong style={{ color: baseColor900, marginBottom: 24 }}>
              {t('wl_download-format-title')}{' '}
              <Tooltip title={t('wl_set_help_download-format')}>
                <InfoCircleOutlined
                  rev={undefined}
                  style={{ color: baseColor600 }}
                />
              </Tooltip>
            </Paragraph>
            <Form.Item name="downloadFormat">
              <Controller
                control={control}
                name="downloadFormat"
                render={({ field }) => (
                  <Radio.Group
                    {...field}
                    defaultValue={initialValues.downloadFormat}
                  >
                    {downloadFormatOptions.map((el) => (
                      <Radio
                        key={el.value}
                        value={el.value}
                      >
                        {t(el.label)}
                      </Radio>
                    ))}
                  </Radio.Group>
                )}
              />
            </Form.Item>
            <Form.Item
              name="estimatedTickets"
              label={t('wl_set_estimated-seats')}
            >
              <Controller
                control={control}
                name="estimatedTickets"
                render={({ field }) => (
                  <InputNumber
                    {...field}
                    min={0}
                    size="middle"
                    style={{ width: 120 }}
                  />
                )}
              />
            </Form.Item>
            {seatDistributionMode === SeatDistributionMode.TICKET && <TicketPoolSelect />}
            {seatDistributionMode === SeatDistributionMode.VOUCHER && (
              <>
                <Row gutter={20} align="middle">
                  <Col xs={24} md={16} xl={5}>
                    <Form.Item
                      label={t('wl_availableseats-label')}
                      name="ticketsToAdd" // Actually this is the number of available seats
                    >
                      <Controller
                        control={control}
                        name="ticketsToAdd"
                        render={({ field }) => (
                          <InputNumber
                            {...field}
                            min={0}
                            size="middle"
                            style={{ width: 120 }}
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20} align="top">
                  <Col xs={24} sm={24} md={16} xxl={12} xl={12}>
                    <Row>
                      <Col>
                        <Form.Item
                          label={t('mwl_seats_table-voucher')}
                          name="voucherImageUrl"
                        >
                          <VoucherUploader
                            setImageUploading={setImageUploading}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col xs={24} md={24} xl={24}>
                        <Form.Item
                          label={
                            <div>
                              {t('voucher_instruction_label')}{' '}
                              <InfoCircleOutlined
                                rev={undefined}
                                style={{ color: baseColor600 }}
                              />
                            </div>
                          }
                          name={`voucherText.${currentLanguage}`}
                          validateStatus={
                            errors.voucherText &&
                            errors.voucherText?.root?.message &&
                            'error'
                          }
                          help={
                            errors.voucherText &&
                            t(errors.voucherText?.root?.message)
                          }
                        >
                          <Controller
                            control={control}
                            name={`voucherText.${currentLanguage}`}
                            render={({ field: { onChange, ...field } }) => {
                              return !editorShown ? (
                                <StyledText
                                  text={field.value ?? ''}
                                  onClick={() => setEditorShown(true)}
                                />
                              ) : (
                                <Editor
                                  {...field}
                                  onEditorChange={onChange}
                                  width="100%"
                                  height={200}
                                />
                              );
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={16} xxl={12} xl={12}>
                    <Form.Item label={t('voucher_preview_label')}>
                      <Space direction="vertical">
                        {typeof getValues().voucherImageUrl === 'object' && (
                          <Alert
                            closable
                            type="info"
                            message={t('updated_voucher_image_tip')}
                          />
                        )}
                        <Document
                          file={previewVoucherImage?.url}
                          onError={(error) =>
                            console.log('Error loading PDF:', error)
                          }
                        >
                          <div className={styles.container}>
                            <div className={styles.overlay}>
                              <Button
                                type="primary"
                                icon={<DownloadOutlined rev={undefined} />}
                                onClick={() => {
                                  const voucherUrlOrCanvas = voucherImageUrl as
                                    | string
                                    | HTMLCanvasElement;

                                  if (
                                    voucherUrlOrCanvas &&
                                    typeof voucherUrlOrCanvas !== 'string'
                                  ) {
                                    setInfoModalShown(true);
                                  } else {
                                    handlePreviewVoucher();
                                  }
                                }}
                                loading={voucherPreviewIsLoading}
                                size="large"
                              />
                            </div>
                            <div
                              key={`page_voucher`}
                              className={styles.content}
                            >
                              <Page
                                pageNumber={1}
                                renderMode="canvas"
                                scale={0.7}
                              />
                            </div>
                          </div>
                        </Document>
                      </Space>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}

            <Footer
              isEdit={isEdit}
              handlePrevStep={prev}
              saveAsDraft={() => {
                setSaveAsDraft(true);
                onSubmit(getValues());
              }}
              cancelButton={
                <Button
                  disabled={!isDirty}
                  size="middle"
                  type="default"
                  danger
                  icon={<CloseOutlined rev={undefined} />}
                  onClick={() => reset(initialValues)}
                >
                  {t('button_text_cancel')}
                </Button>
              }
              isLoading={isMutating}
            >
              <Segmented
                size="middle"
                options={languagesOptions}
                value={currentLanguage}
                onChange={setCurrentLanguage}
              />
            </Footer>
          </Form>
        </FormProvider>
        {infoModalShown && (
          <Modal
            title={t('voucher_preview_label')}
            open={infoModalShown}
            onCancel={() => setInfoModalShown(false)}
            footer={[
              <Button onClick={handlePreviewVoucher}>
                {t('see_current_voucher_button')}
              </Button>,
              <Button htmlType="submit" form="ticketsStep" type="primary">
                {t('save_wl_button')}
              </Button>,
            ]}
          >
            <Text>{t('voucher_image_preview_description_modal')}</Text>
          </Modal>
        )}
      </Space>
    </>
  );
}
