import {
  endpoints,
  ListEntity,
  FanGroupsQuery,
  FanGroupEntity,
  FanGroupValidator,
  UploadFileResponseType,
  OneTimeUploadQuery,
  OneTimeUploadImageQuery,
  RequestAccessResult,
  ListEntityV2,
  V2Prefix,
  FanGroupMember,
  FanGroupMembersValidator,
  SortedPaginationQueryV2,
  CreateFGOGroupMemberValidator,
  seatersAdminPrefix,
  ThemeEntity,
  PaginationQuery,
  RegistrationTypeEnum,
  LoginTypeEnum,
  FanAccessRequest,
} from '@seaters-app/constants';
import { api } from '../axios-instance';
import imageCompression from 'browser-image-compression';


const validImageTypes: string[] = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/webp',
  'image/bmp',
  'image/tiff',
  'image/svg+xml',
  'image/heif',
  'image/heic'
];

const validImageExtensions: string[] = [
  'jpg',
  'jpeg',
  'png',
  'gif',
  'webp',
  'bmp',
  'tiff',
  'tif',
  'svg',
  'heif',
  'heic'
];

export type LoginConfigBody = {
  loginConfigurations: {
    configs: {
      type: LoginTypeEnum;
      providerId: string;
      providerName: { [key: string]: string };
    }[];
  };
};

export type SignupConfigBody = {
  registrationTypes: RegistrationTypeEnum[];
};

export const fetchAdminFanGroups = (
  params: FanGroupsQuery
): Promise<ListEntity<FanGroupEntity>> =>
  api.get(
    `${V2Prefix}/${seatersAdminPrefix}/${endpoints.groups}/${endpoints.search}`,
    { params }
  );

export const fetchJoinedFanGroups = (
  params: FanGroupsQuery
): Promise<ListEntity<FanGroupEntity>> =>
  api.get(`${endpoints.fan}/${endpoints.joinedGroups}`, { params });

export const fetchAdminFanGroup = (id: string): Promise<FanGroupEntity> =>
  api.get(`${endpoints.seatersAdmin}/${endpoints.fanGroups}/${id}`);

export const fetchFGOFanGroup = (id: string): Promise<FanGroupEntity> =>
  api.get(`${endpoints.fanGroupOwner}/${endpoints.groups}/${id}`);

export const fetchFanFGSeats = (
  id: string
): Promise<{
  numberOfSeats: number;
  directSaleModeTicketsLimit: number | null;
}> =>
  api.get(
    `${V2Prefix}/${endpoints.fan}/${endpoints.groups}/${id}/${endpoints.countSeats}`
  );

export const createFanGroup = (
  body: FanGroupValidator
): Promise<FanGroupEntity> =>
  api.post(`${endpoints.seatersAdmin}/${endpoints.fanGroups}`, body);

export const updateAdminFanGroup = (
  id: string,
  body: FanGroupValidator
): Promise<FanGroupEntity> => {
  return api.put(
    `${endpoints.seatersAdmin}/${endpoints.fanGroups}/${id}`,
    body
  );
};

export const updateFanGroup = (
  id: string,
  body: FanGroupValidator
): Promise<FanGroupEntity> => {
  return api.put(`${endpoints.fanGroupOwner}/${endpoints.groups}/${id}`, body);
};

// PUT https://api.qa-seaters.com/api/v2/seaters-admin/groups/**groupId**/login-configurations/

export const updateFanGroupLoginConfig = (
  id: string,
  body: LoginConfigBody
): Promise<FanGroupEntity> => {
  return api.put(
    `${V2Prefix}/${endpoints.seatersAdmin}/${endpoints.groups}/${id}/${endpoints.loginConfigurations}`,
    body
  );
};

// PUT https://api.qa-seaters.com/api/v2/seaters-admin/groups/**groupId**/registration-types/

export const updateFanGroupSignupConfig = (
  id: string,
  body: SignupConfigBody
): Promise<FanGroupEntity> => {
  return api.put(
    `${V2Prefix}/${endpoints.seatersAdmin}/${endpoints.groups}/${id}/${endpoints.registrationTypes}`,
    body
  );
};

export const requestOneTimeUpload = (
  params: OneTimeUploadQuery,
  allowCompression: boolean = true,
): Promise<UploadFileResponseType> => {
  const [name, ext] = params.fileName ? params.fileName.toLowerCase().split('.') : ['file', ''];
  // if valid image format, file is going to be compressed to webp
  if (allowCompression && validImageExtensions.includes(ext)) {
    params.fileName = name + '.' + 'webp';
  }
  return api.put(
    `${endpoints.fanGroupOwner}/${endpoints.requestOneTimeUpload}?fileName=${params.fileName}`
  );
}

export const requestAdminOneTimeUpload = (
  params: OneTimeUploadQuery
): Promise<UploadFileResponseType> =>
  api.put(
    `${endpoints.seatersAdmin}/${endpoints.requestOneTimeUpload}?fileName=${params.fileName}`
  );

export const requestAdminOneTimeImageUpload = (
  params: OneTimeUploadImageQuery
): Promise<UploadFileResponseType> => {
  const [name, ext] = params.fileName ? params.fileName.toLowerCase().split('.') : ['file', ''];
  // if valid image format, file is going to be compressed to webp
  if (validImageExtensions.includes(ext)) {
    params.fileName = name + '.' + 'webp';
  }
  return api.put(
    `${endpoints.seatersAdmin}/${endpoints[params.entityType]}/${
      params.id
    }/image/?fileName=${params.fileName}`
  );
}

export const uploadFile = async (body: FormData, token: string, allowCompression: boolean = true): Promise<unknown> => {
  const file = body.get("file") as File | Blob | null;
  if (allowCompression && file && validImageTypes.includes(file.type)) {
      const imageFile = file instanceof File ? file : new File([file], "compressed_image.webp", { type: file.type });
      // Compress the file
      const compressedImage: Blob = await imageCompression(imageFile, {
        useWebWorker: true,
        fileType: "image/webp",
        initialQuality: 0.1
      });
      body.set("file", compressedImage);
  }

  return api.post(
    `${endpoints.oneTimeFiles}/${token}/${endpoints.upload}`,
    body
  );
};

export const updateFanGroupProfileImage = (
  id: string,
  body: { fileId: string }
): Promise<FanGroupEntity> => {
  return api.put(
    `${endpoints.fanGroupOwner}/${endpoints.groups}/${id}/${endpoints.profileImage}?fileId=${body.fileId}`
  );
};

export const updateFanGroupClientLogo = (
  id: string,
  body: { fileId: string }
): Promise<FanGroupEntity> => {
  return api.put(
    `${endpoints.fanGroupOwner}/${endpoints.groups}/${id}/${endpoints.logoImage}?fileId=${body.fileId}`
  );
};

export const updateFanGroupCoverImage = (
  id: string,
  body: { fileId: string }
): Promise<FanGroupEntity> => {
  return api.put(
    `${endpoints.fanGroupOwner}/${endpoints.groups}/${id}/${endpoints.coverImage}?fileId=${body.fileId}`
  );
};
export const updateFanGroupBackgroundImage = (
  id: string,
  body: { fileId: string }
): Promise<FanGroupEntity> => {
  return api.put(
    `${endpoints.fanGroupOwner}/${endpoints.groups}/${id}/${endpoints.backgroundImage}?fileId=${body.fileId}`
  );
};

export const deleteFanGroup = (fanGroupId: string) =>
  api.delete(`${endpoints.seatersAdmin}/${endpoints.fanGroups}/${fanGroupId}`);

export const joinPublicFG = (groupId: string): Promise<FanGroupEntity> => {
  return api.post(`${endpoints.fan}/${endpoints.groups}/${groupId}`);
};

export const requestFanAccessToProtectedFG = (
  groupId: string,
  body: {
    code: string;
  }
): Promise<RequestAccessResult> => {
  return api.post(
    `${endpoints.fan}/${endpoints.groups}/${groupId}/${endpoints.requestWithData}`,
    { code: body.code }
  );
};

export const updateRequestFanAccessToProtectedFG = (
  groupId: string,
  body: {
    code: string;
  }
): Promise<RequestAccessResult> => {
  return api.put(
    `${endpoints.fan}/${endpoints.groups}/${groupId}/${endpoints.request}`,
    { code: body.code }
  );
};

export const requestFanAccessToFG = (
  groupId: string,
  body: {
    code?: string;
  }
): Promise<RequestAccessResult> => {
  return api.post(
    `${endpoints.fan}/${endpoints.groups}/${groupId}/${endpoints.request}`,
    { code: body.code }
  );
};

export const deleteRequestToJoinFG = (groupId: string) =>
  api.delete(
    `${endpoints.fan}/${endpoints.groups}/${groupId}/${endpoints.request}`
  );

// PUT: https://api.qa-seaters.com/api/v2/fan-group-owner/groups/id/members/
export const fetchFGOGroupMembers = (
  fanGroupId: string,
  params: SortedPaginationQueryV2,
  body: FanGroupMembersValidator
): Promise<ListEntityV2<FanGroupMember>> =>
  api.put(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.groups}/${fanGroupId}/${endpoints.members}?page=${params.page}&size=${params.size}`,
    body
  );

// POST: https://api.qa-seaters.com/api/v2/fan-group-owner/waiting-lists/1ede858e-3b0a-45d2-ae20-a17eeb2ed9f7/fan/fca2c3b0-3909-4ba5-815d-740684304209
export const createFGOGroupMembers = (
  waitingListId: string,
  fanId: string,
  body: CreateFGOGroupMemberValidator
): Promise<unknown> =>
  api.post(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.fan}/${fanId}`,
    body
  );

// POST https://api.qa-seaters.com/api/v2/seaters-admin/users/id/groups/groupId/
export const addFanGroupMember = (
  userId: string,
  groupId: string
): Promise<FanGroupEntity> =>
  api.post(
    `${V2Prefix}/${endpoints.users}/${userId}/${endpoints.groups}/${groupId}`
  );

// POST https://api.qa-seaters.com/api/v2/seaters-admin/users/fdsfdff/groups/2ad5e829-de85-42bd-8ce0-5c0fe13f9d49/request-with-data?page=0&size=10

export const addFanToProtectedFG = (
  userId: string,
  groupId: string,
  body: {
    code: string;
  }
): Promise<RequestAccessResult> => {
  return api.post(
    `${V2Prefix}/${endpoints.users}/${userId}/${endpoints.groups}/${groupId}/${endpoints.requestWithData}`,
    { code: body.code }
  );
};

// PUT https://api.qa-seaters.com/api/v2/seaters-admin/users/id/groups/groupId/request-with-data/

export const updateUserRequestToJoinProtectedFG = (
  userId: string,
  groupId: string,
  body: {
    code: string;
  }
): Promise<RequestAccessResult> => {
  return api.put(
    `${V2Prefix}/${endpoints.users}/${userId}/${endpoints.groups}/${groupId}/${endpoints.requestWithData}`,
    { code: body.code }
  );
};

// DELETE https://api.qa-seaters.com/api/v2/fan-group-owner/groups/fgId/members/userId

export const deleteFanGroupMember = (fanGroupId: string, userId: string) =>
  api.delete(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.groups}/${fanGroupId}/${endpoints.members}/${userId}`
  );

export const fetchFanGroupThemes = (
  params: PaginationQuery
): Promise<ListEntity<ThemeEntity>> =>
  api.get(`${seatersAdminPrefix}/${endpoints.themes}`, {
    params,
  });

// PUT /api/v2/fan-group-owner/groups/{groupId}/properties

export const updateFanGroupProperties = (
  groupId: string,
  body: {
    properties: FanGroupEntity['properties'];
  }
): Promise<FanGroupEntity> => {
  return api.put(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.groups}/${groupId}/${endpoints.properties}`,
    body
  );
};

// GET https://api.qa-seaters.com/api/seaters-admin/fan-groups/id/requests/

export const fetchFanGroupAccessRequests = (
  fanGroupId: string,
  params: PaginationQuery
): Promise<ListEntity<FanAccessRequest>> =>
  api.get(
    `${seatersAdminPrefix}/${endpoints.fanGroups}/${fanGroupId}/${endpoints.requests}`,
    { params }
  );
