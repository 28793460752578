import {
  endpoints,
  ListEntity,
  PaginationQuery,
  TicketingSystem,
  TicketPool,
  TicketPoolValidator, V2Prefix
} from '@seaters-app/constants';
import { api } from '../axios-instance';

export const fetchTicketingSystems = (
  params: PaginationQuery
): Promise<ListEntity<TicketingSystem>> =>
  api.get(`${endpoints.ticketing}/${endpoints.systems}`, { params });

export const deleteTicketingSystem = (systemId: string) =>
  api.delete(`${endpoints.ticketing}/${endpoints.systems}/${systemId}`);

export const fetchTicketingSystem = (
  systemId: string
): Promise<TicketingSystem> =>
  api.get(`${endpoints.ticketing}/${endpoints.systems}/${systemId}`);

export const fetchTicketPools = (
  systemId: string,
  params: PaginationQuery
): Promise<ListEntity<TicketPool>> =>
  api.get(
    `${endpoints.ticketing}/${endpoints.systems}/${systemId}/${endpoints.ticketPools}`,
    { params }
  );

export const fetchTicketPoolsFGO = (
  groupId: string,
  params: PaginationQuery
): Promise<ListEntity<TicketPool>> =>
  api.get(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.groups}/${groupId}/${endpoints.ticketPools}`,
    { params }
  );

export const fetchTicketPool = (ticketPoolId: string): Promise<TicketPool> =>
  api.get(`${endpoints.ticketing}/${endpoints.ticketPools}/${ticketPoolId}`);

export const createTicketPool = (
  systemId: string,
  ticketPool: TicketPoolValidator
): Promise<TicketPool> =>
  api.post(
    `${endpoints.ticketing}/${endpoints.systems}/${systemId}/${endpoints.ticketPools}`,
    ticketPool
  );

export const createTicketPoolFGO = (
  groupId: string,
  ticketPool: TicketPoolValidator
): Promise<TicketPool> =>
  api.post(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.groups}/${groupId}/${endpoints.ticketPools}`,
    ticketPool
  );

export const editTicketPool = (
  ticketPoolId: string,
  ticketPool: TicketPoolValidator
): Promise<TicketPool> =>
  api.put(
    `${endpoints.ticketing}/${endpoints.ticketPools}/${ticketPoolId}`,
    ticketPool
  );

export const uploadTickets = (ticketPoolId: string, fileId: string) =>
  api.put(
    `${endpoints.ticketing}/${endpoints.ticketPools}/${ticketPoolId}/${endpoints.uploadTickets}`,
    { fileId }
  );

export const createTicketingSystem = (
  system: TicketingSystem
): Promise<TicketingSystem> =>
  api.post(`${endpoints.ticketing}/${endpoints.systems}`, system);
