import {
  endpoints,
  FGOWishListInvitation,
  ListEntityV2,
  V2Prefix,
  FGOWishListInvitationsQuery,
  FGOInvitationRejectBody,
} from '@seaters-app/constants';
import { api } from '../axios-instance';

export const fetchFGOInvitations = (
  waitingListId: string,
  params: FGOWishListInvitationsQuery
): Promise<ListEntityV2<FGOWishListInvitation>> =>
  api.get(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.invitations}`,
    { params }
  );

export const rejectFGOInvitations = (
  invitationId: string,
  body: FGOInvitationRejectBody = { rejectionReason: null }
): Promise<FGOWishListInvitation> =>
  api.post(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.invitations}/${invitationId}/${endpoints.reject}`,
    body
  );

export const approveFGOInvitations = (
  invitationId: string
): Promise<FGOWishListInvitation> =>
  api.post(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.invitations}/${invitationId}/${endpoints.approve}`
  );

export const approveSelectedInvitations = async (invitationIds: string[]): Promise<FGOWishListInvitation[]> => {
  return api.post<FGOWishListInvitation[]>(
    "v2/fan-group-owner/invitations/approve-selected",
    invitationIds // Sending the array of IDs in the body
  ).then(response => response.data);
};