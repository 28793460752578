import {
  AccessMode,
  LOCAL_STORAGE_USER_LOCALE,
  LookEntity,
  privacyPolicyUrl,
  RegistrationTypeEnum,
  routes,
  SignupFormValidator,
  SignupFormValidatorSchema,
  userAgreementUrl,
} from '@seaters-app/constants';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';

import {
  getObjectTranslation,
  getSlugFromUrl,
  loginV2,
  queryClient,
  useFetchLook,
  useRequestFanAccessToProtectedFG,
  useSignUp,
} from '@seaters-app/data-access';
import { baseColor600, Button, PasswordValidation } from '@seaters-app/ui';
import {
  Card,
  Checkbox,
  Col,
  ConfigProvider,
  notification,
  Row,
  theme,
  Tooltip,
  Typography,
} from 'antd';
import './index.css';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useInitialValues } from './usePrefilledValues';
import { useGetAuthOption } from '../SignIn/hooks/useGetAuthOption';
import { InfoCircleOutlined } from '@ant-design/icons';
import { StyledText } from '@seaters-app/ui-shared';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslatedProperty } from '../helpers';
import { FacebookLogin } from '../SignIn/components';

const { Title, Text } = Typography;

function SignUp() {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const lang = language === 'nl_NL' || language === 'nl_BE' ? 'nl' : language;
  const fanGroupData: LookEntity | undefined = queryClient.getQueryData([
    'users',
    'look',
  ]);

  const fgPrivacyPolicyUrl = useTranslatedProperty(
    fanGroupData?.privacyPolicyUrls
  );

  const fgTermsAndConditionsUrl = useTranslatedProperty(
    fanGroupData?.termsAndConditionsUrls
  );

  const termsAndConditionsText = useTranslatedProperty(
    fanGroupData?.optInTermsTexts
  );

  const rulesText = useTranslatedProperty(fanGroupData?.optInRulesTexts);

  const partnersText = useTranslatedProperty(fanGroupData?.optInPartnersTexts);

  const platformText = useTranslatedProperty(fanGroupData?.optInPlatformTexts);

  const rulesLinksUrls = useTranslatedProperty(fanGroupData?.rulesLinks);

  const detectedSlug = getSlugFromUrl();

  const initialValues = useInitialValues();
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const { data: look } = useFetchLook(detectedSlug ?? '');
  const { mutate: signUp, isLoading: isSignUpLoading } = useSignUp();

  const { mutate: requestAccess } = useRequestFanAccessToProtectedFG(
    fanGroupData?.fanGroupId ?? ''
  );

  const fanGroupName = fanGroupData?.name.en;
  const accessMode = fanGroupData?.accessMode;

  const methods = useForm<SignupFormValidator>({
    mode: 'onBlur',
    resolver: zodResolver(SignupFormValidatorSchema),
    defaultValues: {
      ...initialValues,
      fanGroupRules: rulesLinksUrls ? false : undefined,
    },
    values: initialValues,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = methods;

  const password = useWatch({
    control,
    name: 'password',
  });

  const onSubmit = (values: SignupFormValidator) => {
    const userToSignUp = {
      ...initialValues,
      ...values,
      email: values.email.trim(),
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
      // confirmationReturnURLPath: `${SEATERS_APP_BASE_URL}`,
      registeredFromFanGroupId: look?.fanGroupId,
      directMarketingSettings: {
        allowDirectMarketingFromSeaters: values.allowDirectMarketingFromSeaters,
        allowDirectMarketingFromPartners:
          values.allowDirectMarketingFromPartners,
      },
    };

    delete userToSignUp.accessCode;

    userToSignUp.language =
      localStorage.getItem(LOCAL_STORAGE_USER_LOCALE) ?? 'en';

    signUp(userToSignUp, {
      onSuccess: async () => {
        await loginV2({ email: values.email, password: values.password });
        if (detectedSlug) {
          navigate(`..${routes.root}/${detectedSlug}`);
        } else {
          navigate(`../${routes.root}`);
        }
        const accessCode = values?.accessCode ?? initialValues.accessCode;
        if (accessCode) {
          await requestAccess({
            code: accessCode,
          });
        }
      },
      onError: (err) => {
        notification.error({
          message:
            t(
              `${err?.response?.data?.errors?.errorsTypes?.validation_errors[0].error?.errorCode}`
            ) ?? err.message,
        });
      },
    });
  };

  const facebookSignUp = useGetAuthOption(
    RegistrationTypeEnum.FACEBOOK
  ).registration;

  // const classicSignUp = useGetAuthOption(
  //   RegistrationTypeEnum.CLASSIC
  // ).registration;

  const classicSignUp = true;

  const tooltipContent = getObjectTranslation(
    fanGroupData?.protectionCodeExplanation,
    lang,
    t('unlock_text_fangroup_reserved', {
      fangroup: fanGroupData?.translatedName,
    })
  );

  return (
    <Card className="publicForm" bordered={false}>
      <Row justify="center" align="middle">
        <Col span={24}>
          <Title level={3}>{t('signup_text_title')}</Title>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Text>{t('signup_text_already_account')}</Text>
            <ConfigProvider
              theme={{
                token: {
                  colorLink: token.colorPrimary,
                  colorLinkHover: token.colorPrimary,
                  colorLinkActive: token.colorPrimary,
                },
              }}
            >
              <NavLink to={`../${routes.login}`}>
                <Button type="link">{t('signup_text_login_here')}</Button>
              </NavLink>
            </ConfigProvider>
          </div>
          {facebookSignUp && <FacebookLogin register />}
          {classicSignUp ? (
            <FormProvider {...methods}>
              <Form
                name="basic"
                layout="vertical"
                style={{ maxWidth: 600 }}
                onFinish={handleSubmit(onSubmit)}
                autoComplete="off"
                data-testid="signUpForm"
              >
                {accessMode === AccessMode.CODE_PROTECTED && (
                  <div style={{ marginTop: '15px', marginBottom: '10px' }}>
                    <Form.Item
                      label={
                        <Text strong style={{ color: baseColor600 }}>
                          {t('signup_text_enter_access_code')}{' '}
                          <Tooltip title={tooltipContent}>
                            <InfoCircleOutlined rev={undefined} />
                          </Tooltip>
                        </Text>
                      }
                      name="accessCode"
                    >
                      <Controller
                        disabled={!!initialValues?.accessCode}
                        control={control}
                        name={'accessCode'}
                        render={({ field }) => (
                          <Input data-testid="accessCode" {...field} />
                        )}
                      />
                    </Form.Item>
                  </div>
                )}

                <div style={{ marginTop: '15px', marginBottom: '10px' }}>
                  <Text strong style={{ color: baseColor600 }}>
                    {t('signup_text_enter_user_details')}
                  </Text>
                </div>

                <Form.Item
                  label={t('signup_form_email-label')}
                  name="email"
                  validateStatus={errors.email && 'error'}
                  help={errors?.email && t(errors?.email?.message)}
                >
                  <Controller
                    control={control}
                    name={'email'}
                    render={({ field }) => (
                      <Input data-testid="signUpEmail" {...field} />
                    )}
                  />
                </Form.Item>
                <Form.Item
                  label={t('signup_form_firstname-label')}
                  name="firstName"
                  validateStatus={errors.firstName && 'error'}
                  help={errors?.firstName && t(errors?.firstName?.message)}
                >
                  <Controller
                    control={control}
                    name={'firstName'}
                    render={({ field }) => (
                      <Input data-testid="signUpFirstName" {...field} />
                    )}
                  />
                </Form.Item>
                <Form.Item
                  label={t('signup_form_lastname-label')}
                  name="lastName"
                  validateStatus={errors.lastName && 'error'}
                  help={errors?.lastName && t(errors?.lastName?.message)}
                >
                  <Controller
                    control={control}
                    name={'lastName'}
                    render={({ field }) => (
                      <Input data-testid="signUpLastName" {...field} />
                    )}
                  />
                </Form.Item>
                <Form.Item
                  label={t('signup_form_password-label')}
                  name="password"
                  validateStatus={errors.password && 'error'}
                  help={errors?.password && t(errors?.password?.message)}
                >
                  <Controller
                    control={control}
                    name={'password'}
                    render={({ field }) => (
                      <Input.Password {...field} data-testid="signUpPassword" />
                    )}
                  />
                </Form.Item>
                {!!password && <PasswordValidation password={password} />}
                <Form.Item
                  label={t(
                    'settings_personal-section_form_password-confirm-label'
                  )}
                  name="confirmPassword"
                  validateStatus={errors?.confirmPassword && 'error'}
                  help={
                    errors?.confirmPassword &&
                    t(errors?.confirmPassword?.message)
                  }
                >
                  <Controller
                    control={control}
                    name={'confirmPassword'}
                    render={({ field }) => (
                      <Input.Password
                        data-testid="signUpConfirmPassword"
                        {...field}
                      />
                    )}
                  />
                </Form.Item>
                <Form.Item
                  valuePropName="checked"
                  name={'termsAndConditions'}
                  validateStatus={errors?.termsAndConditions && 'error'}
                  help={
                    errors?.termsAndConditions &&
                    t(errors?.termsAndConditions?.message)
                  }
                >
                  <Controller
                    control={control}
                    name={'termsAndConditions'}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        onChange={field.onChange}
                        data-testid="signUpTermsCheckbox"
                      >
                        {termsAndConditionsText ? (
                          <StyledText text={termsAndConditionsText} />
                        ) : (
                          <>
                            {t('signup_text_terms')}
                            <a
                              href={fgTermsAndConditionsUrl ?? userAgreementUrl}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {t('terms_amper_conditions')}
                            </a>{' '}
                            {t('and_text')}{' '}
                            <a
                              href={fgPrivacyPolicyUrl ?? privacyPolicyUrl}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {t('privacy_policy')}
                            </a>
                          </>
                        )}
                      </Checkbox>
                    )}
                  />
                </Form.Item>
                {!fanGroupData?.optInPlatformHidden && (
                  <Form.Item
                    valuePropName="checked"
                    name="allowDirectMarketingFromSeaters"
                  >
                    <Controller
                      control={control}
                      name={'allowDirectMarketingFromSeaters'}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          defaultChecked={
                            initialValues?.allowDirectMarketingFromSeaters
                          }
                          data-testid="signUpAllowSeatersMarketingCheckbox"
                        >
                          {platformText ? (
                            <StyledText text={platformText} />
                          ) : (
                            t('signup_text_optin_seaters', {
                              FN: fanGroupName ?? 'Seaters',
                            })
                          )}
                        </Checkbox>
                      )}
                    />
                  </Form.Item>
                )}
                {!fanGroupData?.optInPartnersHidden && (
                  <Form.Item
                    valuePropName="checked"
                    name="allowDirectMarketingFromPartners"
                  >
                    <Controller
                      control={control}
                      name={'allowDirectMarketingFromPartners'}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          defaultChecked={
                            initialValues?.allowDirectMarketingFromPartners
                          }
                          data-testid="signUpAllowPartnersMarketingCheckbox"
                        >
                          {partnersText ? (
                            <StyledText text={partnersText} />
                          ) : (
                            t('signup_text_optin_partners', {
                              FN: fanGroupName ?? 'Seaters',
                            })
                          )}
                        </Checkbox>
                      )}
                    />
                  </Form.Item>
                )}
                {rulesLinksUrls && (
                  <Form.Item
                    valuePropName="checked"
                    name="fanGroupRules"
                    validateStatus={errors?.fanGroupRules && 'error'}
                    help={
                      errors?.fanGroupRules && t(errors?.fanGroupRules?.message)
                    }
                  >
                    <Controller
                      control={control}
                      name={'fanGroupRules'}
                      render={({ field }) => (
                        <Checkbox data-testid="signUpFanGroupRules" {...field}>
                          {rulesText ? (
                            <StyledText text={rulesText} />
                          ) : (
                            <>
                              {t('sign_up_read_and_accept')}{' '}
                              <a
                                href={rulesLinksUrls}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {t('rules_label')}
                              </a>{' '}
                              {t('of_fangroup', { fanGroup: fanGroupName })}
                            </>
                          )}
                        </Checkbox>
                      )}
                    />
                  </Form.Item>
                )}
                <Form.Item>
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    style={{ width: '100%' }}
                    loading={isSignUpLoading}
                    data-testid="signUpSubmitButton"
                  >
                    {t('signin_form-register-btn')}
                  </Button>
                </Form.Item>
              </Form>
            </FormProvider>
          ) : null}
        </Col>
      </Row>
    </Card>
  );
}

export default SignUp;
