import { useFGOWishListSeats } from '@seaters-app/data-access';
import { usePagination } from '../../../../utils';
import { useParams } from 'react-router-dom';
import { TablePaginationConfig } from 'antd';
import { useState } from 'react';
import { SortOrder } from 'antd/es/table/interface';
import { SortDirectionMap } from '@seaters-app/constants';

type SortType = {
  name: string;
  order: SortOrder;
};

const defaultSort: SortType = {
  name: 'lastRSVP',
  order: 'descend',
};

export const useWishListSeats = () => {
  const { wishListId } = useParams();

  const [sort, setSort] = useState<SortType | null>(defaultSort);

  const [paginationParams, setPaginationParams] = useState<{
    pageSize: number;
    current: number;
    search: string | undefined;
    sort: string;
  }>({
    pageSize: 10,
    current: 0,
    search: undefined,
    sort: '',
  });

  const handleTableChange = ({ pageSize, current }: TablePaginationConfig) => {
    setPaginationParams({
      ...paginationParams,
      pageSize: pageSize || 10,
      current: current ? current - 1 : 0,
    });
  };

  const { data, isLoading, isFetching } = useFGOWishListSeats(
    wishListId || '',
    {
      ...paginationParams,
      page: paginationParams.current,
      size: paginationParams.pageSize,
      sort:
        sort && sort.order
          ? `${sort.name}:${SortDirectionMap[sort.order]}:nullsLast`
          : '',
    }
  );

  const pagination = usePagination({
    ...paginationParams,
    current: paginationParams.current + 1,
    totalSize: data?.totalElements,
  });

  const handleSort = (columnName: string): void => {
    if (columnName === sort?.name) {
      setSort({
        ...sort,
        order: sort.order === 'ascend' ? 'descend' : 'ascend',
      });
    } else {
      setSort({
        name: columnName,
        order: 'ascend',
      });
    }
  };

  return {
    seats: data,
    handleTableChange,
    pagination,
    isLoading,
    isFetching,
    queryParams: paginationParams,
    setPaginationParams: setPaginationParams,
    sort,
    handleSort,
  };
};
