import { EventStatus, AttendeeRequiredFieldsType, Language } from '../../enums';
import { z, ZodType } from 'zod';
import { TranslationValidatorSchema } from './translation.validator';
import dayjs, { Dayjs } from 'dayjs';
import {
  getTranslationValidation,
  getTranslationValidationWithLimit,
} from '../../helpers';

const EventFormValidatorDateSchema = z
  .object({
    startDate: z.instanceof(dayjs as unknown as typeof Dayjs, {
      message: 'Start date was not configured',
    }),
    endDate: z
      .instanceof(dayjs as unknown as typeof Dayjs)
      .optional()
      .nullable(),
  })
  .refine(
    (data) => {
      return data.endDate ? dayjs(data.endDate).isAfter(data.startDate) : true;
    },
    {
      message: 'event_error_invalid-end-date', // End date must be after the start date
      path: ['endDate'],
    }
  )
  .refine(
    (data) => {
      return data.startDate ? dayjs(data.startDate).isAfter(new Date()) : true;
    },
    {
      message: 'event_error_invalid-start-date', // Start date must not be in the past
      path: ['startDate'],
    }
  );

// required fields: name, shortName, description, startDate, slug, venueConfigId, status
export const EventFormValidatorSchema = z.intersection(
  EventFormValidatorDateSchema,
  z.object({
    status: z.nativeEnum(EventStatus),
    shortName: getTranslationValidation(),
    description: getTranslationValidation(),
    name: getTranslationValidation(),
    slug: z.string().min(1),
    venueConfigId: z.string().min(1, 'venue_config_error_message'),
    imageUrl: z.any().optional(),
    attendeeRequiredFields: z
      .array(z.nativeEnum(AttendeeRequiredFieldsType))
      .nullable()
      .optional(),
  })
);

// required fields: name, shortName, description, startDate, slug, venueConfigId, status

export const FGOEventFormValidatorSchema = z.intersection(
  EventFormValidatorDateSchema,
  z.object({
    shortName: getTranslationValidation(),
    description: getTranslationValidation(),

    ticketEvolutionId: z.string().min(1).optional(),
    attendeeRequiredFields: z
      .array(z.nativeEnum(AttendeeRequiredFieldsType))
      .optional(),
    imageFileId: z.any().optional(),
    venueConfigId: z.string().optional(),
    name: getTranslationValidationWithLimit(),
  })
);
// required fields: name, shortName, description, startDate, slug, venueConfigId, status
export const FGOEventValidatorSchema = z.object({
  shortName: z.array(
    z.object({ lang: z.nativeEnum(Language), text: z.string() })
  ),
  description: z.array(
    z.object({ lang: z.nativeEnum(Language), text: z.string() })
  ),
  startDate: z.string(),
  endDate: z.string().optional().nullable(),
  ticketEvolutionId: z.string().min(1).optional().nullable(),
  attendeeRequiredFields: z
    .array(z.nativeEnum(AttendeeRequiredFieldsType))
    .optional(),
  imageFileId: z.any().optional(),
  venueConfigId: z.string().optional(),
  name: z.array(z.object({ lang: z.nativeEnum(Language), text: z.string() })),
});

export const EventValidatorSchema = z.object({
  description: z.array(TranslationValidatorSchema),
  status: z.nativeEnum(EventStatus),
  shortName: z.array(TranslationValidatorSchema),
  slug: z.string(),
  startDate: z.string(),
  endDate: z.string().nullable(),
  ticketEvolutionId: z.string().optional(),
  venueConfigId: z.string(),
  name: z.array(TranslationValidatorSchema),
  attendeeRequiredFields: z
    .array(z.nativeEnum(AttendeeRequiredFieldsType))
    .optional()
    .nullable(),
});

export type EventValidator = z.infer<typeof EventValidatorSchema>;
export type FGOEventFormValidator = z.infer<typeof FGOEventFormValidatorSchema>;
export type FGOEventValidator = z.infer<typeof FGOEventValidatorSchema>;
