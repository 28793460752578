import React, { useCallback, useState, useEffect } from 'react';
import Form from 'antd/es/form';
import {
  AccessMode,
  FanGroupAccessFormType,
  FanGroupAccessFormValidatorSchema,
  fanGroupsKeys,
  Language,
  languagesOptions,
} from '@seaters-app/constants';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import styles from './access.module.css';
import { CSVLink } from 'react-csv';
import {
  Col,
  Input,
  Radio,
  Row,
  Segmented,
  Space,
  Switch,
  notification,
} from 'antd';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  useCreateFanGroupProtectionCode,
  useDeleteFanGroupProtectionCode,
  useFanGroupStore,
} from '@seaters-app/data-access';
import { useFanGroupProtectionCodes } from './hooks/useProtectionCodes';
import { useParams } from 'react-router-dom';
import { CodesTable } from './components/Codes';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';

import { useIsMutating } from '@tanstack/react-query';

import { formSteps, useCurrentStep } from '../create/CreateFanGroup';
import { FanGroupCreationFooter, Footer } from '@seaters-app/ui-shared';
import { ImportCodesModal } from './components/ImportCodesModal';
import { t } from 'i18next';
import { Button } from '@seaters-app/ui';
import { getErrorMessages } from 'apps/seaters/src/utils/helpers/getFormFieldsErrorMessages/getErrorMessages';

const { TextArea } = Input;

type FanGroupAccessFormPropsType = {
  fanGroup: FanGroupAccessFormType;
  onSubmit: (values: FanGroupAccessFormType) => void;
  isEdit?: boolean;
  setAccessStepValues?: (val: FanGroupAccessFormType) => void;
  isConfiguration?: boolean;
};

export function FanGroupAccessForm({
  fanGroup,
  onSubmit,
  setAccessStepValues,
  isConfiguration = false,
  isEdit = true,
}: FanGroupAccessFormPropsType) {
  const { prev } = useCurrentStep();

  const { fanGroup: FGOgroupData } = useFanGroupStore();

  const isSubmitting = !!useIsMutating(fanGroupsKeys.mutation());

  const { fanGroupId = '' } = useParams();
  const fgId = fanGroupId ? fanGroupId : FGOgroupData?.id ?? '';

  const {
    data: codesData,
    isLoading,
    handleTableChange,
    pagination,
    query,
    setQuery,
  } = useFanGroupProtectionCodes(fgId, isConfiguration);

  const { mutate: createCode, isLoading: isCodeCreating } =
    useCreateFanGroupProtectionCode(fgId);

  const { mutateAsync: deleteCode, isLoading: isCodeRemoving } =
    useDeleteFanGroupProtectionCode(fgId);

  const [code, setCode] = useState<string>('');
  const [maxTimesUse, setMaxTimesUse] = useState<number>(100);
  const [isImportCodesModal, setIsImportCodesModal] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState<Language>(Language.EN);

  const initialValues: FanGroupAccessFormType = {
    visibility: fanGroup?.visibility,
    accessMode: fanGroup?.accessMode,
    protectionCodeExplanation: fanGroup.protectionCodeExplanation,
  };

  const methods = useForm<FanGroupAccessFormType>({
    mode: 'onBlur',
    resolver: zodResolver(FanGroupAccessFormValidatorSchema),
    defaultValues: initialValues,
    values: initialValues,
  });

  const {
    handleSubmit,
    control,
    reset,
    trigger,
    getValues,
    formState: { errors, isValid },
  } = methods;

  const fieldErrors = getErrorMessages<FanGroupAccessFormType>(
    errors,
    ['protectionCodeExplanation'],
    currentLanguage
  );

  const visibility = useWatch({
    control,
    name: 'visibility',
  });

  const accessMode = useWatch({
    control,
    name: 'accessMode',
  });

  const generateCode = (code: string, maxTimesUse: number) => {
    createCode(
      { code: code, maxTimesUsed: maxTimesUse, timesUsed: 0 },
      {
        onSuccess: () => {
          notification.success({
            message: t('notification_success_code_generated'),
          });
        },
        onError: (err) => {
          console.error(err);
          notification.error({
            message: t('notification_error_code_generated'),
          });
        },
      }
    );
  };

  const handleDeleteCode = (code: string) =>
    deleteCode(code, {
      onSuccess: () => {
        notification.success({
          message: t('notification_success_code_removed'),
        });
      },
      onError: () => {
        notification.error({
          message: t('notification_error_code_removed'),
        });
      },
    });

  const copyCode = useCallback(
    (i: number) => {
      if (codesData) {
        navigator.clipboard.writeText(codesData?.content[i].code);
        notification.success({
          message: t('notification_success_code_copied'),
        });
      }
    },
    [codesData]
  );

  const handlePreviousStep = () => {
    if (setAccessStepValues) {
      setAccessStepValues({
        visibility: visibility,
        accessMode: getValues('accessMode'),
        protectionCodeExplanation: getValues('protectionCodeExplanation'),
      });
    }
    if (prev) {
      prev();
    }
  };

  useEffect(() => {
    trigger('protectionCodeExplanation');
  }, [accessMode]);

  return (
    <>
      <FormProvider {...methods}>
        <Form
          layout="vertical"
          onFinish={handleSubmit(onSubmit)}
          autoComplete="off"
          disabled={isSubmitting}
        >
          <Row gutter={24}>
            <Col>
              <Form.Item label="none" name="visibility">
                <Controller
                  control={control}
                  name={'visibility'}
                  render={({ field: { onChange, value } }) => (
                    <Switch
                      onChange={onChange}
                      checked={value}
                      defaultChecked={initialValues.visibility}
                    />
                  )}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="none">
                <span>
                  {t('fg_visibility_text', {
                    visibility: visibility
                      ? t('fgc_access_visibility-visible')
                      : t('fgc_access_visibility-invisible') ??
                        initialValues?.visibility,
                  })}
                </span>
              </Form.Item>
            </Col>
          </Row>
          <div>{t('fgc_access_visibility-warning')}</div>
          <Form.Item name="accessMode" label="none">
            <Controller
              control={control}
              name="accessMode"
              render={({ field }) => (
                <Radio.Group {...field}>
                  <Radio value={AccessMode.PUBLIC}>
                    {t('fgc_access_mode_public')}
                  </Radio>
                  <Radio value={AccessMode.PRIVATE}>
                    {t('fgc_access_mode_private')}
                  </Radio>
                  <Radio value={AccessMode.CODE_PROTECTED}>
                    {t('fgc_access_mode_code-protected')}
                  </Radio>
                </Radio.Group>
              )}
            />
          </Form.Item>
          <Col xs={24} md={24} lg={16} xl={12} xxl={12}>
            <Form.Item
              label={t('fgc_access_protection')}
              name={`protectionCodeExplanation.${currentLanguage}`}
              validateStatus={fieldErrors?.protectionCodeExplanation && 'error'}
              help={fieldErrors?.protectionCodeExplanation}
            >
              <Controller
                control={control}
                name={`protectionCodeExplanation.${currentLanguage}`}
                render={({ field }) => (
                  <TextArea
                    placeholder={t('type_here_placeholder_text')}
                    className={styles.explanation}
                    {...field}
                    style={{ marginBottom: 20 }}
                  />
                )}
              />
            </Form.Item>
            <Segmented
              size="middle"
              options={languagesOptions}
              value={currentLanguage}
              onChange={setCurrentLanguage}
              className={styles.languages}
            />
          </Col>
          {setAccessStepValues ? (
            <FanGroupCreationFooter
              isDisabled={!isValid}
              isLoading={false}
              handlePreviousStep={handlePreviousStep}
              currentStep={formSteps.indexOf('access')}
              formSteps={formSteps}
            ></FanGroupCreationFooter>
          ) : (
            <Footer onCancel={() => reset()} isDisabled={!isValid} />
          )}
        </Form>
        {isEdit && (
          <Form layout="vertical">
            <Row gutter={24}>
              <Col xs={24} md={10} lg={10} xl={8} xxl={6}>
                <Form.Item label={t('admin_currency_code')}>
                  <Input
                    onChange={(e) => setCode(e.target.value)}
                    placeholder={t('type_here_placeholder_text')}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={10} lg={10} xl={8} xxl={6}>
                <Form.Item
                  label={t('admin_fan-group-protection-codes_max-times-used')}
                >
                  <Input
                    type="number"
                    onChange={(e) => setMaxTimesUse(Number(e.target.value))}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label="hidden">
                  <Button
                    onClick={() => generateCode(code, maxTimesUse)}
                    loading={isCodeCreating}
                  >
                    {t('generate_button_text')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </FormProvider>
      {isEdit && (
        <>
          <Space>
            <Button
              style={{ marginBottom: 20 }}
              icon={<UploadOutlined rev={undefined} />}
              onClick={() => setIsImportCodesModal(true)}
            >
              {t('wl_prot-codes_import-title')}
            </Button>
            {!!codesData?.content.length && (
              <CSVLink
                data={codesData?.content}
                separator={';'}
                enclosingCharacter={``}
                filename={'protectionCodes.csv'}
              >
                <Button
                  style={{ marginBottom: 20 }}
                  icon={<DownloadOutlined rev={undefined} />}
                >
                  {t('export_protected_codes_button_text')}
                </Button>
              </CSVLink>
            )}
          </Space>
          <CodesTable
            query={query}
            setQuery={setQuery}
            codesData={codesData?.content}
            pagination={pagination}
            handleTableChange={handleTableChange}
            isLoading={isLoading}
            isRemoving={isCodeRemoving}
            copyCode={copyCode}
            handleDeleteCode={handleDeleteCode}
          />
          <ImportCodesModal
            open={isImportCodesModal}
            onClose={() => setIsImportCodesModal(false)}
          />
        </>
      )}
    </>
  );
}
