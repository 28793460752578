import {
  AttendeesInfo,
  AttendeesInfoValidator,
  endpoints,
  ListEntityV2,
  PaymentInfo,
  PositionTimeoutsValidator,
  SeatEntity,
  SortedPaginationQueryV2,
  UseUpdatePositionTimeOutsType,
  V2Prefix,
  WLPositionEntity,
} from '@seaters-app/constants';
import { api } from '../axios-instance';

// PUT URL: https://api.qa-seaters.com/api/v2/fan-group-owner/waiting-lists/1ede858e-3b0a-45d2-ae20-a17eeb2ed9f7/positions?page=0&size=10&sort=rank:DESC
export const fetchFGOWaitingListPositions = (
  waitingListId: string,
  params: SortedPaginationQueryV2
): Promise<ListEntityV2<WLPositionEntity>> =>
  api.put(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.positions}?page=${params.page}&size=${params.size}&sort=${params?.sort}`,
    { query: params.search }
  );

// PUT URL: https://api.qa-seaters.com/api/v2/fan-group-owner/waiting-lists/73e4fc18-1862-49ec-9f7a-f50ad43d15da/positions/27995d17-b067-48f1-b359-fffcd5195191/decline-seats-request
export const declineSeatsPosition = (
  waitingListId: string,
  positionId: string
): Promise<unknown> =>
  api.put(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.positions}/${positionId}/${endpoints.declineSeatsRequest}`
  );

// PUT URL: https://api.qa-seaters.com/api/fan-group-owner/waiting-lists/1ede858e-3b0a-45d2-ae20-a17eeb2ed9f7/positions/c758d066-2936-4c3b-9e7e-28f71133d169/mark-for-distribution
export const updateSeatsPosition = (
  waitingListId: string,
  positionId: string,
  nrOfSeats: number
): Promise<unknown> =>
  api.put(
    `${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.positions}/${positionId}/${endpoints.markForDistribution}`,
    { nrOfSeats }
  );

// PUT URL: https://api.qa-seaters.com/api/v2/fan-group-owner/waiting-lists/1ede858e-3b0a-45d2-ae20-a17eeb2ed9f7/positions/f89ad14b-978b-4552-bf97-d3a897833dd8/mark-for-parking-distribution
export const updateParkingPosition = (
  waitingListId: string,
  positionId: string,
  nbrOfParkingTickets: number
): Promise<unknown> =>
  api.put(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.positions}/${positionId}/${endpoints.markForParkingDistribution}`,
    { nbrOfParkingTickets }
  );
// PUT URL: https://api.qa-seaters.com/api/v2/fan-group-owner/waiting-lists/1ede858e-3b0a-45d2-ae20-a17eeb2ed9f7/positions/f89ad14b-978b-4552-bf97-d3a897833dd8/mark-for-parking-distribution
export const unmarkParkingPosition = (
  waitingListId: string,
  positionId: string
): Promise<unknown> =>
  api.put(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.positions}/${positionId}/${endpoints.unmarkForParkingDistribution}`
  );

// PUT URL: https://api.qa-seaters.com/api/v2/fan-group-owner/waiting-lists/1ede858e-3b0a-45d2-ae20-a17eeb2ed9f7/positions/86bf7106-fb8e-4df1-ab95-657ec9c9e103/assign-with-parking
export const assignPositionWithParking = (
  waitingListId: string,
  positionId: string
): Promise<{ nextDistributionNumber: number }> =>
  api.put(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.positions}/${positionId}/${endpoints.assignWithParking}`
  );

// PUT URL: https://api.qa-seaters.com/api/v2/fan-group-owner/waiting-lists/1ede858e-3b0a-45d2-ae20-a17eeb2ed9f7/positions/86bf7106-fb8e-4df1-ab95-657ec9c9e103/assign
export const assignPosition = (
  waitingListId: string,
  positionId: string
): Promise<{ nextDistributionNumber: number }> =>
  api.put(
    `${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.positions}/${positionId}/${endpoints.assign}`
  );

// PUT URL: https://api.qa-seaters.com/api/v2/fan-group-owner/waiting-lists/38d8e03f-8edb-44fa-bef6-42cfe4789ced/positions/ec8e3607-de31-4739-9b35-e8f0fb7331e9/assign-without-seats
export const assignPositionWithoutSeats = (
  waitingListId: string,
  positionId: string
): Promise<{ nextDistributionNumber: number }> =>
  api.put(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.positions}/${positionId}/${endpoints.assignWithoutSeats}`
  );

// PUT URL: https://api.qa-seaters.com/api/v2/fan-group-owner/waiting-lists/1ede858e-3b0a-45d2-ae20-a17eeb2ed9f7/seats?page=0&size=10&sort=rank:DESC
export const fetchFGOWaitingListSeats = (
  waitingListId: string,
  params: SortedPaginationQueryV2
): Promise<ListEntityV2<SeatEntity>> =>
  api.put(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.seats}?page=${params.page}&size=${params.size}&sort=${params?.sort}`,
    { query: params.search }
  );

// POST api/v2/fan-group-owner/waiting-lists/{listId}/fan/{fanId}/accept-seats
export const acceptFanSeatsAsFGO = (
  waitingListId: string,
  fanId: string
): Promise<unknown> =>
  api.post(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.fan}/${fanId}/${endpoints.acceptSeats}`
  );

// PUT URL: https://api.qa-seaters.com/api/v2/fan-group-owner/waiting-lists/1ede858e-3b0a-45d2-ae20-a17eeb2ed9f7/positions/af89b045-3f69-457c-b07e-a9c192818a9b
export const updatePositionTimeOuts = (
  waitingListId: string,
  fanId: UseUpdatePositionTimeOutsType['fanId'],
  body: PositionTimeoutsValidator
): Promise<unknown> =>
  api.put(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.positions}/${fanId}`,
    body
  );

// DELETE URL: https://api.qa-seaters.com/api/fan-group-owner/waiting-lists/1ede858e-3b0a-45d2-ae20-a17eeb2ed9f7/seats/b7385f65-db94-4cda-8fb3-fdaf0da0ef56
export const deleteFGOWishListSeat = (
  waitingListId: string,
  fanId: UseUpdatePositionTimeOutsType['fanId']
): Promise<unknown> =>
  api.delete(
    `${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.seats}/${fanId}`
  );

export const updateFanAttendeesInfo = (
  waitingListId: string,
  body: AttendeesInfoValidator
): Promise<AttendeesInfo> =>
  api.put(
    `${V2Prefix}/${endpoints.fan}/${endpoints.waitingLists}/${waitingListId}/${endpoints.position}/${endpoints.attendeesInfo}`,
    body
  );

// PUT https://api.qa-seaters.com/api/v2/fan-group-owner/waiting-lists/listId/position/fanId/attendees-info/
export const updateFanAttendeesInfoAsFGO = (
  waitingListId: string,
  fanId: string,
  body: AttendeesInfoValidator
): Promise<AttendeesInfo> =>
  api.put(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.position}/${fanId}/${endpoints.attendeesInfo}`,
    body
  );

//PUT v2/host/invitations/{id}/attendees-info

export const updateGuestAttendeesInfoAsHost = (
  invitationId: string,
  body: AttendeesInfoValidator
): Promise<AttendeesInfo> =>
  api.put(
    `${V2Prefix}/${endpoints.host}/${endpoints.invitations}/${invitationId}/${endpoints.attendeesInfo}`,
    body
  );

//api.qa-seaters.com/api/v2/fan-group-owner/waiting-lists/listId/positions/fanId/more-seats/nrOfSeats/
export const distributeMoreSeats = (
  waitingListId: string,
  fanId: string,
  nrOfSeats: number
): Promise<{ [smth: string]: string }> =>
  api.put(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.positions}/${fanId}/${endpoints.moreSeats}/${nrOfSeats}`
  );

// https://api.qa-seaters.com/api/v2/fan-group-owner/waiting-lists/152c156f-7154-4807-9265-0417b0265e73/positions/3367e925-738c-4dd1-bd92-82f68aeed278/give-seats
export const giveSeats = (
  waitingListId: string,
  fanId: string
): Promise<{ nextDistributionNumber: number }> =>
  api.put(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.positions}/${fanId}/${endpoints.giveSeats}`
  );

export const giveParking = (
  waitingListId: string,
  fanId: string,
  nrOfParkingTickets: number
): Promise<any> => api.put(`${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.positions}/${fanId}/${endpoints.giveParking}/${nrOfParkingTickets}`)

// https://api.qa-seaters.com/api/v2/fan-group-owner/waiting-lists/152c156f-7154-4807-9265-0417b0265e73/positions/3367e925-738c-4dd1-bd92-82f68aeed278/give-seats-parking
export const giveSeatsParking = (
  waitingListId: string,
  fanId: string
): Promise<{ nextDistributionNumber: number }> =>
  api.put(
    `${V2Prefix}/${endpoints.fanGroupOwner}/${endpoints.waitingLists}/${waitingListId}/${endpoints.positions}/${fanId}/${endpoints.giveSeatsParking}`
  );

// https://api.qa-seaters.com/api/fan/waiting-lists/327e9ce5-1e95-49b7-972f-ad4871e9a5a9/position/payment-info
export const fetchPaymentInfo = (waitingListId: string): Promise<PaymentInfo> =>
  api.get(
    `${endpoints.fan}/${endpoints.waitingLists}/${waitingListId}/${endpoints.position}/${endpoints.paymentInfo}`
  );
