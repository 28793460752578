import { z } from 'zod';
import { t } from 'i18next';
import { MailcampaignRecipient } from '../enums';

export const MailcampaignFormValidatorSchema = z.object({
  name: z.string().min(1, 'Required'),
  subject: z.string().nullish(),
  replyTo: z.string().email().nullish(),
  body: z.string().nullish(),
  recipient: z.nativeEnum(MailcampaignRecipient).nullish(),
  wishlist: z.string().nullish(),
  badge: z.string().nullish(),
});
export type MailcampaignFormValues = z.infer<
  typeof MailcampaignFormValidatorSchema
>;

export const MailcampaignRequiredFormSchema = z
  .object({
    name: z
      .string()
      .min(
        1,
        t('mailcampaigns_name_required')
          ? (t('mailcampaigns_name_required') as string)
          : 'Required'
      ),
    subject: z.string({
      message: t('mailcampaigns_subject_required')
        ? (t('mailcampaigns_subject_required') as string)
        : 'Required',
    }),
    replyTo: z
      .string({
        message: t('mailcampaigns_reply_to_required')
          ? (t('mailcampaigns_reply_to_required') as string)
          : 'Required',
      })
      .email({
        message: t('mailcampaigns_reply_to_invalid')
          ? (t('mailcampaigns_reply_to_invalid') as string)
          : 'Required',
      }),
    body: z.string({
      message: t('mailcampaigns_body_required')
        ? (t('mailcampaigns_body_required') as string)
        : 'Required',
    }),
    recipient: z.nativeEnum(MailcampaignRecipient, {
      message: t('mailcampaigns_recipient_required')
        ? (t('mailcampaigns_recipient_required') as string)
        : 'Required',
    }),
    wishlist: z.object({ id: z.string().nullish() }).nullish(),
  })
  .refine(
    (data) => {
      if (data.recipient === 'fangroup') {
        return true;
      }
      return !!data.wishlist?.id;
    },
    {
      message: t('mailcampaigns_wishlist_required')
        ? (t('mailcampaigns_wishlist_required') as string)
        : 'Required',
      path: ['wishlist'],
    }
  );

const createMailCampaignBodySchema = z.object({
  name: z.string(),
  fangroup: z.string(),
  subject: z.string().nullish(),
  replyTo: z.string().nullish(),
  body: z.string().nullish(),
  recipient: z.nativeEnum(MailcampaignRecipient).nullish(),
  wishlist: z.string().nullish(),
});

// Type inference if needed
export type CreateMailCampaignBody = z.infer<
  typeof createMailCampaignBodySchema
>;

export interface UpdateMailCampaignBody
  extends Omit<CreateMailCampaignBody, 'fangroup' | 'name'> {
  name?: string;
}
