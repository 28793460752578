import { useEffect, useState } from 'react';
import AntLayout, { LayoutProps } from 'antd/es/layout';
import { RightOutlined } from '@ant-design/icons';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  Avatar,
  Card,
  ConfigProvider,
  Popover,
  Grid,
  Typography,
  Space,
  Menu,
  Modal,
  Input,
} from 'antd';
import { useMenuItems } from './constants';
import styles from './layout.module.css';
import { ProfilePopup } from './components/ProfilePopup';
import {
  getSingleTranslation,
  queryClient,
  useAppUserStore,
  useFanGroupStore,
  useFetchFanGroupOwnerGroups,
} from '@seaters-app/data-access';
import { SettingOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
  LOCAL_STORAGE_SLUG,
  SESSION_STORAGE_JOB_ID,
  SESSION_STORAGE_TARGET_APP,
  SESSION_STORAGE_VERIFICATION,
  TranslationsTarget,
  UserRole,
  routes,
} from '@seaters-app/constants';
import { FanGroupsPopup } from './components/FanGroupsPopup';
import {
  Button,
  Logo,
  hexToRgb,
  primaryColor,
  baseColor100,
  baseColor600,
  baseColor800,
} from '@seaters-app/ui';
import Color from 'color';
import { useFGOGroup } from '../hooks/useFGOGroup';
import SupportActionsModal from '../../pages/supportActions/SupportActionsModal';
import { useAppTranslationsChange } from '../hooks';

const { Content, Sider } = AntLayout;
const { useBreakpoint } = Grid;

const { Text } = Typography;

export function AdminLayout(props: LayoutProps) {
  const { pathname } = useLocation();
  const pathNamePartToMatch = pathname?.split('/')[2];

  const { xs } = useBreakpoint();

  const { user, setUser } = useAppUserStore();
  const { t, i18n, ready } = useTranslation();
  const { language } = i18n;
  const lang = language === 'nl_NL' || language === 'nl_BE' ? 'nl' : language;
  const [isSettingsPopupOpen, setSettingsPopupOpen] = useState(false);
  const [isFanGroupsPopupOpen, setFanGroupsPopupOpen] = useState(false);
  const [isSupportModalClosed, setSupportModalClosed] = useState(false);
  const [showSupportModal, setShowSupportModal] = useState(
    pathNamePartToMatch !== routes.supportActions &&
      !!sessionStorage.getItem(SESSION_STORAGE_JOB_ID) &&
      !isSupportModalClosed
  );

  const { data: fanGroups } = useFetchFanGroupOwnerGroups({
    itemOffset: 0,
    maxPageSize: 100,
  });

  const { fanGroup, setFanGroup } = useFanGroupStore();

  useFGOGroup();

  const customColor = fanGroup?.color1
    ? Color(hexToRgb(`${fanGroup?.color1}`) ?? 'rgb(0, 0, 0)')
    : Color(hexToRgb(primaryColor) ?? 'rgb(0, 0, 0)');

  const customColorDarken = customColor.darken(0.9).toString();

  const [siderCollapsed, setSiderCollapsed] = useState(false);
  const toggleSider = () => {
    setSiderCollapsed(!siderCollapsed);
  };
  const navigate = useNavigate();

  const openSettingsPopup = () => {
    setSettingsPopupOpen(!isSettingsPopupOpen);
  };

  const openFanGroupsPopup = () => {
    setFanGroupsPopupOpen(!isFanGroupsPopupOpen);
  };

  const { changeTranslations } = useAppTranslationsChange();

  const isFanGroupOwner = user?.roles.includes(UserRole.FAN_GROUP_OWNER);

  const isAllowedToAdminInterface =
    user?.roles.includes(UserRole.ADMIN) ||
    user?.roles.includes(UserRole.FAN_GROUP_OWNER);

  const items = useMenuItems(
    getSingleTranslation(fanGroup?.name ?? []),
    user?.roles
  );

  useEffect(() => {
    const fgName =
      (fanGroup && getSingleTranslation(fanGroup.name, lang)) || 'Seaters';

    document.title = fgName;
    return () => {
      document.title = 'Seaters';
    };
  }, [fanGroup, lang]);

  useEffect(() => {
    setShowSupportModal(
      pathNamePartToMatch !== routes.supportActions &&
        !!sessionStorage.getItem(SESSION_STORAGE_JOB_ID) &&
        !isSupportModalClosed
    );
    if (pathNamePartToMatch === routes.supportActions && isSupportModalClosed) {
      setSupportModalClosed(false);
    }
  }, [pathNamePartToMatch, isSupportModalClosed]);

  const currentTarget = sessionStorage.getItem(SESSION_STORAGE_TARGET_APP);
  const [enteredSlug, setEnteredSlug] = useState(
    localStorage.getItem(LOCAL_STORAGE_SLUG)?.toString() ?? ''
  );

  const logout = () => {
    sessionStorage.removeItem(SESSION_STORAGE_VERIFICATION);
    sessionStorage.removeItem(SESSION_STORAGE_JOB_ID);
    localStorage.removeItem(LOCAL_STORAGE_SLUG);
    sessionStorage.removeItem(SESSION_STORAGE_TARGET_APP);
    setFanGroup(undefined);
    setUser(undefined);
    queryClient.clear();
    navigate(routes.login);
  };

  useEffect(() => {
    if (currentTarget !== TranslationsTarget.COCKPIT) {
      changeTranslations(
        SESSION_STORAGE_TARGET_APP,
        TranslationsTarget.COCKPIT
      );
    }
  }, [currentTarget]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Layout: {
            siderBg: customColorDarken,
          },
        },
      }}
    >
      <AntLayout style={{ minHeight: '100vh' }}>
        <Sider
          className={styles.sider}
          theme="dark"
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            toggleSider();
          }}
          trigger={xs ? <UnorderedListOutlined rev={undefined} /> : null}
          collapsed={xs ? siderCollapsed : false}
          collapsible
          width={255}
        >
          {ready && (
            <div className={styles.menubar}>
              <Space
                direction="vertical"
                style={{
                  overflow: 'auto',
                }}
              >
                <Logo inverted />
                <ConfigProvider
                  theme={{
                    token: {
                      controlItemBgActive: baseColor100,
                      colorBgBase: customColorDarken,
                      colorTextBase: baseColor100,
                    },
                    components: {
                      Menu: {
                        itemSelectedColor: customColorDarken,
                        itemHeight: 32,
                        groupTitleLineHeight: 1.2,
                      },
                    },
                  }}
                >
                  <Menu
                    onClick={(v) => {
                      navigate(v.key);
                    }}
                    defaultSelectedKeys={[pathNamePartToMatch]}
                    selectedKeys={[pathNamePartToMatch]}
                    style={{
                      border: 'none',
                      marginTop: 8,
                    }}
                    mode="inline"
                    items={items}
                  />
                </ConfigProvider>
              </Space>
              <Space size={12} direction="vertical">
                <Popover
                  content={<ProfilePopup setIsOpen={setSettingsPopupOpen} />}
                  trigger="click"
                  open={isSettingsPopupOpen}
                  onOpenChange={openSettingsPopup}
                  key="settingsPopup"
                >
                  <Space style={{ padding: 8 }}>
                    <Space
                      className={styles.user}
                      direction="vertical"
                      align="start"
                      size={0}
                    >
                      <Text style={{ color: baseColor100 }}>
                        {user?.firstName} {user?.lastName}
                      </Text>
                      {user && (
                        <Text type="secondary" style={{ color: baseColor600 }}>
                          {fanGroup
                            ? t('general_fgo_in_fangroup') +
                              getSingleTranslation(fanGroup.name)
                            : 'Admin in Seaters'}
                        </Text>
                      )}
                    </Space>
                    <Button size="large" color={baseColor100} type="text">
                      <SettingOutlined rev={undefined} />
                    </Button>
                  </Space>
                </Popover>
                {isFanGroupOwner && (
                  <Popover
                    placement="rightTop"
                    content={
                      fanGroups?.items?.length &&
                      fanGroups?.items?.length > 1 && (
                        <FanGroupsPopup
                          setIsOpen={setFanGroupsPopupOpen}
                          fanGroups={fanGroups?.items}
                        />
                      )
                    }
                    trigger="click"
                    open={isFanGroupsPopupOpen}
                    onOpenChange={openFanGroupsPopup}
                    key="fanGroupsPopup"
                  >
                    <Card
                      bordered
                      style={{
                        margin: '0 4px',
                        border: `1px solid ${baseColor100}`,
                        color: baseColor100,
                        backgroundColor: customColorDarken,
                        borderRadius: 8,
                      }}
                      size="small"
                    >
                      <div className={styles.fanGroupsButton}>
                        <div>
                          <Avatar
                            style={{ backgroundColor: baseColor100 }}
                            src={fanGroup?.logoImageUrl}
                            size="large"
                          />
                        </div>
                        <p className={styles.fanGroupName}>
                          {fanGroup?.name
                            ? getSingleTranslation(fanGroup?.name)
                            : fanGroup?.slug}
                        </p>
                        {Number(fanGroups?.totalSize) > 1 && (
                          <Button
                            size="large"
                            color={baseColor100}
                            icon={<RightOutlined rev={undefined} />}
                            type="text"
                          />
                        )}
                      </div>
                    </Card>
                  </Popover>
                )}
              </Space>
            </div>
          )}
        </Sider>

        <AntLayout
          style={{
            height: 'fit-content',
          }}
        >
          <Content className={styles.contentAdmin}>
            <div className={styles.outletContainer}>
              <Outlet />
            </div>
          </Content>
        </AntLayout>
      </AntLayout>
      {showSupportModal && (
        <SupportActionsModal setSupportModalClosed={setSupportModalClosed} />
      )}
      {!isAllowedToAdminInterface && !!user && (
        <Modal
          title={t('admin_interface_warning_modal_title')}
          open={!isAllowedToAdminInterface}
          footer={null}
        >
          <Space direction="vertical" size={12}>
            <Text>{t('admin_interface_login_warning')}</Text>

            <Input
              defaultValue={
                !!localStorage.getItem(LOCAL_STORAGE_SLUG)
                  ? localStorage.getItem(LOCAL_STORAGE_SLUG)?.toString()
                  : ''
              }
              placeholder={t('type_here_placeholder_text')}
              onChange={(v) => setEnteredSlug(v.target.value)}
            ></Input>
            <Space>
              <Button color={baseColor800} onClick={logout}>
                {t('settings_logout')}
              </Button>
              <Button
                type="primary"
                onClick={() => navigate(`../${enteredSlug}`)}
              >
                {`${t('main_topbar_go-to-btn')} ${t('admin_fan-group')}`}
              </Button>
            </Space>
          </Space>
        </Modal>
      )}
    </ConfigProvider>
  );
}

export default AdminLayout;
