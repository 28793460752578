import { UserRole } from '@seaters-app/constants';
import { create } from 'zustand';

interface RolesState {
  roles: UserRole[] | undefined;
  setRoles: (roles: UserRole[] | undefined) => void;
}

export const useRolesStore = create<RolesState>((set) => ({
  roles: undefined,
  setRoles: (roles: UserRole[] | undefined) => set(() => ({ roles: roles })),
}));
