import {
  MailcampaignEntity,
  endpoints,
  MailcampaignQuery,
  CountEntity,
  CreateMailCampaignBody,
  UpdateMailCampaignBody,
  PartialMailcampaignEntity,
} from '@seaters-app/constants';
import { api } from '../axios-instance';
import { Dayjs } from 'dayjs';

export const fetchMailcampaigns = (
  params: MailcampaignQuery
): Promise<CountEntity<PartialMailcampaignEntity>> =>
  api.get(endpoints.mailcampaigns, { params });

export const fetchMailcampaign = (
  id: string
): Promise<MailcampaignEntity> =>
  api.get(`${endpoints.mailcampaigns}/${id}`);

export const createMailcampaign = (
  body: CreateMailCampaignBody
): Promise<MailcampaignEntity> => api.post(endpoints.mailcampaigns, body);

export const updateMailcampaign = (
  id: string,
  body: UpdateMailCampaignBody
): Promise<MailcampaignEntity> => api.patch(`${endpoints.mailcampaigns}/${id}`, body);

export const sendMailcampaign = (
  id: string,
) => api.put(`${endpoints.mailcampaigns}/${id}/${endpoints.send}`);

export const scheduleMailcampaign = (
  id: string,
  scheduleDate: Dayjs
) => api.put(`${endpoints.mailcampaigns}/${id}/${endpoints.schedule}`, { scheduleTime: scheduleDate });

export const updateScheduleMailcampaign = (
  id: string,
  scheduleDate: Dayjs
) => api.patch(`${endpoints.mailcampaigns}/${id}/${endpoints.schedule}`, { scheduleTime: scheduleDate });

export const cancelScheduleMailcampaign = (
  id: string,
) => api.patch(`${endpoints.mailcampaigns}/${id}/${endpoints.schedule}/${endpoints.cancel}`);

export const deleteMailcampaign = (
  id: string
) => api.delete(`${endpoints.mailcampaigns}/${id}`);