import { Space, Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import {
  FanGroupCustomInformation,
  userLanguagesOptions,
} from '@seaters-app/constants';

export function ContactForm({
  fgCustomInfo,
}: {
  fgCustomInfo?: FanGroupCustomInformation[];
}) {
  const { t } = useTranslation();

  const methods = useFormContext(); // retrieve those props

  const {
    control,
    formState: { errors },
  } = methods;

  return (
    <>
      <Form.Item
        label={t('input_label_first_name')}
        name="firstName"
        validateStatus={errors.firstName && 'error'}
        required
      >
        <Controller
          control={control}
          name={'firstName'}
          render={({ field }) => <Input {...field} />}
        />
      </Form.Item>
      <Form.Item
        label={t('input_label_last_name')}
        name="lastName"
        validateStatus={errors.lastName && 'error'}
        required
      >
        <Controller
          control={control}
          name={'lastName'}
          render={({ field }) => <Input {...field} />}
        />
      </Form.Item>
      <Form.Item
        label={t('input_label_email')}
        name="guestEmail"
        validateStatus={errors.guestEmail && 'error'}
        required
      >
        <Controller
          control={control}
          name={'guestEmail'}
          render={({ field }) => <Input {...field} />}
        />
      </Form.Item>
      <Form.Item
        label={t('checkout_att_field_language')}
        name="locale"
        validateStatus={errors.locale && 'error'}
        required
      >
        <Controller
          control={control}
          name={'locale'}
          render={({ field }) => (
            <Select options={userLanguagesOptions} {...field} /> // TODO: check
          )}
        />
      </Form.Item>
      <Space style={{ width: '100%' }}>
        <Form.Item
          label={`${t('settings_phone_form_country_calling_code-label')} ${t(
            'settings_phone_form_country_calling_code-ex'
          )}`}
          name="mobilePhoneNumber.countryCallingCode"
        >
          <Controller
            control={control}
            name="mobilePhoneNumber.countryCallingCode"
            render={({ field }) => <Input {...field} />}
          />
        </Form.Item>
        <Form.Item
          label={t('settings_phone_form_phone-label')}
          name="mobilePhoneNumber.localNumber"
        >
          <Controller
            control={control}
            name="mobilePhoneNumber.localNumber"
            render={({ field }) => <Input {...field} />}
          />
        </Form.Item>
      </Space>
      <Form.Item
        label={t('guestlist_company')}
        name="company"
        validateStatus={errors.company && 'error'}
        required={
          fgCustomInfo?.find((item) => item.semanticName === 'company')
            ?.mandatory
        }
      >
        <Controller
          control={control}
          name="company"
          render={({ field }) => <Input {...field} />}
        />
      </Form.Item>
      <Form.Item
        label={t('guestlist_job_title')}
        name="jobtitle"
        validateStatus={errors.jobtitle && 'error'}
        required={
          fgCustomInfo?.find((item) => item.semanticName === 'jobtitle')
            ?.mandatory
        }
      >
        <Controller
          control={control}
          name="jobtitle"
          render={({ field }) => <Input {...field} />}
        />
      </Form.Item>
    </>
  );
}
