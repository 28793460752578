import {
  Space,
  Row,
  Col,
  Card,
  Alert,
  Upload,
  UploadProps,
  Form,
  Input,
  notification,
} from 'antd';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Button } from '@seaters-app/ui';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Modal from 'antd/es/modal/Modal';
import { useEffect, useState } from 'react';
import Table from 'antd/es/table';
import {
  groupsKeys,
  GuestFormValidator,
  GuestFormValidatorSchema,
} from '@seaters-app/constants';
import {
  queryClient,
  setNestedObject,
  useCreateGuest,
} from '@seaters-app/data-access';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useIsMutating } from '@tanstack/react-query';
import { useImportContactsStore } from '@seaters-app/data-access/storage/import-contacts-store';

enum ContactPropertiesEnum {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  MAIL = 'guestEmail',
  COUNTRY_CODE = 'mobilePhoneNumber.countryCallingCode',
  PHONE = 'mobilePhoneNumber.localNumber',
  COMPANY = 'company',
  JOB_TITLE = 'jobtitle',
  LANGUAGE = 'locale',
}

const example = {
  [ContactPropertiesEnum.FIRST_NAME]: 'John',
  [ContactPropertiesEnum.LAST_NAME]: 'Doe',
  [ContactPropertiesEnum.MAIL]: 'john.doe@gmail.com',
  [ContactPropertiesEnum.COUNTRY_CODE]: '+32',
  [ContactPropertiesEnum.PHONE]: '334455666',
  [ContactPropertiesEnum.COMPANY]: 'Company name',
  [ContactPropertiesEnum.JOB_TITLE]: 'Manager',
  [ContactPropertiesEnum.LANGUAGE]: 'nl_NL',
};

const contactProperties = (
  Object.keys(ContactPropertiesEnum) as (keyof typeof ContactPropertiesEnum)[]
).map((key) => {
  return ContactPropertiesEnum[key];
});

type TableDataType = GuestFormValidator & { key: number };

interface ImportContactModalType {
  open: boolean;
  onClose: () => void;
  groupId?: string;
}

const UploadContactsFormValidatorSchema = z.object({
  guests: z.array(GuestFormValidatorSchema),
});

export function ImportContactModal({
  groupId,
  ...props
}: ImportContactModalType) {
  const { t } = useTranslation();

  const isMutating = !!useIsMutating();

  const [tableModalIsOpen, setTableModalIsOpen] = useState(false);

  const {
    error: mutationErrors,
    success: mutationSuccess,
    errorGuests,
    reset,
  } = useImportContactsStore();

  const [dataSource, setDataSource] = useState<TableDataType[]>([]);

  const methods = useForm<{ guests: TableDataType[] }>({
    mode: 'onBlur',
    resolver: zodResolver(UploadContactsFormValidatorSchema),

    defaultValues: { guests: dataSource },
    values: { guests: dataSource },
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = methods;

  const disabled = isMutating || isSubmitting;

  const { mutateAsync: createGuest } = useCreateGuest(groupId, 'multiple');

  const handleTemplateDownload = () => {
    let csvContent = 'data:text/csv;charset=utf-8,';

    csvContent += contactProperties.join(';') + '\n';
    csvContent += Object.values(example).join(';') + '\n';
    const encodedUri = encodeURI(csvContent);
    const downloadLink = document.createElement('a');
    downloadLink.href = encodedUri;
    downloadLink.download = 'data.csv';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const uploadProps: UploadProps = {
    accept: '.csv',
    showUploadList: false,

    onChange(info) {
      if (info.file.status !== 'uploading') {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const csvRows = (e.target?.result as string)
            .replace(/\r/, '')
            .split('\n');

          const cleanedUpRows = csvRows
            .filter((row) => row.length)
            .map((row) => row.replace(/\r?\n|\r/, ''))
            .slice(1);

          const data: TableDataType[] = cleanedUpRows.map((row, rowIndex) => {
            const splittedRow = row.split(';');

            const mappedObject: any = {};

            splittedRow.forEach((value, index) => {
              setNestedObject(mappedObject, contactProperties[index], value);
              mappedObject.key = rowIndex;
            });

            return mappedObject;
          });

          setDataSource(data);

          setTableModalIsOpen(true);
          props.onClose();
        };
        reader.readAsText(info.file.originFileObj as Blob);
      }
    },
  };

  useEffect(() => {
    if (!disabled) {
      if (mutationErrors) {
        notification.error({
          message: `${t(
            'notification_contact_created_error_other'
          )}: ${mutationErrors} (${errorGuests.join(', ')})`,
        });
      }

      if (mutationSuccess)
        notification.success({
          message: `${t(
            'notification_contact_created_success_other'
          )}: ${mutationSuccess}`,
        });
      reset();
    }
  }, [mutationErrors, mutationSuccess, disabled]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  const onSubmit = async ({ guests }: { guests: TableDataType[] }) => {
    const contactsToCreate: TableDataType[] = guests;

    if (mutationSuccess || mutationErrors) {
      reset();
    }

    contactsToCreate.map((contact) => {
      const parsed = GuestFormValidatorSchema.safeParse(contact);

      if (!parsed.success) {
        console.log(parsed.error);
        return;
      }

      if (
        !parsed.data.mobilePhoneNumber?.countryCallingCode?.length ||
        !parsed.data.mobilePhoneNumber?.localNumber?.length
      ) {
        parsed.data.mobilePhoneNumber = null;
      }

      createGuest(parsed.data, {
        onSettled: () => {
          setTableModalIsOpen(false);
          queryClient.invalidateQueries(groupsKeys.guests(groupId));
          props.onClose();
        },
      });
    });

    reset();
  };

  return (
    <>
      <Modal
        {...props}
        onCancel={props.onClose}
        title={t('guestlist_import_guestlist')}
        footer={null}
      >
        <Space direction="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <Card style={{ height: '100%' }}>
                <Space
                  direction="vertical"
                  align="center"
                  style={{ textAlign: 'center' }}
                >
                  <Button
                    icon={<DownloadOutlined />}
                    onClick={handleTemplateDownload}
                    size="large"
                  />
                  {t('guestlist_get_template')}
                </Space>
              </Card>
            </Col>
            <Col span={12}>
              <Card style={{ height: '100%' }}>
                <Space
                  direction="vertical"
                  align="center"
                  style={{ textAlign: 'center' }}
                >
                  <Upload {...uploadProps}>
                    <Button
                      type="primary"
                      icon={<UploadOutlined />}
                      size="large"
                    />
                  </Upload>
                  {t('guestlist_import_csv')}
                </Space>
              </Card>
            </Col>
          </Row>

          <Alert
            message={t('alert_message_import_guestlist')}
            type="warning"
            showIcon
          />
        </Space>
      </Modal>

      <Modal
        title={t('guestlist_import_review')}
        open={tableModalIsOpen}
        // TODO fix types
        onOk={handleSubmit(onSubmit)}
        cancelButtonProps={{
          disabled,
        }}
        okButtonProps={{
          loading: disabled,
          htmlType: 'submit',
        }}
        onCancel={async () => {
          props.onClose();
          setTableModalIsOpen(false);
        }}
        width="100%"
      >
        <Form component={false} initialValues={dataSource} disabled={disabled}>
          <Table
            size="small"
            columns={[
              {
                title: '#',
                dataIndex: 'key',
                width: 40,
                render: (key) => key + 1,
              },
              {
                title: t('input_label_first_name'),
                dataIndex: 'firstName',
                width: '15%',
                sorter: true,

                render: (_, record, index: number) => {
                  return (
                    <Form.Item
                      name={`guests[${record.key}].firstName`}
                      style={{ margin: 0 }}
                      validateStatus={
                        errors.guests &&
                        errors.guests[record.key] &&
                        errors.guests[record.key]?.firstName &&
                        'error'
                      }
                      help={
                        errors.guests &&
                        errors.guests[record.key] &&
                        errors.guests[record.key]?.firstName?.message
                      }
                    >
                      <Controller
                        control={control}
                        name={`guests[${record.key}].firstName`}
                        render={({ field }) => <Input {...field} />}
                      />
                    </Form.Item>
                  );
                },
              },
              {
                title: t('input_label_last_name'),
                dataIndex: 'lastName',
                sorter: true,

                width: '15%',

                render: (_, record, index: number) => {
                  return (
                    <Form.Item
                      name={`guests[${record.key}].lastName`}
                      style={{ margin: 0 }}
                      validateStatus={
                        errors.guests &&
                        errors.guests[record.key] &&
                        errors.guests[record.key]?.lastName &&
                        'error'
                      }
                      help={
                        errors.guests &&
                        errors.guests[record.key] &&
                        errors.guests[record.key]?.lastName?.message
                      }
                    >
                      <Controller
                        control={control}
                        name={`guests[${record.key}].lastName`}
                        render={({ field }) => <Input {...field} />}
                      />
                    </Form.Item>
                  );
                },
              },
              {
                title: t('email'),
                dataIndex: 'guestEmail',
                key: 'guestEmail',
                sorter: true,

                render: (_, record) => {
                  return (
                    <Form.Item
                      name={`guests[${record.key}].guestEmail`}
                      style={{ margin: 0 }}
                      validateStatus={
                        errors.guests &&
                        errors.guests[record.key] &&
                        errors.guests[record.key]?.guestEmail &&
                        'error'
                      }
                      help={
                        errors.guests &&
                        errors.guests[record.key] &&
                        errors.guests[record.key]?.guestEmail?.message
                      }
                    >
                      <Controller
                        control={control}
                        name={`guests[${record.key}].guestEmail`}
                        render={({ field }) => <Input {...field} />}
                      />
                    </Form.Item>
                  );
                },
              },
              {
                title: t('guestlist_company'),
                dataIndex: 'company',
                width: '10%',
                sorter: true,

                render: (_, record, index: number) => {
                  return (
                    <Form.Item
                      name={`guests[${record.key}].company`}
                      style={{ margin: 0 }}
                      validateStatus={
                        errors.guests &&
                        errors.guests[record.key] &&
                        errors.guests[record.key]?.company &&
                        'error'
                      }
                      help={
                        errors.guests &&
                        errors.guests[record.key] &&
                        errors.guests[record.key]?.company?.message
                      }
                    >
                      <Controller
                        control={control}
                        name={`guests[${record.key}].company`}
                        render={({ field }) => <Input {...field} />}
                      />
                    </Form.Item>
                  );
                },
              },
              {
                title: t('guestlist_job_title'),
                dataIndex: 'jobtitle',
                width: '10%',

                render: (_, record, index: number) => {
                  return (
                    <Form.Item
                      name={`guests[${record.key}].jobtitle`}
                      style={{ margin: 0 }}
                      validateStatus={
                        errors.guests &&
                        errors.guests[record.key] &&
                        errors.guests[record.key]?.jobtitle &&
                        'error'
                      }
                      help={
                        errors.guests &&
                        errors.guests[record.key] &&
                        errors.guests[record.key]?.jobtitle?.message
                      }
                    >
                      <Controller
                        control={control}
                        name={`guests[${record.key}].jobtitle`}
                        render={({ field }) => <Input {...field} />}
                      />
                    </Form.Item>
                  );
                },
              },
              {
                title: t('settings_phone_form_country_calling_code-with-ex'),
                dataIndex: ['mobilePhoneNumber', 'countryCallingCode'],
                width: '12%',

                render: (_, record, index: number) => {
                  return (
                    <Form.Item
                      name={`guests[${record.key}].mobilePhoneNumber.countryCallingCode`}
                      style={{ margin: 0 }}
                      validateStatus={
                        errors.guests &&
                        errors.guests[record.key] &&
                        errors.guests[record.key]?.mobilePhoneNumber &&
                        errors.guests[record.key]?.mobilePhoneNumber
                          .countryCallingCode &&
                        'error'
                      }
                      help={
                        errors.guests &&
                        errors.guests[record.key] &&
                        errors.guests[record.key]?.mobilePhoneNumber &&
                        errors.guests[record.key]?.mobilePhoneNumber
                          .countryCallingCode?.message
                      }
                    >
                      <Controller
                        control={control}
                        name={`guests[${record.key}].mobilePhoneNumber.countryCallingCode`}
                        render={({ field }) => <Input {...field} />}
                      />
                    </Form.Item>
                  );
                },
              },
              {
                title: t('phoneNumber'),
                dataIndex: ['mobilePhoneNumber', 'localNumber'],
                width: '10%',

                render: (_, record, index: number) => {
                  return (
                    <Form.Item
                      name={`guests[${record.key}].mobilePhoneNumber.localNumber`}
                      style={{ margin: 0 }}
                      validateStatus={
                        errors.guests &&
                        errors.guests[record.key] &&
                        errors.guests[record.key]?.mobilePhoneNumber &&
                        errors.guests[record.key]?.mobilePhoneNumber
                          .localNumber &&
                        'error'
                      }
                      help={
                        errors.guests &&
                        errors.guests[record.key] &&
                        errors.guests[record.key]?.mobilePhoneNumber &&
                        errors.guests[record.key]?.mobilePhoneNumber.localNumber
                          ?.message
                      }
                    >
                      <Controller
                        control={control}
                        name={`guests[${record.key}].mobilePhoneNumber.localNumber`}
                        render={({ field }) => <Input {...field} />}
                      />
                    </Form.Item>
                  );
                },
              },
              {
                title: t('checkout_att_field_language'),
                dataIndex: 'locale',
                width: '5%',

                render: (_, record, index: number) => {
                  return (
                    <Form.Item
                      name={`guests[${record.key}].locale`}
                      style={{ margin: 0 }}
                      validateStatus={
                        errors.guests &&
                        errors.guests[record.key] &&
                        errors.guests[record.key]?.locale &&
                        'error'
                      }
                      help={
                        errors.guests &&
                        errors.guests[record.key] &&
                        errors.guests[record.key]?.locale?.message
                      }
                    >
                      <Controller
                        control={control}
                        name={`guests[${record.key}].locale`}
                        render={({ field }) => <Input {...field} />}
                      />
                    </Form.Item>
                  );
                },
              },
            ]}
            dataSource={dataSource}
            scroll={{
              x: true,
            }}
            pagination={{
              position: ['bottomLeft'],
              size: 'default',
            }}
          />
        </Form>
      </Modal>
    </>
  );
}
