import {
  endpoints,
  ListEntity,
  EventEntity,
  EventsQuery,
  EventValidator,
  SearchEventRequestBody,
  FanGroupOwnerEventEntity,
  seatersAdminPrefix,
  FGOEventValidator,
  AdminEventEntity,
  PaginationQueryV2,
  V2Prefix,
  PaginationQuery,
  WaitingListEntity,
} from '@seaters-app/constants';
import { api } from '../axios-instance';

export const fetchEvents = (
  params: EventsQuery
): Promise<ListEntity<EventEntity>> =>
  api.get(`${seatersAdminPrefix}/${endpoints.events}`, { params });

export const fetchEvent = (id: string): Promise<EventEntity> =>
  api.get(`${seatersAdminPrefix}/${endpoints.events}/${id}`);

export const fetchEventAsFGO = (id: string): Promise<EventEntity> =>
  api.get(`${endpoints.fanGroupOwner}/${endpoints.events}/${id}`);

export const createEvent = (body: EventValidator): Promise<EventEntity> =>
  api.post(`${seatersAdminPrefix}/${endpoints.events}`, body);

export const createFGOEvent = (
  body: FGOEventValidator
): Promise<FanGroupOwnerEventEntity> =>
  api.post(`${endpoints.fanGroupOwner}/${endpoints.events}`, body);

export const updateEvent = (
  id: string,
  body: EventValidator
): Promise<EventEntity> =>
  api.put(`${seatersAdminPrefix}/${endpoints.events}/${id}`, body);

export const deleteEvent = (eventId: string) =>
  api.delete(`${seatersAdminPrefix}/${endpoints.events}/${eventId}`);

export const searchFanGroupOwnerEventsList = (
  body: SearchEventRequestBody,
  params: EventsQuery
): Promise<ListEntity<FanGroupOwnerEventEntity>> =>
  api.put(`${endpoints.fanGroupOwner}/${endpoints.searchEvent}`, body, {
    params,
  });

export const searchAdminEventsList = (
  body: SearchEventRequestBody,
  params: PaginationQueryV2
): Promise<ListEntity<AdminEventEntity>> =>
  api.put(
    `${V2Prefix}/${endpoints.seatersAdmin}/${endpoints.events}/${endpoints.search}`,
    body,
    {
      params,
    }
  );

export const fetchAdminEventWLs = (
  id: string,
  params: PaginationQuery
): Promise<ListEntity<WaitingListEntity>> =>
  api.get(
    `${endpoints.seatersAdmin}/${endpoints.events}/${id}/${endpoints.waitingLists}`,
    { params }
  );
