import {
  getObjectTranslation,
  getSlugFromUrl,
  queryClient,
  useCreateFGOTransaction,
  useCreateTransaction,
  useDeleteTransaction,
  usePaymentInfo,
} from '@seaters-app/data-access';
import { PurchaseSummary } from '../PurchaseSummary';
import { StepItemContentProps } from './types';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Flex, Result, Space, notification } from 'antd';
import { Button } from '@seaters-app/ui';
import {
  LOCAL_STORAGE_STRIPE_DEEP_LINK,
  SEATERS_APP_BASE_URL,
  TransactionEntity,
  waitingListsKeys,
} from '@seaters-app/constants';
import { EuroCircleOutlined } from '@ant-design/icons';

export function PaymentStep({ selectedFanId, ...props }: StepItemContentProps) {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const lang = language === 'nl_NL' || language === 'nl_BE' ? 'nl' : language;
  const slug = getSlugFromUrl();
  const { waitingListId = '', wishListId = '' } = useParams();

  const wlId = waitingListId || wishListId;

  const { data: paymentInfo } = usePaymentInfo(wlId);

  const transaction = queryClient.getQueryData<TransactionEntity>(
    waitingListsKeys.transaction(wlId)
  );
  const stripeDeepLink = localStorage.getItem(LOCAL_STORAGE_STRIPE_DEEP_LINK);
  const { mutate: createTransaction, isLoading } = useCreateTransaction(wlId);

  const { mutate: deleteTransaction } = useDeleteTransaction();

  const { mutate: createFGOTransaction } = useCreateFGOTransaction(
    wlId,
    selectedFanId ?? ''
  );

  const createTransactionFunc = (options: any) => {
    createTransaction(options, {
      onSuccess: (res: { approvalUrl: string }) => {
        if (res?.approvalUrl) {
          window.open(res.approvalUrl, '_self');
        }
      },
      onError: ({ response }) => {
        notification.error({
          message: t('notification_error_transaction_creation'),
          description: response?.data?.message,
        });
      },
    });
  };

  const payStripe = async () => {
    const options = {
      stripePayment: {
        baseReturnUrl:
          stripeDeepLink && slug && slug.includes('kpn')
            ? decodeURIComponent(stripeDeepLink).replaceAll(
                '{webviewUrl}',
                `${SEATERS_APP_BASE_URL}${slug}/waitinglist/${wlId}`
              )
            : `${SEATERS_APP_BASE_URL}${slug}/waitinglist/${wlId}`,
      },
    };
    if (transaction?.approvalUrl && transaction?.status === 'PENDING') {
      window.open(transaction.approvalUrl, '_self');
    } else {
      if (!transaction) {
        createTransactionFunc(options);
      } else if (transaction?.status === 'FAILURE') {
        deleteTransaction(
          { wlId },
          {
            onSuccess: async () => {
              queryClient.invalidateQueries(waitingListsKeys.transaction(wlId));
              createTransactionFunc(options);
            },
            onError: (error) => {
              notification.error({
                message: t('notification_error_transaction_deletion'),
                description: error?.response?.data?.message,
              });
            },
          }
        );
      }
    }
  };

  const payVirtual = () => {
    const options = {
      seatersPayment: {
        paymentMethod: 'VIRTUAL',
      },
    };
    if (selectedFanId) {
      createFGOTransaction(options, {
        onSuccess: () => {
          if (!!props.next) props.next();
        },
        onError: ({ response }) => {
          if (
            response?.data?.message ===
            'api_cannot_create_sales_transaction_for_fan_in_paid_wl'
          ) {
            if (!!props.next) props.next();
          } else {
            notification.error({
              message: t('notification_error_transation_creation'),
              description: response?.data?.message,
            });
          }
        },
      });
    } else {
      createTransaction(options, {
        onSuccess: () => {
          if (!!props.next) props.next();
        },
        onError: ({ response }) => {
          notification.error({
            message: t('notification_error_transation_creation'),
            description: response?.data?.message,
          });
        },
      });
    }
  };

  return (
    <Flex justify="space-around">
      {!!paymentInfo?.seatersConfig && (
        <Result
          title={getObjectTranslation(
            paymentInfo?.seatersConfig?.virtualTitle,
            lang
          )}
          subTitle={getObjectTranslation(
            paymentInfo?.seatersConfig?.virtualDesc,
            lang
          )}
          extra={[
            <Link
              target="_blank"
              to={
                getObjectTranslation(
                  paymentInfo?.seatersConfig?.virtualTcLink,
                  lang
                ) || ''
              }
            >
              <Button type="text" key="terms_and_conditions">
                {t('waitinglist_terms_and_conditions')}
              </Button>
            </Link>,
            <Button type="primary" key="confirm" onClick={payVirtual}>
              {t('general_confirm-btn')}
            </Button>,
          ]}
        />
      )}
      <Space direction="vertical">
        <PurchaseSummary />
        {paymentInfo?.stripeConfig && (
          <Space
            style={{ width: '100%', justifyContent: 'space-between' }}
            direction="vertical"
          >
            <Button size="large" onClick={props.prev} block>
              {t('general_cancel-btn')}
            </Button>
            <Button
              block
              icon={<EuroCircleOutlined rev={undefined} />}
              type="primary"
              key="confirm"
              size="large"
              loading={isLoading}
              onClick={payStripe}
            >
              {t('general_confirm-btn')}
            </Button>
          </Space>
        )}
      </Space>
    </Flex>
  );
}
