import {
  Avatar,
  ConfigProvider,
  Menu,
  Segmented,
  Spin,
  Typography,
  theme,
  Alert,
  Space,
} from 'antd';
import {
  LOCAL_STORAGE_SLUG,
  SESSION_STORAGE_TARGET_APP,
  LookEntity,
  SESSION_STORAGE_MODE,
  TranslationsTarget,
  UserRole,
  routes,
  profileKeys,
  FanEntity,
  SESSION_STORAGE_ALLOWED_GROUPS,
  FanGroupEntity,
} from '@seaters-app/constants';
import Color from 'color';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import styles from './profilePopup.module.css';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';

import {
  getSingleTranslation,
  getSlugFromUrl,
  queryClient,
  useAppThemeStore,
  useAppUserStore,
  useFanGroupStore,
  useFetchJoinedFanGroups,
  useFetchUserRolesForCurrentFG,
} from '@seaters-app/data-access';
import { useTranslation } from 'react-i18next';
import {
  Button,
  CustomDivider,
  MoonIcon,
  SunIcon,
  baseColor900,
  getColorContrast,
  hexToRgb,
  primaryColor,
} from '@seaters-app/ui';
import { useAppTranslationsChange } from '../../hooks';
import { useTranslatedProperty } from '../../../pages/public/helpers';
import { useLogout } from '../../helpers/logout';
import { TermsAndConditionsModal } from 'apps/seaters/src/pages/waiting-lists/components/TermsAndConditonsModal/TermsAndConditionsModal';

const { Title } = Typography;

export const ProfilePopup = ({
  setIsOpen,
}: {
  setIsOpen: (v: boolean) => void;
}) => {
  const { logout } = useLogout();
  const slug = getSlugFromUrl();

  const allowedGroups = sessionStorage
    .getItem(SESSION_STORAGE_ALLOWED_GROUPS)
    ?.split(',')
    .map((fg) => fg.trim());

  const { mode, switch: switchTheme } = useAppThemeStore();
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const lang = language === 'nl_NL' || language === 'nl_BE' ? 'nl' : language;
  const { user } = useAppUserStore();
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const { fanGroup } = useFanGroupStore();

  const fanGroupData: LookEntity | undefined = queryClient.getQueryData([
    'users',
    'look',
  ]);

  const { data: fanGroupRoles, isLoading: userRolesIsLoading } =
    useFetchUserRolesForCurrentFG(fanGroupData?.fanGroupId ?? '');

  const { data: joinedFanGroups, isLoading } = useFetchJoinedFanGroups(
    {
      itemOffset: 0,
      maxPageSize: 100,
    },
    !!slug
  );

  let allowedJoinedGroups: FanGroupEntity[] | undefined = [];

  if (!!allowedGroups) {
    allowedJoinedGroups = joinedFanGroups?.items.filter((fg) =>
      allowedGroups.includes(fg.slug)
    );
  }
  const { changeTranslations } = useAppTranslationsChange();

  const faqLinks = useTranslatedProperty(fanGroupData?.faqLinks);
  const tacLinks = useTranslatedProperty(fanGroupData?.termsAndConditionsUrls);
  const ppLinks = useTranslatedProperty(fanGroupData?.privacyPolicyUrls);

  const fanData: FanEntity | undefined = queryClient.getQueryData(
    profileKeys.fan()
  );

  const isAdmin = fanData?.roles.includes(UserRole.ADMIN);

  const isFGO = fanData?.roles.includes(UserRole.FAN_GROUP_OWNER);

  const isFGOCurrentGroup = fanGroupRoles?.roles.includes(
    UserRole.FAN_GROUP_OWNER
  );

  const isAdminOrFGO = isAdmin || isFGO;

  const PROD = import.meta.env.MODE === 'production';

  const showSwitchInterfaceButton = isAdminOrFGO && fanGroupData;

  const switchInterfaceButtonEnabled = isFGOCurrentGroup || isAdmin;

  const showSwitchInterfaceAlert = !isFGOCurrentGroup && !isAdmin && !!slug;

  const changeTheme = () => {
    sessionStorage.setItem(
      SESSION_STORAGE_MODE,
      mode === 'dark' ? 'light' : 'dark'
    );
    switchTheme();
  };

  const customPrimaryColor = fanGroupData?.color;
  const customColor = Color(
    hexToRgb(customPrimaryColor ? `#${customPrimaryColor}` : '#000') ??
      'rgb(0, 0, 0)'
  );

  const customColorDarken30 = customColor.darken(0.3).toString();

  const { colorContrast } = getColorContrast(customColor.color);

  const isLight = colorContrast === 'black';

  const setCurrentTarget = (target: TranslationsTarget) => {
    changeTranslations(SESSION_STORAGE_TARGET_APP, target);
  };
  const handleInterfaceSwitch = () => {
    queryClient.clear();
    if (slug) {
      setCurrentTarget(TranslationsTarget.COCKPIT);
      localStorage.setItem(LOCAL_STORAGE_SLUG, slug);
    } else {
      setCurrentTarget(TranslationsTarget.WEB);
    }
    navigate(
      slug ? `/${routes.admin}/${routes.wishLists}` : `/${fanGroup?.slug}`
    );
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorTextBase: customPrimaryColor ?? primaryColor,
          colorTextHeading: token.colorTextBase,
        },
        components: {
          Typography: {
            colorTextDescription: 'red',
            colorTextLabel: 'red',
          },
        },
      }}
    >
      <div className={styles.popup}>
        <div className={styles.top}>
          <div>
            <Title level={4}>
              {user?.firstName} {user?.lastName}
            </Title>
            {user && (
              <p className={styles.user}>
                {slug
                  ? t('general_fan_in_fangroup') + fanGroupData?.name[lang]
                  : fanGroup
                  ? t('general_fgo_in_fangroup') +
                    getSingleTranslation(fanGroup.name)
                  : 'Admin in Seaters'}
              </p>
            )}
          </div>
          <NavLink to={routes.profile}>
            <Button
              color={!slug ? fanGroup?.color1 : `#${fanGroupData?.color}`}
              type="text"
              onClick={() => setIsOpen(false)}
              icon={<UserOutlined rev={undefined} />}
            >
              {t('button_manage_profile')}
            </Button>
          </NavLink>
          <ConfigProvider
            theme={{
              token: {
                colorTextBase: isLight
                  ? customColorDarken30
                  : customColor.toString(),
              },
            }}
          >
            {slug ? (
              isLoading ? (
                <div style={{ textAlign: 'center', width: '100%' }}>
                  <Spin />
                </div>
              ) : joinedFanGroups?.items.length ? (
                <Menu
                  className={styles.menu}
                  onClick={(e) => {
                    navigate(`/${e.key}`);
                    localStorage.setItem(LOCAL_STORAGE_SLUG, e.key);
                    queryClient.clear();
                    setIsOpen(false);
                  }}
                  selectedKeys={[slug]}
                  items={(allowedGroups
                    ? allowedJoinedGroups
                    : joinedFanGroups?.items
                  )?.map((item, i) => ({
                    label: item.translatedName,
                    key: item.slug,
                    icon: <Avatar src={item.logoImageUrl} size="large" />,
                    style: {
                      height: 'fit-content',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      color: baseColor900,
                      alignItems: 'center',
                      border:
                        item.slug === slug
                          ? `2px solid ${customColor.toString()}`
                          : 'transparent',
                    },
                  }))}
                />
              ) : null
            ) : null}
            {/* </div> */}
          </ConfigProvider>

          {/* {data} */}
          {/* <NavLink to={routes.companies}>
            <Button
              type="text"
              onClick={() => setIsOpen(false)}
              icon={<SettingOutlined />}
            >
              Company settings
            </Button>
          </NavLink> */}
        </div>
        {/* <CustomDivider color={token.colorBorder} /> */}
        {/* <Space direction="vertical">
          {user?.roles.map((role) => (
            <Button
              color={token.colorTextBase}
              type="text"
              disabled //TODO: implement role switching
              onClick={() => handleChangeRole(role)}
            >
              {role}
            </Button>
          ))}
        </Space> */}
        {showSwitchInterfaceButton && (
          <>
            <CustomDivider color={token.colorBorder} />
            <Space size={8} direction="vertical">
              <Button
                style={{ width: '100%' }}
                type="primary"
                onClick={handleInterfaceSwitch}
                disabled={!switchInterfaceButtonEnabled}
              >
                {slug
                  ? t('button_switch_admin_interface')
                  : `${t('main_topbar_go-to-btn')} ${t('admin_fan-group')}`}
              </Button>
              {showSwitchInterfaceAlert && (
                <Alert
                  style={{ color: '#003eb3' }}
                  message={t('switch-interface-alert-message')}
                  type="info"
                  showIcon
                />
              )}
            </Space>
          </>
        )}
        <CustomDivider color={token.colorBorder} />
        {!PROD && (
          <>
            <Segmented
              className={styles.switch}
              value={mode}
              onChange={changeTheme}
              options={[
                {
                  value: 'light',
                  icon: <SunIcon />,
                },
                {
                  value: 'dark',
                  icon: <MoonIcon />,
                },
              ]}
            />
            <CustomDivider color={token.colorBorder} />
          </>
        )}

        <div className={styles.bottom}>
          <Link
            className={styles.faq}
            to={faqLinks ? faqLinks : t('footer_support_faq-link')}
            target="_blank"
          >
            {t('footer_support_faq')}
          </Link>
          {tacLinks ? (

            <Link
              className={styles.faq}
              to={tacLinks ? tacLinks : t('footer_support_faq-link')}
              target="_blank"
            >
              {t('footer_support_tac')}
            </Link>

          ) : ''}
          {ppLinks ? (

            <Link
              className={styles.faq}
              to={ppLinks ? ppLinks : t('footer_support_faq-link')}
              target="_blank"
            >
              {t('footer_support_pp')}
            </Link>

          ) : ''}
          <Button
            type="link"
            icon={<LogoutOutlined rev={undefined} />}
            onClick={logout}
            className={styles.logout}
            color={token.colorError}
          >
            {t('settings_logout')}
          </Button>
        </div>
      </div>
    </ConfigProvider>
  );
};
