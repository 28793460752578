import React from 'react';
import { Drawer, DrawerProps, Form, FormProps, Space, message } from 'antd';
import { Button } from '@seaters-app/ui';
import { useIsMutating } from '@tanstack/react-query';
import dayjs from 'dayjs';
import {
  queryClient,
  requestAdminOneTimeUpload, requestOneTimeUpload,
  uploadFile,
  uploadTickets, useAppUserStore,
  useCreateTicketPool, useCreateTicketPoolFGO, useFanGroupStore
} from '@seaters-app/data-access';
import {
  TicketPoolFormValidator,
  TicketPoolValidator,
  TicketPoolValidatorSchema,
  ticketingKeys, UserRole
} from '@seaters-app/constants';
import { t } from 'i18next';
import TicketPoolForm from './TicketPoolForm';

interface CreateTicketPoolProps extends DrawerProps {
  onClose: () => void;
  ticketingSystemId: string;
}

const CreateTicketPool: React.FC<CreateTicketPoolProps> = ({
  onClose,
  ticketingSystemId,
  ...props
}) => {
  const { user } = useAppUserStore();
  const isAdmin = user?.roles.includes(UserRole.ADMIN);
  const { fanGroup } = useFanGroupStore();
  const fanGroupId = fanGroup?.id || '';

  const [form] = Form.useForm();

  const [messageApi, contextHolder] = message.useMessage();

  const key = 'updatable';

  const { mutate: createTicketPool } = useCreateTicketPool();
  const { mutate: createTicketPoolFGO } = useCreateTicketPoolFGO();

  const isMutating = !!useIsMutating();

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const prepareValues = (values: any): TicketPoolValidator => {
    return {
      name: values.name,
      expirationDate: dayjs(values.expirationDate).toISOString(),
      ticketPdf: '',
    };
  };

  const onFinish: FormProps<TicketPoolFormValidator>['onFinish'] = (values) => {
    const { name, originFileObj } = values.ticketPdf;

    const parsed = TicketPoolValidatorSchema.safeParse(prepareValues(values));

    if (!parsed.success) {
      console.log(parsed.error);
      return;
    }
    if (isAdmin) {
      createTicketPool(
        { systemId: ticketingSystemId, ticketPool: parsed.data },
        {
          onSuccess: async (ticketPoolResponse) => {
            messageApi.open({
              key,
              type: 'loading',
              content: 'Uploading tickets...',
              duration: 0,
            });
            handleClose();

            await requestAdminOneTimeUpload({
              fileName: encodeURIComponent(name),
            }).then(async (response) => {
              if (response.token) {
                const formFile = new FormData();
                formFile.append('file', originFileObj);
                await uploadFile(formFile, response.token).then(() => {
                  uploadTickets(ticketPoolResponse.id, response.fileId).then(
                    () => {
                      messageApi.open({
                        key,
                        type: 'success',
                        content: 'Uploaded!',
                        duration: 2,
                      });
                      queryClient.invalidateQueries(
                        ticketingKeys.ticketPools(ticketingSystemId)
                      );
                    }
                  );
                });
              }
            });
          },
        }
      );
    } else {
      createTicketPoolFGO(
        { groupId: fanGroupId, ticketPool: parsed.data },
        {
          onSuccess: async (ticketPoolResponse) => {
            messageApi.open({
              key,
              type: 'loading',
              content: 'Uploading tickets...',
              duration: 0,
            });
            handleClose();

            await requestOneTimeUpload({
              fileName: encodeURIComponent(name),
            }).then(async (response) => {
              if (response.token) {
                const formFile = new FormData();
                formFile.append('file', originFileObj);
                await uploadFile(formFile, response.token).then(() => {
                  uploadTickets(ticketPoolResponse.id, response.fileId).then(
                    () => {
                      messageApi.open({
                        key,
                        type: 'success',
                        content: 'Uploaded!',
                        duration: 2,
                      });
                      queryClient.invalidateQueries(
                        ticketingKeys.ticketPools(fanGroupId)
                      );
                    }
                  );
                });
              }
            });
          },
        }
      );
    }
  };

  const isSubmittingForm = form.isFieldsValidating() || isMutating;

  return (
    <>
      {contextHolder}
      <Drawer
        title={t('admin_ticket_pool_create')}
        width={720}
        onClose={handleClose}
        destroyOnClose
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            <Button onClick={handleClose} disabled={isSubmittingForm}>
              {t('general_cancel')}
            </Button>
            <Button
              onClick={() => form.submit()}
              type="primary"
              loading={isSubmittingForm}
            >
              {t('general_submit')}
            </Button>
          </Space>
        }
        {...props}
      >
        <TicketPoolForm
          onFinish={onFinish}
          form={form}
          disabled={isSubmittingForm}
        />
      </Drawer>
    </>
  );
};

export default CreateTicketPool;
