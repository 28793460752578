import { LOCAL_STORAGE_SLUG, routes } from '@seaters-app/constants';
import {
  getSessionData,
  getSlugFromUrl,
  useFanGroupStore,
  useFetchLook,
} from '@seaters-app/data-access';
import Color from 'color';

import {
  darkTheme,
  lightTheme,
  primaryColor,
  hexToRgb,
  getColorContrast,
} from '@seaters-app/ui';
import { notification } from 'antd';
import { useEffect, useState } from 'react';

export const useFanGroupSlug = () => {
  const auth = getSessionData();
  const [customLightTheme, setCustomLightTheme] = useState({ ...lightTheme });
  const [customDarkTheme, setCustomDarkTheme] = useState({ ...darkTheme });

  const detectedSlug = getSlugFromUrl();

  const { fanGroup: storeFanGroup } = useFanGroupStore();

  const {
    data: fanGroupData,
    isError,
    error,
  } = useFetchLook(
    (detectedSlug ||
      (storeFanGroup?.slug ?? localStorage.getItem(LOCAL_STORAGE_SLUG))) ??
      ''
  );
  if (isError) {
    if (detectedSlug && error) {
      notification.error({
        message: `${
          error?.response?.data?.message || 'Error'
        }: ${detectedSlug}`,
      });

      if (error?.response?.data?.message) {
        window.history.pushState({}, '', `/${routes.error}/${routes.landing}`);
        sessionStorage.clear();
        localStorage.removeItem(LOCAL_STORAGE_SLUG);
      }
    }
    if (auth) {
      window.location.reload();
    }
  }
  const customColor = fanGroupData?.color
    ? Color(hexToRgb(`${fanGroupData?.color}`) ?? 'rgb(0, 0, 0)')
    : Color(hexToRgb(primaryColor) ?? 'rgb(0, 0, 0)');

  const customColor200 = customColor.lighten(0.5).toString();
  const customColor700 = customColor.darken(0.5).toString();
  const customColorDarken = customColor.darken(0.3).toString();

  const { colorContrast } = getColorContrast(customColor.color);

  useEffect(() => {
    setCustomLightTheme({
      ...lightTheme,
      token: {
        ...lightTheme.token,
        colorPrimary: fanGroupData?.color
          ? `#${fanGroupData?.color}`
          : primaryColor,
        colorBgTextActive: customColor.toString(),
      },
    });
    setCustomDarkTheme({
      ...darkTheme,
      token: {
        ...darkTheme.token,
        colorPrimary: fanGroupData?.color
          ? `#${fanGroupData?.color}`
          : primaryColor,
        colorTextSecondary: customColor200,
        colorBgTextActive: customColor.toString(),
      },
    });
  }, [fanGroupData]);

  useEffect(() => {
    if (storeFanGroup?.id && auth) {
      const customColor = Color(
        hexToRgb(`${storeFanGroup?.color1}` ?? '#000') ?? 'rgb(0, 0, 0)'
      );
      const customColor200 = customColor.lighten(0.5).toString();
      const customColor700 = customColor.darken(0.5).toString();
      setCustomLightTheme({
        ...lightTheme,
        token: {
          ...lightTheme.token,
          colorPrimary: storeFanGroup?.color1
            ? `${storeFanGroup?.color1}`
            : primaryColor,
          colorBgTextActive: customColor.toString(),
        },
      });
      setCustomDarkTheme({
        ...darkTheme,
        token: {
          ...darkTheme.token,
          colorPrimary: storeFanGroup?.color1
            ? `${storeFanGroup?.color1}`
            : primaryColor,
          colorTextSecondary: customColor200,
          colorBgTextActive: customColor700,
        },
      });
    }
  }, [storeFanGroup?.id]);

  return {
    customLightTheme,
    customDarkTheme,
    colorContrast,
    customColorDarken,
    detectedSlug,
    customColor,
  };
};

export const useRemoveOldAppCookies = () => {
  const oldFWACookies = ['_fbp', '_ga', '_gat', '_gid'];

  document.cookie
    .split(';')
    .map((item) => item.trim())
    .filter((item) => item.startsWith('_ga') && item !== '_ga=')
    .map((item) => {
      oldFWACookies.push(item.slice(0, -1));
    });

  const MODE = import.meta.env.MODE;

  const domains: { [key: string]: string } = {
    qa: '.qa-seaters.com',
    development: '.qa-seaters.com',
    production: '.seaters.com',
  };

  useEffect(() => {
    oldFWACookies.forEach((cookie) => {
      document.cookie = `${cookie}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domains[MODE]}`;
    });
  }, []);
};
