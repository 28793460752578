export enum Language {
  EN = 'en',
  DE = 'de',
  NL_BE = 'nl_BE',
  NL_NL = 'nl_NL',
  NL = 'nl',
  CN = 'cn',
  RU = 'ru',
  IT = 'it',
  FR = 'fr',
  JA = 'ja',
  ES = 'es',
}
