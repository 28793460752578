import { Typography } from 'antd';
import { baseColor600, Button } from '@seaters-app/ui';
import { useTranslation } from 'react-i18next';
import { useFetchOauthAuthConfig } from '@seaters-app/data-access';
import { Link, useLocation } from 'react-router-dom';
import { SEATERS_APP_BASE_URL } from '@seaters-app/constants';
import i18next from 'i18next';

const { Text } = Typography;

export function FacebookLogin({ register } : {register?: boolean}) {
  const { t } = useTranslation();
  const { data: oauthConfig } = useFetchOauthAuthConfig("facebook");
  const { state: locationState } = useLocation();
  const redirectUri = encodeURI(SEATERS_APP_BASE_URL + "security/authenticate/oauth/facebook");

  return (
    <>
      {oauthConfig && (
        <Link to={oauthConfig.uri + "&redirect_uri=" + redirectUri} state={locationState}>
          <Button
            color="blue"
            size="large"
            type="primary"
            style={{ width: '100%' }}
          >
            {t('button_text_facebook_connect')}
          </Button>
        </Link>
      )}
      <div
        style={{
          textAlign: 'center',
          marginTop: 16,
          marginBottom: 16,
        }}
      >
        <Text style={{ color: baseColor600 }}>
          {register ? t('signup_with_email_text') : t('public_classic_auth_login')}
        </Text>
      </div>
    </>
  );
}
