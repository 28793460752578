import React, { useEffect, useState } from 'react';
import {
  getSessionToken,
  useFetchLook,
  useRolesStore,
  useVerifyOauth,
} from '@seaters-app/data-access';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Container, Loader } from '@seaters-app/ui';
import { routes } from '@seaters-app/constants';
import { Navigate, useParams } from 'react-router-dom';
import {
  SESSION_STORAGE_ALLOWED_GROUPS,
  LOCAL_STORAGE_SLUG,
  LOCAL_STORAGE_STRIPE_DEEP_LINK,
} from '@seaters-app/constants';
import { Alert, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { Button } from '@seaters-app/ui';

const VerifyAuthentication: React.FC = () => {
  const auth = getSessionToken();
  const { t } = useTranslation();

  const [slug, setSlug] = useState(localStorage.getItem(LOCAL_STORAGE_SLUG));
  const searchParams = new URLSearchParams(window.location.search);

  const { provider } = useParams();

  const code = searchParams.get('code');

  const error = searchParams.get('error');

  // // Do not remove this line!!!
  // const { roles } = useRolesStore();

  const { data: fanGroupData, refetch } = useFetchLook(slug ? slug : 'kpn');

  const {
    mutate: verifyOauth,
    isLoading,
    isError,
    error: verifyError,
  } = useVerifyOauth({
    provider: provider || '',
    authorizationCode: code || '',
    fangroupId: fanGroupData?.fanGroupId || '',
  });

  const handleVerify = () => {
    verifyOauth();
  };

  let origin: string | undefined = undefined;
  let stripeDeepLink: string | undefined = undefined;
  const [wlScreenPath, setWlScreenPath] = useState<string | null>(null);

  const PROD = import.meta.env.MODE === 'production';

  useEffect(() => {
    if (provider?.includes('kpn')) {
      const state = searchParams.get('state');

      const decodedState = state ? atob(state) : null;

      const splitted = decodedState?.split('&');

      origin = splitted
        ?.find((item) => item.startsWith('origin='))
        ?.slice('origin='.length);

      if (origin) {
        setSlug(origin);
      }

      const wlId = splitted
        ?.find((item) => item.startsWith('wlId='))
        ?.slice('wlId='.length)
        .trim();

      const surveyAfterEventToken = splitted
        ?.find((item) => item.startsWith('surveyAfterEventToken='))
        ?.slice('surveyAfterEventToken='.length)
        .trim();

      if (surveyAfterEventToken) {
        setWlScreenPath(
          `${wlId}/${routes.surveyAfterEvent}?token=${surveyAfterEventToken}`
        );
      } else {
        setWlScreenPath(wlId ?? null);
      }

      if (origin && origin !== localStorage.getItem(LOCAL_STORAGE_SLUG)) {
        localStorage.setItem(LOCAL_STORAGE_SLUG, origin);
      }

      const allowedFangroup = splitted
        ?.find((item) => item.startsWith('allowedGroups='))
        ?.slice('allowedGroups='.length);

      if (
        allowedFangroup &&
        allowedFangroup !==
          sessionStorage.getItem(SESSION_STORAGE_ALLOWED_GROUPS)
      ) {
        sessionStorage.setItem(SESSION_STORAGE_ALLOWED_GROUPS, allowedFangroup);
      }

      stripeDeepLink = splitted
        ?.find((item) => item.startsWith('stripeDeepLink='))
        ?.slice('stripeDeepLink='.length);

      if (stripeDeepLink) {
        localStorage.setItem(LOCAL_STORAGE_STRIPE_DEEP_LINK, stripeDeepLink);
      }
    }
  }, [provider]);

  useEffect(() => {
    if (
      !isLoading &&
      provider &&
      code &&
      fanGroupData?.fanGroupId &&
      !auth &&
      slug
    ) {
      handleVerify();
    }
  }, [fanGroupData?.fanGroupId]);

  useEffect(() => {
    if (slug) {
      refetch();
    }
  }, [slug]);

  return auth ? (
    <Navigate
      to={`/${
        wlScreenPath && (slug || origin)
          ? `${slug ?? origin}/${routes.waitingList}/${wlScreenPath}`
          : slug ?? origin
      }`}
    />
  ) : error || isError ? (
    <Container>
      {!PROD && <div>{fanGroupData?.fanGroupId ?? 'No Fan group'}</div>}
      {isError && !error && (
        <>
          <Result
            status="error"
            title={t('sso_error')}
            subTitle={
              verifyError?.response?.status === 422
                ? t('sso_error_description_422')
                : t('sso_error_description')
            }
          />
          {!PROD && <div>{fanGroupData?.fanGroupId ?? 'No Fan group'}</div>}
        </>
      )}
      {error && (
        <Alert
          type="error"
          message={
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {t(`sso_${error}`)}
              <Button
                icon={<ArrowLeftOutlined />}
                style={{
                  alignSelf: 'flex-end',
                }}
                onClick={() => window.history.back()}
              >
                {t('group_menu_back')}
              </Button>
            </div>
          }
        />
      )}
    </Container>
  ) : (
    <Loader show />
  );
};

export default VerifyAuthentication;
