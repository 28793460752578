export enum MailcampaignState {
  DRAFT = 'draft',
  SCHEDULED = 'scheduled',
  SENDING = 'sending',
  SENT = 'sent',
  QUEUED = 'queued',
  FAILED = 'failed',
}

export enum MailcampaignRecipient {
  FANGROUP = 'fangroup',
  WAITING_LIST = 'waitinglist',
  BADGE = 'badge',
  WAITING_LIST_NOT_DISTRIBUTED = 'waitinglist_not_distributed',
  WAITING_LIST_DISTRIBUTED_NOT_ACCEPTED = 'waitinglist_distributed_not_accepted',
  WAITING_LIST_DISTRIBUTED_ACCEPTED = 'waitinglist_distributed_accepted',
}
