import { Link, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import {
  getSingleTranslation,
  queryClient,
  useAppUserStore,
  useDeleteFGOWaitingList,
  useFanGroupStore,
  useFetchAdminWaitingList,
  useFetchFanGroupOwnerWaitingList,
  useFetchFGOWaitingListAvailableSeats,
  useFGOCloseWishList,
  useFGOWishListPositionsDetails,
  useFGOWishListSeatsDetails,
  useUpdateAdminWaitingList,
} from '@seaters-app/data-access';
import {
  CalendarOutlined,
  DeleteOutlined,
  EnvironmentOutlined,
  SettingOutlined,
  StopOutlined,
  LogoutOutlined,
  DownOutlined,
  UpOutlined,
  RedoOutlined,
} from '@ant-design/icons';

import {
  Button,
  baseColor400,
  errorColor,
  successColor,
} from '@seaters-app/ui';
import { WishListStatusTag } from '@seaters-app/ui-shared';
import {
  Image,
  Space,
  Tabs,
  TabsProps,
  Typography,
  theme,
  Breadcrumb,
  Popconfirm,
  Flex,
  notification,
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  BillingMode,
  FanGroupOwnerWaitingListEntity,
  InvitationMode,
  SESSION_STORAGE_TARGET_APP,
  routes,
  TranslationsTarget,
  WaitingListStatusEnum,
  UserRole,
} from '@seaters-app/constants';
import dayjs from 'dayjs';
import { usePositionDistributionOptions } from '../create/helpers';
import { WishListPositions } from './WishListPositions';
import { RSVP } from './RSVP';
import { ReactNode, useEffect, useState } from 'react';
import { SeatsDetails } from './SeatsDetails';
import { PositionsDetails } from './PositionsDetails';
import { Demands } from './Demands';
import { useAppTranslationsChange } from '../../../utils';

const { Text, Title } = Typography;

const extraTabs: {
  [key: string]: ReactNode;
} = {
  1: <PositionsDetails />,
  2: <SeatsDetails />,
};

export const useWishListTabs = (
  wishList?: FanGroupOwnerWaitingListEntity
): TabsProps['items'] => {
  const { t } = useTranslation();

  if (!wishList) {
    return [];
  }

  const tabList = {
    DEMANDS: {
      label: t('mwl_tab-invites'),
      children: <Demands />,
      isShown:
        wishList.invitationParameters &&
        wishList.invitationParameters.invitationMode === InvitationMode.ENABLED,
    },
    WISHLIST: {
      label: t('mwl_tab-wl'),
      children: <WishListPositions />,
      isShown: true,
    },
    RSVP: {
      label: t('rsvp_label_text'),
      children: <RSVP />,
      isShown: true,
    },
  };

  const tabKeys = Object.keys(tabList) as Array<keyof typeof tabList>;

  const tabs: TabsProps['items'] = tabKeys.reduce(
    (filtered: TabsProps['items'], item) => {
      const tab = tabList[item];

      const filteredTab = {
        key: item,
        label: tab.label,
        children: tab.children,
      };

      if (tab.isShown && filtered) {
        filtered.push(filteredTab);
      }

      return filtered;
    },
    []
  );

  return tabs;
};

export function WishList() {
  const { wishListId = '' } = useParams();
  const { t } = useTranslation();
  const { data: wishList } = useFetchFanGroupOwnerWaitingList(wishListId || '');

  const { data: availableSeatsData } =
    useFetchFGOWaitingListAvailableSeats(wishListId);
  const tabs = useWishListTabs(wishList);

  const positionDistributionOptions = usePositionDistributionOptions();

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const { data: positionDetails } = useFGOWishListPositionsDetails(wishListId);
  const { data: seatDetails } = useFGOWishListSeatsDetails(wishListId);

  const { user } = useAppUserStore();

  const isAdmin = user?.roles.includes(UserRole.ADMIN);
  const [activeTab, setActiveTab] = useState<string>();
  const [isMetaDataShown, setMetaDataShown] = useState(true);
  const { mutate: closeWL } = useFGOCloseWishList();
  const { mutate: deleteFGOWaitingList } = useDeleteFGOWaitingList(wishListId);

  const { token } = theme.useToken();

  const { changeTranslations } = useAppTranslationsChange();

  const setCurrentTarget = (target: TranslationsTarget) => {
    changeTranslations(SESSION_STORAGE_TARGET_APP, target);
  };
  const { fanGroup } = useFanGroupStore();

  const handleInterfaceSwitch = () => {
    queryClient.clear();

    setCurrentTarget(TranslationsTarget.WEB);
    navigate(`/${fanGroup?.slug}/${routes.waitingList}/${wishListId}`);
  };

  const handleRemoveWL = () => {
    if (wishListId) deleteFGOWaitingList({ waitingListId: wishListId });
  };

  const { data: adminWL } = useFetchAdminWaitingList(wishListId);

  const {
    mutateAsync: updateAdminWishList,
    isLoading: updateAdminWishListIsLoading,
  } = useUpdateAdminWaitingList();

  const reopenWL = async () => {
    if (adminWL) {
      const { billingVariables, ...rest } = adminWL;
      const body = {
        ...rest,
        ...billingVariables,
      };
      await updateAdminWishList(
        {
          waitingListId: wishList?.waitingListId || '',
          body: { ...body, status: WaitingListStatusEnum.OPEN },
        },
        {
          onSuccess: () => {
            notification.success({
              message: t('notification_success_wl_updated'),
            });
          },
          onError: ({ response }) => {
            notification.error({
              message: t('notification_error_wl_updated'),
              description: response?.data.message,
            });
          },
        }
      );
    }
  };

  const onTabsChange = (key: string) => {
    searchParams.set('tab', key.toLowerCase());
    setSearchParams(searchParams, { replace: true });
  };

  const handleCloseWishList = () => {
    closeWL({ waitingListId: wishListId || '' });
  };

  useEffect(() => {
    const tabParam = searchParams.get('tab');
    if (tabParam && tabs?.some(tab => tab.key === tabParam.toUpperCase())) {
      setActiveTab(tabParam.toUpperCase());
    } else if (tabs?.length){
      searchParams.set('tab', 'wishlist');
      setSearchParams(searchParams, { replace: true });
    }
  }, [searchParams, tabs]);

  if (!wishList) {
    return null;
  }

  const WLImage =
    wishList.waitingListImageUrl ||
    wishList.event.eventImageUrl ||
    wishList.event.venueImageUrl;

  const MetaDataItem = ({
    label,
    value = 0,
  }: {
    label: string;
    value: string | number;
  }) => {
    return (
      <Text type="secondary">
        {label} {value}
      </Text>
    );
  };

  const WLTabMetaData = () => {
    return positionDetails ? (
      <Flex
        justify="space-between"
        style={{
          textAlign: 'right',
        }}
      >
        <Space size={24} style={{ alignItems: 'start' }}>
          <Space direction="vertical" size={6}>
            <MetaDataItem
              label={t('mwl_wl_fans_waiting_seat')}
              value={positionDetails.fansWaitingSeat}
            />
            <MetaDataItem
              label={t('mwl_wl_detail-declined')}
              value={positionDetails.fansDeclined}
            />
            <MetaDataItem
              label={t('mwl_wl_fans-actual')}
              value={
                positionDetails.fansWaitingSeat - positionDetails.fansDeclined
              }
            />
          </Space>
          <Space direction="vertical" size={6}>
            <MetaDataItem
              label={t('mwl_wl_detail-seats-distributed')}
              value={positionDetails.fansHasSeat}
            />
            <MetaDataItem
              label={t('mwl_wl_fans-total')}
              value={
                positionDetails.fansWaitingSeat + positionDetails.seatsHasSeat
              }
            />
          </Space>
        </Space>
        <Space size={24} style={{ alignItems: 'start' }}>
          <Space direction="vertical" size={6}>
            <MetaDataItem
              label={t('mwl_wl_seats_waiting_seat')}
              value={positionDetails.seatsWaitingSeat}
            />
            <MetaDataItem
              label={t('mwl_wl_detail_seats-declined')}
              value={positionDetails.seatsDeclined}
            />
            <MetaDataItem
              label={t('mwl_wl_seats-actual')}
              value={
                positionDetails.seatsWaitingSeat - positionDetails.seatsDeclined
              }
            />{' '}
          </Space>
          <Space direction="vertical" size={6}>
            <MetaDataItem
              label={t('mwl_wl_seats-distributed')}
              value={positionDetails.seatsHasSeat}
            />
            <MetaDataItem
              label={t('mwl_wl_seats-total')}
              value={
                positionDetails.seatsWaitingSeat + positionDetails.fansHasSeat
              }
            />
            <MetaDataItem
              label={t('wl_set_estimated-seats')}
              value={wishList.estimatedTickets}
            />
          </Space>
        </Space>
      </Flex>
    ) : null;
  };

  const RSVPTabMetaData = () =>
    seatDetails ? (
      <Flex
        justify="space-between"
        style={{
          textAlign: 'right',
        }}
      >
        <Space size={24} style={{ alignItems: 'start' }}>
          <Space direction="vertical" size={6}>
            <MetaDataItem
              label={t('mwl_wl_fans_pending')}
              value={seatDetails.totalFansAssigned}
            />
            <MetaDataItem
              label={t('mwl_wl_fans_confirmed')}
              value={seatDetails.totalFansAccepted}
            />
            <MetaDataItem
              label={t('mwl_wl_fans_pending_without_seats')}
              value={seatDetails.totalFansAssignedWithoutSeats}
            />
          </Space>
          <Space direction="vertical" size={6}>
            <MetaDataItem
              label={t('mwl_wl_fans_confirmed_without_seats')}
              value={seatDetails.totalFansRsvpConfirmed}
            />
          </Space>
        </Space>
        <Space size={24} style={{ alignItems: 'start' }}>
          <Space direction="vertical" size={6}>
            <MetaDataItem
              label={t('mwl_wl_tickets_pending')}
              value={seatDetails.totalSeatsAssigned}
            />
            <MetaDataItem
              label={t('mwl_wl_tickets_assigned_without_seats')}
              value={seatDetails.totalSeatsAssignedWithoutTickets}
            />

            <MetaDataItem
              label={t('mwl_wl_tickets_confirmed')}
              value={seatDetails.totalSeatsAccepted}
            />
          </Space>
          <Space direction="vertical" size={6}>
            <MetaDataItem
              label={t('mwl_wl_tickets_confirmed_without_seats')}
              value={seatDetails.totalSeatsRsvpConfirmed}
            />
            <MetaDataItem
              label={t('mwl_wl_tickets_left')}
              value={availableSeatsData?.numberOfAvailableSeats || 0}
            />
            <MetaDataItem
              label={t('wl_set_estimated-seats')}
              value={wishList.estimatedTickets}
            />
          </Space>
        </Space>
      </Flex>
    ) : null;

  const tabToMetaData: {
    [key: string]: () => ReactNode;
  } = {
    WISHLIST: WLTabMetaData,
    RSVP: RSVPTabMetaData,
    DEMANDS: () => null,
  };

  const activeTabKey = activeTab || 'WISHLIST';

  return (
    <Flex
      vertical
      style={{
        padding: 24,
      }}
      gap={12}
    >
      <Space style={{ width: '100%', justifyContent: 'space-between' }}>
        <Breadcrumb
          items={[
            {
              title: (
                <Link to={`/${routes.admin}/${routes.wishLists}`}>
                  {t('wl_title')}
                </Link>
              ),
            },
            {
              title: <span>{t('admin_tabs_details')}</span>,
            },
          ]}
        />
        <Button
          style={{ alignSelf: 'center' }}
          type="text"
          onClick={handleInterfaceSwitch}
          icon={<LogoutOutlined rev={undefined} />}
        >
          {`${t('go_to_wish_list_btn')}`}
        </Button>
      </Space>
      <Space direction="vertical" size={12} style={{ width: '100%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Space>
            <Title level={3}>
              {wishList.experienceName?.length
                ? getSingleTranslation(wishList.experienceName)
                : wishList.event.eventName}
            </Title>
            <WishListStatusTag status={wishList.status} />
          </Space>
          <Space>
            <Link to={routes.settings}>
              <Button type="text" icon={<SettingOutlined rev={undefined} />}>
                {t('mwl_tab-settings')}
              </Button>
            </Link>
            {wishList.status === WaitingListStatusEnum.CLOSED ? (
              <>
                {isAdmin && (
                  <Button
                    loading={updateAdminWishListIsLoading}
                    color={successColor}
                    icon={<RedoOutlined rev={undefined} />}
                    onClick={reopenWL}
                  >
                    {t('reopen_wl_button_text')}
                  </Button>
                )}

                <Button
                  danger
                  icon={<DeleteOutlined rev={undefined} />}
                  onClick={handleRemoveWL}
                  data-testid="deleteWishListButton"
                >
                  {t('delete_wl_button_text')}
                </Button>
              </>
            ) : wishList.status !== WaitingListStatusEnum.ARCHIVED ? (
              <Popconfirm
                title={t('close_wl_button_text')}
                description={t('close_wl_confirmation_text')}
                cancelText={t('general_no')}
                onConfirm={handleCloseWishList}
                onOpenChange={() => console.log('open change')}
              >
                <Button
                  color={errorColor}
                  danger
                  icon={<StopOutlined rev={undefined} />}
                >
                  {t('close_wl_button_text')}
                </Button>
              </Popconfirm>
            ) : null}
          </Space>
        </div>
        <div
          style={{
            // alignItems: 'center',
            backgroundColor: token.colorPrimaryBg,
            borderRadius: 8,
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              paddingRight: 12,
            }}
          >
            <Image
              style={{ borderRadius: 8, maxWidth: 240 }}
              src={WLImage}
              preview={false}
            />
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'space-between',
                margin: '10px 10px 10px 16px',
              }}
            >
              <Space direction="vertical" size={6}>
                <Text type="secondary">
                  <Space>
                    <CalendarOutlined rev={undefined} />
                    {dayjs(wishList.event.startDate).format(
                      'ddd D MMMM h:mm A'
                    )}
                  </Space>
                </Text>
                <Text type="secondary">
                  <Space>
                    <EnvironmentOutlined rev={undefined} />
                    {wishList.event.venueName}
                  </Space>
                </Text>
              </Space>
              <Space
                direction="vertical"
                style={{ textAlign: 'right' }}
                size={6}
              >
                <Space>
                  <Text type="secondary">
                    {wishList?.billingMode === BillingMode.FREE
                      ? t('component_waitinglist_price-free')
                      : `${wishList.price} ${wishList?.event.venueCurrency}`}
                  </Text>
                </Space>
                <Space>
                  <Text type="secondary">{wishList.name}</Text>
                </Space>
                <Space>
                  <Text type="secondary">
                    {t(
                      positionDistributionOptions.find(
                        (el) => el.value === wishList.positionsDistributionMode
                      )?.label
                    )}
                  </Text>
                </Space>
              </Space>
            </div>
            {!!tabToMetaData[activeTabKey as string]?.() && (
              <Button
                type="text"
                onClick={() => setMetaDataShown(!isMetaDataShown)}
                icon={
                  !isMetaDataShown ? (
                    <DownOutlined rev={undefined} />
                  ) : (
                    <UpOutlined rev={undefined} />
                  )
                }
              />
            )}
          </div>
          {isMetaDataShown && (
            <Flex
              gap={24}
              style={{ padding: 8, borderTop: `1px solid ${baseColor400}` }}
            >
              <Space direction="vertical" size={0} style={{ width: '100%' }}>
                {tabToMetaData[activeTabKey as string]?.()}
              </Space>
            </Flex>
          )}
        </div>
      </Space>

      <Tabs
        destroyInactiveTabPane
        defaultActiveKey="WISHLIST"
        activeKey={activeTab}
        items={tabs}
        onChange={onTabsChange}
        tabBarExtraContent={extraTabs[activeTab || 0]}
        style={{ maxWidth: '100%', width: '100%' }}
      />
    </Flex>
  );
}
