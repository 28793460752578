import { Avatar, Form, Select } from 'antd';
import { Control, Controller } from 'react-hook-form';
import { useWLOptions } from '../../../surveys/[id]/hooks/useWLOptions';
import {
  getSingleTranslation,
  useFanGroupStore,
} from '@seaters-app/data-access';
import { useFGBadges } from '../../../fan-groups/[id]/badges/useFGBadges';
export interface RecipientDropdownProps {
  name: string;
  useFormControlMethod: any;
  options: {
    label: string;
    value: string;
    avatar?: string;
  }[];
  handleScroll: ((any: any) => void) | undefined;
}
function RecipientDropdown({
  name,
  useFormControlMethod,
  options,
  handleScroll,
}: RecipientDropdownProps): JSX.Element {
  return (
    <Form.Item required name={name} style={{ marginBottom: 0 }}>
      <Controller
        control={useFormControlMethod}
        name={name}
        render={({ field }) => (
          <Select
            style={{
              marginTop: 16,
            }}
            allowClear
            showSearch
            optionFilterProp="label"
            options={options}
            onPopupScroll={handleScroll}
            optionRender={({ data }) => {
              return data.avatar ? (
                <>
                  <Avatar src={data.avatar} size="small" /> {data?.label}
                </>
              ) : (
                data?.label
              );
            }}
            {...field}
          />
        )}
      />
    </Form.Item>
  );
}

export function RecipientDropdownWrapper({
  control,
  recipientType,
}: {
  control: Control;
  recipientType: 'wishlist' | 'badge' | null;
}): JSX.Element {
  // Wishlist Logic
  const { fanGroup } = useFanGroupStore();
  const fanGroupId = fanGroup?.id ?? '';
  const { waitingListsOptions, loadMoreData: loadMoreWLs } =
    useWLOptions(fanGroupId);

  const handleScrollWLs = (e: any) => {
    if (e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight) {
      loadMoreWLs();
    }
  };

  // Badge Logic
  const { badges } = useFGBadges();
  const badgesOptions = badges?.map((badge) => ({
    label: getSingleTranslation(badge.name),
    value: badge.id,
    avatar: badge.displayedLogoUrl,
  }));
  return (
    <>
      {recipientType === 'wishlist' && (
        <RecipientDropdown
          name="wishlist"
          useFormControlMethod={control}
          options={waitingListsOptions}
          handleScroll={handleScrollWLs}
        />
      )}
      {recipientType === 'badge' && (
        <RecipientDropdown
          name="badge"
          useFormControlMethod={control}
          options={badgesOptions!}
          handleScroll={undefined}
        />
      )}
    </>
  );
}
