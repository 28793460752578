import {
  DisplayMode,
  InvitationMode,
  PositionsDistributionMode,
  SeatStatus,
  WLPositionStatus,
  WaitingListEntity,
  WaitingListStatusEnum,
} from '@seaters-app/constants';
import { Card, ConfigProvider, Space, Tag, Typography, theme } from 'antd';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.css';
import {
  CalendarOutlined,
  EnvironmentOutlined,
  StarOutlined,
} from '@ant-design/icons';
import { errorColor, successColor } from '@seaters-app/ui';
import { baseColor100, baseColor600 } from '@seaters-app/ui';
import {
  getSingleTranslation,
  useIsCustomFlow,
} from '@seaters-app/data-access';
import { useTranslation } from 'react-i18next';
import { getFormattedDate } from '../../../../utils/helpers/formattedDate';
const { Title, Text } = Typography;

function WaitingListCard({
  waitingList,
  preview = false,
  redirectPath,
  preference = null,
}: {
  waitingList: WaitingListEntity;
  redirectPath?: string;
  preview?: boolean;
  preference?: number | null;
}) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const lang = language === 'nl_NL' || language === 'nl_BE' ? 'nl' : language;

  const isComingSoonWL =
    waitingList.waitingListStatus === WaitingListStatusEnum.PUBLISHED;

  const getTicketsText = ({ position, seat }: WaitingListEntity) => {
    if (!position) return null;
    if (
      position.status === WLPositionStatus.HAS_SEAT &&
      (seat?.status === SeatStatus.ASSIGNED ||
        seat?.status === SeatStatus.ASSIGNED_WITHOUT_SEATS)
    ) {
      return t('waitinglist_we_have_x_ticket_for_you', {
        seats: position.numberOfSeats,
      });
    } else if (
      position.status === WLPositionStatus.HAS_SEAT &&
      seat?.status === SeatStatus.ACCEPTED
    ) {
      return waitingList.displayMode === DisplayMode.CONTEST
        ? t('waitinglist_you_got_tickets_contest')
        : t('waitinglist_you_got_tickets');
    } else {
      return t('waitinglist_infos_steps_seat-title');
    }
  };

  const {
    eventName,
    experienceName,
    eventStartDate,
    eventEndDate,
    venueName,
    currency: { symbol },
    price,
    venueImageUrl,
    eventImageUrl,
    freeWaitingList,
    waitingListImageUrl,
    position,
    waitingListJoinInfoFanView,
    maxNumberOfSeatsPerHost,
    displayName,
    displayMode,
    showOriginalValue,
    positionsDistributionMode,
    groupRankAndLikelihoodHidden,
    invitationMode,
  } = waitingList;

  const rankIsShown =
    position?.status === WLPositionStatus.WAITING_SEAT &&
    positionsDistributionMode !== PositionsDistributionMode.RANDOM &&
    !groupRankAndLikelihoodHidden &&
    (!invitationMode || invitationMode === InvitationMode.DISABLED);

  const isMerchandisedDisplayMode = displayMode === DisplayMode.MERCHANDISE;
  const { token } = theme.useToken();

  const ticketsText = getTicketsText(waitingList);

  const isCustomFlow = useIsCustomFlow();

  return (
    <Card
      size="small"
      hoverable
      onClick={() => {
        if (!preview && redirectPath) navigate(redirectPath);
      }}
      cover={
        <div className={styles.cover}>
          <img
            className={styles.image}
            alt={eventName[lang] ?? eventName.en}
            src={waitingListImageUrl ?? eventImageUrl ?? venueImageUrl}
          />
          {waitingListJoinInfoFanView?.badgeFanViews && (
            <ConfigProvider
              theme={{
                token: {
                  colorText: baseColor100,
                },
              }}
            >
              <div className={styles.badgesWrapper}>
                {waitingListJoinInfoFanView.badgeFanViews.map((badge) => {
                  return (
                    !badge.hidden && (
                      <div
                        style={{
                          backgroundColor: badge.displayedColor,
                          minHeight: 26,
                        }}
                        className={styles.badge}
                      >
                        <Text strong>
                          {getSingleTranslation(badge.displayedText, lang)}
                        </Text>
                      </div>
                    )
                  );
                })}
              </div>
            </ConfigProvider>
          )}
        </div>
      }
      actions={[
        isCustomFlow ? (
          <div className={styles.actions}>
            {invitationMode === InvitationMode.ENABLED && (
              <Text style={{ color: token.colorPrimary }} strong>
                {t('general_number_of_seats', {
                  seats: maxNumberOfSeatsPerHost,
                })}
              </Text>
            )}
          </div>
        ) : position?.status === WLPositionStatus.HAS_SEAT ? (
          <Space size={40}>
            <Text
              style={{
                color: token.colorPrimary,
                fontWeight: 500,
                fontSize: 16,
                lineHeight: '24px',
              }}
            >
              {ticketsText}
            </Text>
            {rankIsShown && (
              <Text style={{ color: baseColor600 }}>
                {t('waitinglist_rank_label')}: {waitingList.position.rank}
              </Text>
            )}
          </Space>
        ) : position?.status === WLPositionStatus.WAITING_SEAT ? (
          <Space size={40}>
            <Text
              style={{
                color: token.colorPrimary,
                fontWeight: 500,
                fontSize: 16,
                lineHeight: '24px',
              }}
            >
              <StarOutlined rev={undefined} /> {t('waitinglist_joined')}
            </Text>
            {rankIsShown && (
              <Text style={{ color: baseColor600 }}>
                {t('waitinglist_rank_label')}: {waitingList.position.rank}
              </Text>
            )}
          </Space>
        ) : freeWaitingList ? (
          <div className={styles.actions}>
            {!!Number(price?.discountAmount) && showOriginalValue && (
              <Text
                style={{
                  color: errorColor,
                  marginRight: '0',
                  marginLeft: 'auto',
                }}
              >
                {t('invite_wl_value')} {Number(price?.discountAmount)}
                {symbol} {t('waitinglist_per_ticket')}
              </Text>
            )}
          </div>
        ) : (
          <div className={styles.actions}>
            <Text strong>
              {Number(price?.facialPrice)} {symbol}
            </Text>
            {!!Number(price?.discountAmount) && showOriginalValue && (
              <Text type="danger">
                {t('invite_wl_value')} {Number(price?.discountAmount)}
                {symbol} {t('waitinglist_per_ticket')}
              </Text>
            )}
          </div>
        ),
      ]}
    >
      {preference !== null ? (
        <Tag color={token.colorPrimary}>
          {t('mwl_preferences')}: {preference}
        </Tag>
      ) : null}
      {(experienceName || eventName) && (
        <Title ellipsis={{ rows: 2 }} level={5} style={{ minHeight: '48px' }}>
          {isComingSoonWL && (
            <span
              style={{
                fontSize: '14px',
                backgroundColor: successColor,
                color: baseColor100,
                padding: '2px 5px',
                verticalAlign: 'top',
                borderRadius: 4,
              }}
            >
              {t('group_waitinglists_comingsoon-label')}
            </span>
          )}{' '}
          {(experienceName[lang] ?? experienceName.en) ||
            (eventName[lang] ?? eventName.en)}
        </Title>
      )}

      <Space direction="vertical" style={{ minHeight: 104 }}>
        {!isMerchandisedDisplayMode && (
          <Space direction="vertical">
            {eventStartDate && (
              <Space size={4}>
                <CalendarOutlined rev={undefined} />
                <Text>{getFormattedDate(eventStartDate)}</Text>
                {eventEndDate && (
                  <Text>
                    {' - '}
                    {getFormattedDate(eventEndDate)}
                  </Text>
                )}
              </Space>
            )}
          </Space>
        )}
        {venueName && !isMerchandisedDisplayMode && (
          <Space
            style={{
              color: baseColor600,
              margin: 0,
              minHeight: '44px',
              textOverflow: 'ellipsis',
            }}
          >
            <EnvironmentOutlined rev={undefined} />
            <Text style={{ color: baseColor600 }}>
              {venueName[lang] ?? venueName.en}
            </Text>
          </Space>
        )}
        {displayName && <Space>{displayName}</Space>}
      </Space>
    </Card>
  );
}

export default WaitingListCard;
