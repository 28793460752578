// List of all API routes
export const seatersAdminPrefix = 'seaters-admin';
export const V2Prefix = 'v2';

export const endpoints = {
  accept: 'accept',
  acceptSeats: 'accept-seats',
  additionalInfos: 'additional-infos',
  addTickets: 'add-tickets',
  advancedSearch: 'advanced-search',
  aggregateInvitations: 'aggregate-invitations',
  answers: 'answers',
  answerSemantics: 'answer-semantics',
  app: 'app',
  approve: 'approve',
  approver: 'approver',
  approverRequests: 'approver-requests',
  approvers: 'approvers',
  assign: 'assign',
  assignWithoutSeats: 'assign-without-seats',
  assignWithParking: 'assign-with-parking',
  attendeesInfo: 'attendees-info',
  auth: 'auth',
  authentication: 'authentication',
  availableSeats: 'available-seats',
  availableSecondarySeats: 'available-secondary-tickets',
  backgroundImage: 'background-image',
  badges: 'badges',
  badgesCodeProtection: 'badges-code-protection',
  barCodes: `${seatersAdminPrefix}/available-barcode-types`,
  cancel: 'cancel',
  categories: 'categories',
  category: 'category',
  checkTransaction: 'check-transaction',
  choices: 'choices',
  close: 'close',
  configs: 'configs',
  configuration: 'configuration',
  mailcampaigns: 'mailcampaigns',
  countries: 'app/countries',
  code: 'code',
  countSeats: 'count-seats',
  coverImage: 'cover-image',
  currencyCodes: 'app/currencies',
  customInfo: 'custom-info',
  customInfoCodeProtection: 'custom-info-code-protection',
  declineSeatsRequest: 'decline-seats-request',
  details: 'details',
  dislike: 'dislike',
  distribution: 'distribution',
  distributionFinished: 'distribution-finished',
  draft: 'draft',
  email: 'email',
  estimatedTickets: 'estimated-tickets',
  events: `events`,
  expiredPositions: 'expired-positions',
  exportFGReport: 'export-fan-group-report',
  exportParkingTicket: 'export-parking-ticket',
  exportSeat: 'export-seat',
  exportSeats: 'export-seats',
  exportSurveyAnswerReport: 'export-survey-answer-report',
  fan: 'fan',
  fanShortSession: 'fan-short-session',
  fanAttributes: 'fan-attributes',
  fanGroupHost: 'fangroup-host',
  fanGroupOwner: 'fan-group-owner',
  fanGroupApprover: 'fangroup-approver',
  fanGroupProtectionCodes: 'protection-codes',
  wishListProtectionCodes: 'protection-codes',
  fanGroupProtectionCodesEnhanced: 'enhanced-protection-codes',
  fanGroups: `fan-groups`,
  fanGroupsBySlug: 'fangroups-by-slug',
  filters: 'filters',
  giveSeats: 'give-seats',
  giveParking: 'give-parking',
  giveSeatsParking: 'give-seats-parking',
  grant: 'grant',
  groups: 'groups',
  guest: 'guest',
  guests: 'guests',
  guestsMy: 'my-guests',
  host: 'host',
  hosts: 'hosts',
  instances: 'instances',
  interests: 'interests',
  invitations: 'invitations',
  joinedGroups: 'joined-groups',
  joinedWaitingLists: 'joined-waiting-lists',
  languages: 'app/languages',
  like: 'like',
  link: 'link',
  linkByName: 'link-by-name',
  loginConfigurations: 'login-configurations',
  login_v2: 'v2/authentication/login',
  login: 'auth/verify',
  logoImage: 'logo-image',
  look: 'look',
  markForDistribution: 'mark-for-distribution',
  markForParkingDistribution: 'mark-for-parking-distribution',
  members: 'members',
  moreSeats: 'more-seats',
  myWaitingList: 'joined-waiting-lists',
  neutral: 'neutral',
  oauth: 'oauth',
  oneTimeFiles: 'one-time-files',
  open: 'open',
  ownerships: 'ownerships',
  password: 'password',
  paymentInfo: 'payment-info',
  paymentSystems: 'payment-systems',
  personalInfos: 'personal-infos',
  phone: 'mobile-phone-number',
  pim: 'pim',
  position: 'position',
  positions: 'positions',
  previewVoucher: 'preview-voucher',
  price: 'price',
  profileImage: 'profile-image',
  properties: 'properties',
  publish: 'publish',
  question: 'question',
  questions: 'questions',
  registrationTypes: 'registration-types',
  removeTickets: 'remove-tickets',
  reExportVoucher: 're-export-voucher',
  refreshToken: 'v2/authentication/refresh-token/',
  reject: 'reject',
  request: 'request',
  requests: 'requests',
  requestAdminOneTimeUpload: `${seatersAdminPrefix}/request-one-time-upload`,
  requestOneTimeUpload: 'request-one-time-upload',
  requestWithData: 'request-with-data',
  resetPassword: 'authentication/reset-password',
  revoke: 'revoke',
  userRoles: 'user-roles',
  saml: 'saml',
  schedule: 'schedule',
  search: 'search',
  searchEvent: 'search-event',
  seatCategories: 'seat-categories',
  seatersAdmin: 'seaters-admin',
  seats: 'seats',
  shuffle: 'shuffle',
  signup: 'v2/authentication/signup',
  survey: 'survey',
  surveys: 'surveys',
  systems: 'systems',
  tc: 'tc',
  ticketing: 'ticketing',
  ticketPools: 'ticket-pools',
  tickets: 'active-waiting-lists-with-seat',
  timeZones: 'app/time-zones',
  transaction: 'transaction',
  translatedVenueConditions: 'translated-venue-conditions',
  translations: 'translations',
  unlink: 'unlink',
  unlock: 'unlock',
  unmarkForParkingDistribution: 'unmark-for-parking-distribution',
  updatePassword: 'v2/authentication/update-password',
  upload: 'upload',
  uploadTickets: 'upload-tickets',
  users: `${seatersAdminPrefix}/users`,
  usersFGO: 'users',
  validate: 'validate',
  venueConditions: 'venue-conditions',
  venueConfigurations: `${seatersAdminPrefix}/venue-configurations`,
  venueImage: 'image',
  venues: `venues`,
  venuesSearch: 'search-venue',
  verify: 'verify',
  verifyOauth: 'verify-oauth',
  waitingListImage: 'waitinglist-image',
  waitingListNames: 'waiting-list-names',
  waitingLists: 'waiting-lists',
  waitingListsWithApprovals: 'waiting-lists-with-approvals',
  waitingListsWithInvitations: 'waiting-lists-with-invitations',
  waitingListsWithRequests: 'waiting-lists-with-requests',
  wlBadges: 'wl-badges',
  themes: 'themes',
  scheduleActivation: 'schedule-activation',
  scheduleArchiving: 'schedule-archiving',
  send: 'send',
  image: 'image',
};

// api / v2 / authentication / verify - oauth;
