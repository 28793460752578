import {
  useFetchMailcampaign,
  useScheduleMailcampaign,
  useSendMailcampaign,
} from '@seaters-app/data-access';
import {
  notification,
  Button,
  Spin,
  Row,
  Col,
  Typography,
  Radio,
  DatePicker,
  DatePickerProps,
  Card,
  theme,
} from 'antd';
import MailCampaignDetail from '../../detail/components/MailcampaignDetail';
import { useState } from 'react';
import Footer from '../components/Footer';
import { useCurrentStep } from '../hooks/useCurrentStep';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { MailcampaignRequiredFormSchema } from '@seaters-app/constants';
import { CalendarOutlined, MailOutlined } from '@ant-design/icons';
import { baseColor400 } from '@seaters-app/ui';
import dayjs, { Dayjs } from 'dayjs';

export function SendStep() {
  const colorPrimary = theme.useToken().token.colorPrimary;

  const { prev, setCurrent } = useCurrentStep();
  const { t } = useTranslation();
  const { mailcampaignId } = useParams();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: mailcampaignValues } = useFetchMailcampaign(mailcampaignId);
  const { mutate: sendMailCampaign } = useSendMailcampaign();
  const { mutate: scheduleMailCampaign } = useScheduleMailcampaign();

  const [isScheduled, setIsScheduled] = useState<boolean>(false);
  const [scheduleDate, setScheduleDate] = useState<Dayjs | undefined | null>(
    null
  );

  const onOk = (value: DatePickerProps['value']) => {
    setScheduleDate(value);
  };

  const submit = (onSuccess: () => void) => {
    setIsSubmitting(true);
    const callbacks = {
      onSuccess: () => {
        onSuccess();
        setIsSubmitting(false);
      },
      onError: (error: any) => {
        notification.error({
          message: t('notification_error_wl_updated'),
          description: error.response?.data?.message || error.message,
        });
        setIsSubmitting(false);
      },
    };
    if (!mailcampaignId) throw new Error('mailcampaignId is required');
    if (isScheduled) {
      if (!scheduleDate) throw new Error('scheduleDate is required');
      scheduleMailCampaign({ id: mailcampaignId, scheduleDate }, callbacks);
    } else {
      sendMailCampaign({ id: mailcampaignId }, callbacks);
    }
  };

  const parsedMailcampaignValues =
    MailcampaignRequiredFormSchema.safeParse(mailcampaignValues);
  const handleSaveAsDraft = () => navigate('/admin/mailcampaigns');
  const handleNextStep = () =>
    submit(() =>
      navigate(`/admin/mailcampaigns/${isScheduled ? 'scheduled' : 'sent'}`)
    );
  return (
    <>
      <MailCampaignDetail
        edit={true}
        editContent={() => setCurrent(1)}
        editDetail={() => setCurrent(0)}
      />
      <Row align="middle">
        <Col xs={24} md={24} xl={12}>
          <Radio.Group
            style={{ width: '100%', minWidth: 300 }}
            value={isScheduled}
            onChange={(e) => setIsScheduled(e.target.value)}
          >
            <Card
              bordered
              key="send"
              style={{
                marginBottom: 8,
                borderColor: !isScheduled ? colorPrimary : baseColor400,
              }}
            >
              <Radio value={false}>Send Mail Campaign now</Radio>
            </Card>
            <Card
              bordered
              key="schedule"
              style={{
                marginBottom: 8,
                borderColor: isScheduled ? colorPrimary : baseColor400,
              }}
            >
              <Radio value={true}>Schedule sending of mailcampaign</Radio>
              {isScheduled && (
                <DatePicker
                  showTime={{ format: 'HH:mm' }}
                  disabledDate={(current) =>
                    current && current < dayjs().startOf('day')
                  }
                  disabledTime={(current) => {
                    const now = new Date();
                    if (current && current.isSame(now, 'day')) {
                      return {
                        disabledHours: () =>
                          Array.from(Array(now.getHours()).keys()),
                        disabledMinutes: (hour) =>
                          hour === now.getHours()
                            ? Array.from(Array(now.getMinutes()).keys())
                            : [],
                      };
                    }
                    return {};
                  }}
                  onOk={onOk}
                />
              )}
            </Card>
          </Radio.Group>
        </Col>
      </Row>
      <Footer
        handlePrevStep={prev}
        saveAsDraft={handleSaveAsDraft}
        isLoading={isSubmitting}
        submitButton={
          <Button
            size="middle"
            type="primary"
            loading={isSubmitting}
            onClick={handleNextStep}
            disabled={
              !parsedMailcampaignValues.success ||
              isSubmitting ||
              (isScheduled && !scheduleDate)
            }
          >
            {isScheduled
              ? t('mailcampaign_schedule_email')
              : t('mailcampaign_send_email')}{' '}
            {isScheduled ? <CalendarOutlined /> : <MailOutlined />}
          </Button>
        }
      ></Footer>
      <Spin
        fullscreen
        spinning={isSubmitting}
        size="large"
        tip={t('mailcampaigns_submitting')}
      />
    </>
  );
}
