import {
  Col,
  Form,
  Input,
  Row,
  Segmented,
  Space,
  Spin,
  Typography,
} from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useWatch,
} from 'react-hook-form';
import {
  FGOEventFormValidatorSchema,
  Language,
  languagesOptions,
  FGOEventFormValidator as ValidationSchema,
} from '@seaters-app/constants';
import { Button, DatePicker, Editor } from '@seaters-app/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEventCreateStepper } from './hooks/useEventCreateStepper';
// Remove the following line to fix the circular dependency issue
// import { ImageUploader } from '@seaters-app/ui-shared';
import styles from './styles.module.css';
import { requestOneTimeUpload, uploadFile } from '@seaters-app/data-access';
import { useTranslation } from 'react-i18next';
import { ImageCropper, StyledText } from '@seaters-app/ui-shared';
import dayjs from 'dayjs';
const { Text } = Typography;

export function EventFormStep() {
  const [currentLanguage, setCurrentLanguage] = useState<Language>(Language.EN);
  const [editorShown, setEditorShown] = useState(false);

  const { t } = useTranslation();
  const { eventFormValues, setEventFormValues, next, prev } =
    useEventCreateStepper();

  const methods = useForm<ValidationSchema>({
    resolver: zodResolver(FGOEventFormValidatorSchema),
    defaultValues: eventFormValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;

  const imageFileId = useWatch({
    control,
    name: 'imageFileId',
  });

  const startDate = useWatch({
    control,
    name: 'startDate',
  });

  useEffect(() => {
    if (errors && currentLanguage !== Language.EN) {
      setCurrentLanguage(Language.EN);
    }
  }, [errors]);

  const onSubmit: SubmitHandler<ValidationSchema> = async (values) => {
    const valuesToSave = { ...values };
    const imageUrlOrCanvas = values?.imageFileId as
      | string
      | HTMLCanvasElement
      | undefined;
    if (imageUrlOrCanvas && typeof imageUrlOrCanvas !== 'string') {
      await requestOneTimeUpload({
        fileName: 'file.png',
      }).then(async (res) => {
        if (res.fileId) {
          const formFile = new FormData();
          const blob = (await new Promise((resolve) =>
            imageUrlOrCanvas?.toBlob(resolve)
          )) as Blob;
          formFile.append('file', blob);
          await uploadFile(formFile, res.path.slice(20, 52)).then(() => {
            valuesToSave.imageFileId = res.fileId;
          });
        }
      });
    }

    setEventFormValues(valuesToSave);

    next();
  };
  return (
    <Space
      direction="vertical"
      style={{ marginBottom: 50, width: '100%' }}
      size={0}
    >
      <FormProvider {...methods}>
        <Form
          layout="vertical"
          onFinish={handleSubmit(onSubmit)}
          autoComplete="off"
          disabled={isSubmitting}
        >
          <Spin spinning={isSubmitting} tip={t('please_wait_message_text')}>
            <Space
              direction="vertical"
              style={{ maxWidth: 840, width: '100%' }}
            >
              <Row gutter={24}>
                <Col xs={24} md={16} xl={12}>
                  <Form.Item
                    label={<Text strong>{t('admin_fan-groups_name')}</Text>}
                    name={`name.${currentLanguage}`}
                    validateStatus={
                      errors.name &&
                      errors.name[currentLanguage]?.message &&
                      'error'
                    }
                    help={errors.name && errors.name[currentLanguage]?.message}
                  >
                    <Controller
                      control={control}
                      name={`name.${currentLanguage}`}
                      render={({ field }) => <Input {...field} />}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8} xl={6}>
                  <Form.Item
                    label={
                      <Text strong>{t('admin_fan-groups_short-name')}</Text>
                    }
                    name={`shortName.${currentLanguage}`}
                    validateStatus={
                      errors.shortName &&
                      errors.shortName[currentLanguage]?.message &&
                      'error'
                    }
                    help={
                      errors.shortName &&
                      errors.shortName[currentLanguage]?.message
                    }
                  >
                    <Controller
                      control={control}
                      name={`shortName.${currentLanguage}`}
                      render={({ field }) => <Input {...field} />}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24}>
                  <Form.Item
                    name={`description.${currentLanguage}`}
                    label={
                      <Text strong>{t('admin_fan-groups_description')}</Text>
                    }
                    validateStatus={
                      errors.description &&
                      errors.description[currentLanguage]?.message &&
                      'error'
                    }
                    help={
                      errors.description &&
                      errors.description[currentLanguage]?.message
                    }
                  >
                    <Controller
                      control={control}
                      name={`description.${currentLanguage}`}
                      render={({ field: { onChange, ...field } }) => {
                        return !editorShown ? (
                          <StyledText
                            text={field.value ?? ''}
                            onClick={() => setEditorShown(true)}
                          />
                        ) : (
                          <Editor
                            onEditorChange={onChange}
                            width="100%"
                            height={320}
                            disabled={isSubmitting}
                            {...field}
                          />
                        );
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col xs={24} md={8} xl={6}>
                  <Form.Item
                    label={<Text strong>{t('event_settings_start')}</Text>}
                    name="startDate"
                    validateStatus={
                      errors.startDate && errors.startDate?.message && 'error'
                    }
                    help={
                      errors.startDate?.message && t(errors.startDate?.message)
                    }
                  >
                    <Controller
                      control={control}
                      name={'startDate'}
                      render={({ field }) => (
                        <DatePicker
                          format="DD/MM/YYYY HH:mm"
                          style={{ width: '100%' }}
                          showTime={{
                            defaultValue: dayjs('00:00', 'HH:mm'),
                            format: 'HH:mm',
                          }}
                          {...field}
                        />
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8} xl={6}>
                  <Form.Item
                    label={<Text strong>{t('event_settings_end')}</Text>}
                    name="endDate"
                    validateStatus={
                      errors.endDate && errors.endDate?.message && 'error'
                    }
                    help={errors.endDate?.message && t(errors.endDate?.message)}
                  >
                    <Controller
                      control={control}
                      name={'endDate'}
                      render={({ field }) => (
                        <DatePicker
                          format="DD/MM/YYYY HH:mm"
                          style={{ width: '100%' }}
                          showTime={{
                            defaultValue: dayjs('00:00', 'HH:mm'),
                            format: 'HH:mm',
                          }}
                          minDate={dayjs(startDate, 'DD/MM/YYYY HH:mm')}
                          {...field}
                        />
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label={t('admin_event_image')} name="imageFileId">
                <ImageCropper
                  imageOrCanvas={imageFileId ?? ''}
                  handleRemoveImage={() => setValue('imageFileId', '')}
                  handleImageChange={(img) => setValue('imageFileId', img)}
                  handleReset={() => {
                    setValue('imageFileId', eventFormValues?.imageFileId);
                  }}
                />
              </Form.Item>
            </Space>
          </Spin>
          {/* </Footer> */}
          <div className={styles.footer}>
            <Button size="middle" onClick={prev}>
              <LeftOutlined rev={undefined} />
              {t('prev_step_button_text')}
            </Button>
            <Segmented
              // size="large"
              options={languagesOptions}
              value={currentLanguage}
              onChange={setCurrentLanguage}
            />
            <Button
              size="middle"
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
            >
              {t('next_step_button_text')}
              <RightOutlined rev={undefined} />
            </Button>
          </div>
        </Form>
      </FormProvider>
    </Space>
  );
}
