import {
  BillingMode,
  InvitationEntity,
  InvitationForApprove,
  Price,
  WaitingListEntity,
} from '@seaters-app/constants';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

export const useWLGuestListSummaryData = () => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const lang = language === 'nl_NL' || language === 'nl_BE' ? 'nl' : language;
  const getData = (
    waitingList: WaitingListEntity,
    price: Price | null,
    totalSeats: number,
    tickets: number,
    toAllocateInvitation?: InvitationEntity
  ) => {
    const isPaid = waitingList.billingMode === BillingMode.FAIR_PRICE;

    return {
      isHost: true,
      generalInfo: {
        imageUrl:
          waitingList.waitingListImageUrl ||
          waitingList.eventImageUrl ||
          waitingList.venueImageUrl,

        title: waitingList.eventName[lang],
        startDate: dayjs(waitingList.eventStartDate).format('ddd D MMMM'),
        status: (waitingList as WaitingListEntity)?.waitingListStatus,
        venue: waitingList?.venueName[lang],
        category: waitingList.waitingListCategory || waitingList?.seatCategory,
      },
      tableInfo: [
        // Price:
        [
          {
            label: `${t('invite_wl_value')} `,
            value: price?.formattedOriginalPrice,
          },
          {
            label: `${t('invite_wl_price')} `,
            value: price?.formattedFacialPrice,
            isHidden: !isPaid,
          },
          {
            label: `${t('wl_fee-lbl')} `,
            value: price?.formattedFee,
            isHidden: !isPaid,
          },
        ],
        // Max amounts:
        [
          {
            label: `${t('waitinglist_max_tickets_per_host')}: `,
            value: (waitingList as WaitingListEntity)?.maxNumberOfSeatsPerHost,
          },
          {
            label: `${t('waitinglist_max_tickets_per_guest')}: `,
            value: (waitingList as WaitingListEntity)
              ?.maxNumberOfSeatsPerPosition,
          },
        ],
        // Main numbers:
        [
          {
            label: `${t('request_card_available_seats')} `,
            value:
              toAllocateInvitation?.status !== InvitationForApprove.APPROVED
                ? 0
                : toAllocateInvitation.numberOfNonAllocatedSeats,
            isCustom: true,
          },
          {
            label: `${t('request_card_distributed_seats')} `,
            value:
              toAllocateInvitation?.status !== InvitationForApprove.REJECTED
                ? tickets -
                  (toAllocateInvitation?.numberOfNonAllocatedSeats ?? 0)
                : 0,
            isCustom: true,
          },
          {
            label: `${t('waitinglist_estimated_tickets')} `,
            value: totalSeats,
          },
        ],
        [
          {
            label: isPaid ? `${t('budget_cost')} ` : null,
            value: isPaid
              ? price?.formattedFacialPrice.toString()
              : t('waitinglist_free_ticket'),
          },
        ],
      ],
    };
  };
  return { getData };
};
