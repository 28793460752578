import {
  PimAuthConfiguration,
  EmailValidator,
  endpoints,
  FanGroupBySlug,
  HostEntity,
  LookEntity,
  PimCheckTransaction,
  PimCheckTransactionQuery,
  PimQuery,
  SignUpResultEntity,
  UpdatePasswordValidator,
  V2Prefix,
  VerificationType,
  VerificationTypeV2,
  OauthAuthConfiguration,
  SamlAuthConfiguration,
  SEATERS_APP_BASE_URL,
  routes,
  ResetPasswordValidator,
} from '@seaters-app/constants';

import { api } from '../axios-instance';
import i18next from 'i18next';

/**
 * Login user with body
 * @param {LoginValidator} body - request payload
 */
export function login<Type>(body: Type): Promise<VerificationType> {
  return api.put(endpoints.login, body);
}

/**
 * Login user with body
 * @param {LoginValidator} body - request payload
 */
export function loginV2<Type>(body: Type): Promise<VerificationTypeV2> {
  return api.put(endpoints.login_v2, body);
}

/**
 * Sign up user with body
 * @param {SignupValidator} body - request payload
 */
export function signUp<Type>(body: Type): Promise<SignUpResultEntity> {
  return api.post(endpoints.signup, body);
}

/**
 * Reset user's password
 */

export const resetUserPassword = (
  body: ResetPasswordValidator
): Promise<VerificationType> =>
  api.put(`${V2Prefix}/${endpoints.resetPassword}`, {
    email: body.email,
    baseUrl: `${SEATERS_APP_BASE_URL}${body.slug}/${routes.authenticate}/`,
  });

export const updatePassword = (
  body: UpdatePasswordValidator
): Promise<VerificationType> => api.put(endpoints.updatePassword, body);

export const fetchHost = (): Promise<HostEntity> =>
  api.get(`${V2Prefix}/${endpoints.host}`);

export const fetchLook = (slug: string): Promise<LookEntity> =>
  api.get(`/${endpoints.fan}/${endpoints.fanGroupsBySlug}/${slug}/look`);

export const fetchPimAuthConfiguration = (
  provider: string,
  params?: PimQuery
): Promise<PimAuthConfiguration> =>
  api.get(
    `/${V2Prefix}/${endpoints.authentication}/${endpoints.pim}/${provider}/${endpoints.configuration}`,
    { params }
  );

// https://api.qa-seaters.com/api/v2/authentication/pim/kpnconsumers/check-transaction?reference=8701b7fa81fd4a5c815cdfeb1dca9614
// Request Method:
// GET
export const fetchPimCheckTransaction = (
  provider: string,
  params?: PimCheckTransactionQuery
): Promise<PimCheckTransaction> =>
  api.get(
    `/${V2Prefix}/${endpoints.authentication}/${endpoints.pim}/${provider}/${endpoints.checkTransaction}`,
    { params }
  );

// https://api.qa-seaters.com/api/v2/authentication/oauth/url/portofantwerp
export const fetchOauthAuthConfiguration = (
  provider: string
): Promise<OauthAuthConfiguration> =>
  api.get(
    `/${V2Prefix}/${endpoints.authentication}/${endpoints.oauth}/${provider}/${endpoints.configuration}`
  );

// https://api.qa-seaters.com/api/v2/authentication/oausamlth/url/portofantwerp
export const fetchSamlAuthConfiguration = (
  provider: string
): Promise<SamlAuthConfiguration> =>
  api.get(
    `/${V2Prefix}/${endpoints.authentication}/${endpoints.saml}/${provider}/${endpoints.configuration}`
  );

export const fetchFGBySlug = (slug: string): Promise<FanGroupBySlug> =>
  api.get(`/${endpoints.fan}/${endpoints.fanGroupsBySlug}/${slug}`);

// PUThttps://api.qa-seaters.com/api/v2/authentication/refresh-token/

export const refreshToken = (token: string): Promise<VerificationTypeV2> => {
  return api.put(endpoints.refreshToken, { token });
};

export function verifyOauth(body: {
  provider: string;
  authorizationCode: string;
  fangroupId: string;
}): Promise<VerificationTypeV2> {
  return api.put(
    `${V2Prefix}/${endpoints.authentication}/${endpoints.verifyOauth}`,
    body,
    { params: {"language": i18next.language} }
  );
}

export const verifyAuth = (body: {
  sessionToken: string;
}): Promise<VerificationType> => {
  return api.put(`${endpoints.auth}/${endpoints.verify}`, body);
};
