import {
  AxiosAPIError,
  CustomInfoValidator,
  GroupGuestsListEntity,
  GroupGuestsQuery,
  groupsKeys,
  GuestEntity,
  GuestFormValidator,
  GuestValidator,
  SemanticNameEnum,
} from '@seaters-app/constants';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  createGuest,
  createGuestCustomInfo,
  fetchGroupGuests,
  updateGuest,
} from '../api/guests';
import { useImportContactsStore } from '../storage/import-contacts-store';

export const useFetchGroupGuests = (
  groupId?: string,
  params?: GroupGuestsQuery
) =>
  useQuery<GroupGuestsListEntity, Error>(
    groupsKeys.guestsList(groupId, params),
    () => fetchGroupGuests(groupId, params),
    {
      enabled: !!groupId,
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

export const useCreateGuest = (
  groupId?: string,
  type?: 'single' | 'multiple'
): UseMutationResult<
  GuestEntity,
  AxiosAPIError<GuestFormValidator>,
  GuestFormValidator,
  unknown
> => {
  const { mutateAsync: createGuestCustomInfo } = useCreateGuestCustomInfo(
    groupId || ''
  );

  const { increaseError, increaseSuccess, setErrorGuests } =
    useImportContactsStore();

  return useMutation(
    async ({ company, jobtitle, ...rest }: GuestFormValidator) =>
      await createGuest(groupId, rest),
    {
      mutationKey: groupsKeys.guestsMutation(groupId),
      onSuccess: async (data, variables) => {
        const { company, jobtitle } = variables;

        if (company && company.length) {
          await createGuestCustomInfo({
            guestId: data.guestId,
            body: {
              informationValue: company,
              semanticName: SemanticNameEnum.COMPANY,
            },
          });
        }

        if (jobtitle && jobtitle.length) {
          await createGuestCustomInfo({
            guestId: data.guestId,
            body: {
              informationValue: jobtitle,
              semanticName: SemanticNameEnum.JOB_TITLE,
            },
          });
        }
        if (type === 'multiple') {
          increaseSuccess();
        }
      },
      onError: (_, request) => {
        if (type === 'multiple') {
          increaseError();
          setErrorGuests(request.guestEmail);
        }
      },
    }
  );
};

export const useUpdateGuest = (
  groupId: string
): UseMutationResult<
  GuestEntity,
  AxiosAPIError<GuestValidator>,
  { guestId: string; body: GuestValidator }
> => {
  return useMutation(
    ({ guestId, body }) => updateGuest(groupId, guestId, body),
    {
      mutationKey: groupsKeys.guestsMutation(groupId),
    }
  );
};

export const useCreateGuestCustomInfo = (
  groupId: string
): UseMutationResult<
  GuestEntity,
  AxiosAPIError<CustomInfoValidator>,
  { guestId: string; body: CustomInfoValidator },
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ guestId, body }) => createGuestCustomInfo(groupId, guestId, body),
    {
      mutationKey: groupsKeys.guestsMutation(groupId),
      onSuccess: () => {
        queryClient.invalidateQueries(groupsKeys.guests(groupId));
      },
    }
  );
};
